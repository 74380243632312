
export enum AuthModalTypes {
    CREATE = 'CREATE',
    LOGIN = 'LOGIN',
    VERIFY = 'VERIFY',
    RESETPASSWORD = 'RESETPASSWORD'
}

export interface MyFile {
    name: string,
    size: number,
    url: string,
    file: File
}

export interface Page<T> {
    items: Array<T>,
    hasNext : boolean,
    page: number,
    totalItems: number
}


export interface ContactUsForm {
    firstName: string,
    lastName: string,
    email: string,
    message: string,
    customerId? : string
}


export interface GuidePost {
    title : string,
    href: string,
    imageUrl: string,
    datePublished: string,
    metaTitle: string,
    metaDescription: string,
    introduction: string,
    body: Array<any>
}
