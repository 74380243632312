import Layout from "../../Components/Structure/Foundation/Layout"
import Body from "Components/Structure/Foundation/Body"
import { Helmet } from "react-helmet"
import { choosingVideoLatencyContent } from "./GuideContent/ChoosingVideoLatency"
import PageHeader from "Components/Typography/PageHeader"

const guideContentMap = {
    "how-to-choose-the-right-video-latency-on-youtube" : choosingVideoLatencyContent
}


export const GuideArticle = () => {
    const templatePath = window.location.pathname.split("/")[2]
    const guideContent = guideContentMap[templatePath]

    return (
        <Layout header={{ showAccount: true, showMenu: true }}>
            <Helmet>
                <title>{guideContent.metaTitle}</title>
                <meta name="description" content={guideContent.metaDescription} />
            </Helmet>
            <PageHeader text={"Streaming Guides"}  />
            <Body> 
                <div className="max-w-4xl mx-auto py-4 md:py-8 px-2 sm:px-12 ">
                    <div className="text-center mb-4">
                        <p className="text-sm md:text-base font-medium text-gray-600 mb-2 md:mb-4">Published {guideContent.datePublished}</p>
                        <h1 className="text-2xl sm:text-3xl leading-relaxed font-bold text-gray-800">{guideContent.title}</h1>
                    </div>
                    <p className="text-md sm:text-lg leading-7 sm:leading-8 font-medium italic text-gray-600">
                        {guideContent.introduction}
                    </p>
                    {guideContent.body.map(body => { return body })}
                </div>
            </Body> 
        </Layout>
    )
}
