import { useRef, useEffect } from "react";

export default function Paypal({paymentAmount, handleSubmit, isCheckoutFormComplete}) {
  const paypal = useRef();

  useEffect(() => {
    {/*@ts-ignore */}
    let paypalButton = window.paypal
      .Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'horizontal',
          label: 'paypal',
          tagline: 'false'
        },

        createOrder: function(data, actions) {
          if (isCheckoutFormComplete()){
            return actions.order.create({
                purchase_units: [{"amount":{"currency_code":"USD","value":paymentAmount}}]
            });
          }
        },

        onApprove: function(data, actions) {
          return actions.order.capture().then(function(orderData) {
            handleSubmit(orderData,null)
          });
        },

        onError: function(err) {
          //handleSubmit(null,null)
        }
      })
      paypalButton.render(paypal.current)
      return () => {paypalButton.close()}
  }, [paymentAmount]);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
