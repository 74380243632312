import React from "react";
import posed, { PoseGroup } from "react-pose";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { compareRanking, getNumberMarginLeftImage, getNumberMarginLeftNoImage, getNumberMarginRight, getRowFontSize, getRowHeight, getRowMarginTop } from "Utilities/HelperFunctions";
import RenderIf from "Components/Structure/RenderIf"
import { observer } from "mobx-react";
import { STANDARD_DROP_SHADOW } from "Utilities/GeneralConstants";

const Item = posed.div({
  flip: {
    scale: 1,
    transition: {
      scale: {
        type: "tween",
        velocity: 2
      },
      default: {
        type: "tween"
      }
    }
  }
});


const VoteTable = observer(({voteConfig}) => {
  let startId = 0;
  let rankingData = voteConfig.voteOptions.map( voteOption => { return { "Id" : `${startId++}`, "Name" : voteOption["name"], "VoteCode" : voteOption["code"], "VoteCount" : voteOption["count"], "Image" : voteOption["avatarUrl"] } })
  rankingData.sort(compareRanking)
  const rowHeight = getRowHeight(rankingData.length)
  const rowFontSize = getRowFontSize(rankingData.length)
  const rowMarginTop = getRowMarginTop(rankingData.length)
  const numberMarginRight = getNumberMarginRight(rankingData.length)
  const numberMarginLeftNoImage = getNumberMarginLeftNoImage(rankingData.length)
  const numberMarginLeftImage = getNumberMarginLeftImage(rankingData.length)
  const gridStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    marginRight: `${1 - rankingData.length*0.05}%`,
    height: `${rowHeight}em`
  }


  function rowNumberStyle() {
    return {
      fontFamily: voteConfig.font,
      fontSize: `${rowFontSize}em`,
      color: voteConfig.fontColor,
      marginTop: `${rowMarginTop*3.5}%`,
      marginRight:  `${numberMarginRight}%`,
      textShadow: voteConfig.dropShadow ? STANDARD_DROP_SHADOW : 'none'
    }
  }

  function rowImageMarginLeftStyle(hasImage) {
    return {
      marginLeft: hasImage ? `${numberMarginLeftImage}%` : `${numberMarginLeftNoImage}%`,
    }
  }
  

  function titleStyle() {
    return {
      fontFamily: voteConfig.font,
      fontSize: "0.4em",
      color: voteConfig.fontColor,
      paddingLeft: "5%",
      textShadow: voteConfig.dropShadow ? STANDARD_DROP_SHADOW : 'none'
    }
  }

  
  const gridTextStyle = {
    fontFamily: voteConfig.font,
    fontSize: `${rowFontSize}em`,
    color: "#000000",
    paddingLeft: "5%",
    alignItems: 'center',
    display: 'flex',
    marginTop: `${rowHeight/4}em`
  }
  
  
  return (
        <React.Fragment>
          <Grid xs={12} container>
            <Grid container>
            <RenderIf condition={voteConfig.includeImages}>
              <Grid xs={1} style={rowImageMarginLeftStyle(voteConfig.includeImages)}></Grid>
            </RenderIf>
            <RenderIf condition={!voteConfig.includeImages}>
              <div style={rowImageMarginLeftStyle(voteConfig.includeImages)}> </div>
            </RenderIf>
            <Grid xs={1}></Grid>
            <Grid xs={4}>
              <Typography  style={titleStyle()}>
              {"Name"}
              </Typography>
            </Grid>
            <Grid xs={3} style={{marginLeft: `${1 - rankingData.length*0.05}%`}}>
              <Typography style={titleStyle()} align="left" >
              {"Vote Code"}
              </Typography>
            </Grid>
            <Grid xs={2}  style={{marginLeft: `${1 - rankingData.length*0.05}%`}}>
              <Typography style={titleStyle()} align="left">
                {"Count"}
              </Typography>
            </Grid>
            </Grid>
          </Grid>

          <Grid xs={12}  justify="center" container>
            <div style={{ height: "100%", width:"100%"}}>
            <PoseGroup>
              {rankingData.map( (item,index) => (
                <Item className="item" data-key={item["Id"]} key={item["Id"]} style={{marginBottom: `${1 - rankingData.length*0.05}%`}} >
                <Grid container >
                   <Grid justify="flex-end" xs={1}>
                      <Typography style={rowNumberStyle()} align="right" >
                        {index + 1}
                      </Typography>
                   </Grid>
                   <RenderIf condition={voteConfig.includeImages}>
                   <Grid xs={1} container justifyContent="flex-end" style={rowImageMarginLeftStyle(voteConfig.includeImages)}>
                      <img style={{width: `${rowHeight}em`, height: `${rowHeight}em`}} src={item["Image"]} />
                    </Grid>
                   </RenderIf>
                   <RenderIf condition={!voteConfig.includeImages}>
                     <div style={rowImageMarginLeftStyle(voteConfig.includeImages)}> </div>
                   </RenderIf>
                   <Grid style={gridStyle} xs={4} >
                     <Typography style={gridTextStyle} align="left" >
                      {item["Name"]}
                     </Typography>
                   </Grid>
                   <Grid style={gridStyle} xs={3} >
                   <Typography style={gridTextStyle} align="left" >
                     {item["VoteCode"]}
                   </Typography>
                   </Grid>
                   <Grid style={gridStyle} xs={2} >
                   <Typography style={gridTextStyle} align="left" >
                     {item["VoteCount"]}
                   </Typography>
                   </Grid>
                </Grid>
                </Item>
              ))}
            </PoseGroup>
            </div>
          </Grid>
        </React.Fragment>
    );
})



export default VoteTable;
