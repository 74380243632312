import { SuccessAlert } from 'Components/Structure/Alerts/SuccessAlert'
import BackNext from 'Components/Structure/BackNext'
import RenderIf from 'Components/Structure/RenderIf'
import ParagraphBold from 'Components/Typography/ParagraphBold'
import React from 'react'
import { useStores } from 'Store/StoreConfig'
import { Payment } from 'Types/CheckoutTypes'
import { formatDateTime, getProductName } from 'Utilities/HelperFunctions'

interface OrderConfirmationInterface {
    payment: Payment
  }

const OrderConfirmation = ({payment} : OrderConfirmationInterface) => {
    const { StreamBuilderStore } = useStores()

    return (
        <React.Fragment>

        <div className="mt-3 sm:mt-4 mx-5 ">
            <div className="mb-4 lg:w-9/12">
                <SuccessAlert message={"Thank you, we have received your payment."}/>
            </div>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg  lg:w-9/12">
                <div className="px-4 py-4 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Receipt number</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{payment.id}</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{payment.paymentFullName}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{payment.paymentEmail}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Product</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getProductName(payment.productType)}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Transaction date</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDateTime(payment.createdAt)} (UTC)</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Stream end</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDateTime(payment.streamEnd)} (UTC)</dd>
                        </div>
                        <RenderIf condition={payment.discount && payment.discount > 0} >
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Subtotal</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">${(payment.cost + payment.discount).toFixed(2)}</dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Discount</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">-${payment.discount.toFixed(2)}</dd>
                            </div>
                        </RenderIf>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Total cost</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">${payment.cost.toFixed(2)}</dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="mt-4  lg:w-9/12 -mb-2" >
                <ParagraphBold> You should receive a confirmation email shortly on: <span className="font-bold">{payment.paymentEmail}</span> if you don't, check your spam folder.</ParagraphBold>
            </div >
        </div>

        <BackNext
            onNext={() => StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep + 1)}
            disableNext={false} 
            showBack={false}
        />
        
       </React.Fragment>
        
      )
  
}

export default OrderConfirmation

