import Layout from "../Components/Structure/Foundation/Layout"
import RenderIf from "../Components/Structure/RenderIf"
import BuildStepOne from "../Components/StreamBuilder/BuildStepOne"
import BuildStepTwo from "Components/StreamBuilder/BuildStepTwo"
import {useStores} from "../Store/StoreConfig"
import { observer } from "mobx-react"
import BuildStepThree from "Components/StreamBuilder/BuildStepThree"
import BuildStepFour from "Components/StreamBuilder/BuildStepFour"
import Body from "Components/Structure/Foundation/Body"
import BuildStepFive from "Components/StreamBuilder/BuildStepFive"
import Stepper from "Components/StreamBuilder/Stepper"
import PageHeader from "Components/Typography/PageHeader"
import { Helmet } from "react-helmet"
import { STREAMBUILDER_DESCRIPTION, STREAMBUILDER_TITLE } from "Utilities/SEOConstants"


const stepTitles = ['Template', 'Customize', 'Preview', 'Checkout', 'Publish' ]

const StreamBuilder  = observer(() => {
  const { StreamBuilderStore } = useStores()

  return (
    <Layout header={{ showAccount: true, showMenu: true }} footer={{basicFooter: true}}>
      <Helmet>
        <title>{STREAMBUILDER_TITLE}</title>
        <meta name="description" content={STREAMBUILDER_DESCRIPTION} />
      </Helmet>
      <PageHeader text={`Stream Builder - ${stepTitles[Math.min(StreamBuilderStore.streamBuilderStep,4)]}`}  />
      <Body > 
        <Stepper 
          currentStep={Math.min(StreamBuilderStore.streamBuilderStep,4)}
          steps={[{
            label: 'Template',
            iconName: "TEMPLATE"
          }, {
            label: 'Customize',
            iconName: "CUSTOMIZE"
          }, {
            label: 'Preview',
            iconName: "PREVIEW"
          }, {
            label: 'Checkout',
            iconName: "CHECKOUT"
          },  {
            label: 'Publish',
            iconName: "PUBLISH"
          }]}
        />
        <div className="pt-3 sm:pt-6" />

        <div className="h-800" >
        <RenderIf condition={StreamBuilderStore.streamBuilderStep === 0}>
          <BuildStepOne/>
        </RenderIf>
        <RenderIf condition={StreamBuilderStore.streamBuilderStep === 1}>
          <BuildStepTwo/>
        </RenderIf>
        <RenderIf condition={StreamBuilderStore.streamBuilderStep === 2}>
          <BuildStepThree/>
        </RenderIf>
        <RenderIf condition={StreamBuilderStore.streamBuilderStep === 3}>
          <BuildStepFour/>
        </RenderIf>
        <RenderIf condition={StreamBuilderStore.streamBuilderStep === 4}>
          <BuildStepFive/>
        </RenderIf>
        </div>
       </Body> 
    </Layout>
  )
})

export default StreamBuilder
