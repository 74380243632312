import axios from 'axios'
import { API_BASE_URL } from './ApiConfig'


export async function validateReferralCode(referralCode : string) {
    try {
        const response = await axios.get(`${API_BASE_URL}/referral/validate/${referralCode}`)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return null
    }
    return null
}
