import { Dialog } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { CognitoUser } from 'amazon-cognito-identity-js'
import InputWithLabel from 'Components/Structure/InputWithLabel'
import RenderIf from 'Components/Structure/RenderIf'
import StandardButton from 'Components/Structure/StandardButton'
import React, { useState } from 'react'
import { createCognitoUser } from 'Services/AuthService'
import { AuthModalTypes } from 'Types/GeneralTypes'
import { GENERIC_ERROR_MSG, INVALID_EMAIL_MSG} from 'Utilities/GeneralConstants'
import { hashText, isEmailValid } from 'Utilities/HelperFunctions'


export const ResetPasswordModalBody = ({setCurrentModal}) => {
    const [email, setEmail] = useState("")
    const [showEmailError, setShowEmailError] = useState(false)
    const [resetPasswordError, setResetPasswordError] = useState("")
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)


    const resetPassword = (event) => {
        event.preventDefault()
        if(isEmailValid(email)){
            setLoading(true)
            const cognitoUser : CognitoUser = createCognitoUser(email)
            cognitoUser.forgotPassword({
                onSuccess: function(data) {
                    setLoading(false)
                    setComplete(true)
                },
                onFailure: function(err) {
                    setLoading(false)
                    setResetPasswordError(GENERIC_ERROR_MSG)
                }
            }, {emailId : hashText(email) })
        }
    }

    return (
        <React.Fragment>
                <RenderIf condition={!complete} >
                <div className="text-center mb-4">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Reset password
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                        Please enter your email below and you will receive password reset instructions.
                    </p>
                  </div>
                </div>

                <InputWithLabel
                    id="email"
                    label=""
                    placeholder="Email" 
                    onChange={e => setEmail(e.target.value)}
                    onBlur={() => setShowEmailError(true)}
                    value={email}
                    type="text"
                    showError={!isEmailValid(email) && showEmailError}
                    errorMessage={INVALID_EMAIL_MSG}
                />
                
                <RenderIf condition={resetPasswordError.length > 0}>
                    <div className="mb-4 text-sm text-red-600 mt-4">{GENERIC_ERROR_MSG}</div>
                </RenderIf>


                <div className="text-center mt-6">
                    <StandardButton
                        disabled={!isEmailValid(email)}
                        onClick={e => resetPassword(e)}
                        text={"Email password reset"}
                        isSpinning={loading}
                    />
                </div>
                </RenderIf>


                <RenderIf condition={complete} >
                <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="text-center mb-4">
                        <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                        Password reset instructions have been sent to your email!
                        </Dialog.Title>
                    
                    </div>
                </div>

                </RenderIf>


            <div className="text-gray-500 mt-4 text-center">
                Go back to<span> </span>
                <button className="border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  onClick={() => {setCurrentModal(AuthModalTypes.LOGIN)} }> Log in.</button>
             </div>


        </React.Fragment>
    )
}
