const Footer = () => {
  return (
    <div className="bg-gray-50 text-white py-2 px-4">
      <p className="text-base text-gray-500 text-center"> &copy; {new Date().getFullYear()} 	StreamCreate Pty Ltd. <p className="text-base text-gray-500 text-center md:inline"> All rights reserved. </p> </p>
    </div>
  )
}

export default Footer

