import { useState } from "react"
import { useStores } from "Store/StoreConfig"

import { observer } from "mobx-react"
import OptionSelector from "./Utilities/OptionSelector"
import ReactAudioPlayer from "react-audio-player"
import RenderIf from "Components/Structure/RenderIf"
import SelectMenu from "./Utilities/SelectMenu"
import { DEFAULT_AUDIO, DEFAULT_AUDIO_MAP } from "Utilities/GeneralConstants"
import { InformationAlert } from "Components/Structure/Alerts/InformationAlert"

const VolumeUpIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
        </svg>
    )
}


const AudioConfig = observer(({isBuilderConfig}) => {
    const { StreamBuilderStore } = useStores()
    const [productConfig,setProductConfig] = useState(StreamBuilderStore.getProductConfig(isBuilderConfig))
    const [musicOn, setMusicOn] = useState(false)
    const updateAudioConfig = (newDefaultAudio, audioEnabled) => {
        let newStreamConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig)
        newStreamConfig.productConfig.defaultAudio = newDefaultAudio
        newStreamConfig.productConfig.audioEnabled = audioEnabled
        StreamBuilderStore.setStreamConfig(newStreamConfig,isBuilderConfig)
        setProductConfig(newStreamConfig.productConfig)
    }

    const playMusicButton = () => {
        if (productConfig.audioEnabled) {
            setMusicOn(!musicOn)
        }
    }

    return (
        <div className="p-4 pt-2 text-gray-800">
            <div className="flex ">
                <OptionSelector text={"No music loop"} active={!productConfig.audioEnabled} onClick={() => {updateAudioConfig(productConfig.defaultAudio,false);setMusicOn(false)}} />
            </div>
            <div className="mt-4"> </div>
            <div className="flex ">
                <OptionSelector text={"Default music loop"} active={productConfig.audioEnabled} onClick={() => updateAudioConfig(productConfig.defaultAudio,true)} />
                <RenderIf condition={musicOn && productConfig.audioEnabled}>
                    <ReactAudioPlayer
                        src={DEFAULT_AUDIO_MAP[productConfig.defaultAudio]}
                        autoPlay
                    />
                </RenderIf>
            </div>
            <div className="flex flex-col xl:flex-row mt-2 mb-4">
                <div className="flex-1 inline-block w-full sm:w-6/12 w-6/12 ">
                    <SelectMenu items={DEFAULT_AUDIO} itemName={productConfig.defaultAudio} updateCallback={(audio) => {updateAudioConfig(audio,true)}} />
                </div>
                <div className="inline-block  w-full sm:w-6/12 pt-1 xl:pl-4">
                    <button onClick={() => {updateAudioConfig(productConfig.defaultAudio,true);playMusicButton()}} className={`ring-1 mt-2 xl:mt-0  w-full py-2 inline-flex items-center font-medium text-blue-600 text-sm border rounded-md border-blue-300  ${musicOn ? "bg-blue-200" : "bg-blue-50" }`} >
                        <span className="pr-1 pl-2"> Play music </span> <VolumeUpIcon  aria-hidden="true" />
                    </button>
                </div>
            </div>
            <InformationAlert title={"Note"} message={"Each music loop is approximately one hour long, you are listening to a sample."}/>
        </div>
    )
})

export default AudioConfig
