const MyCheckBox = ({text, checked, onChange}) => {

    return (
        <div className="relative flex items-start" >
        <label htmlFor="email" className={"block text-md  text-gray-700" }>{text}</label>
        <div className="ml-2 flex items-center h-6">
            <input
                type="checkbox"
                onBlur={() =>{}}
                checked={checked}
                onChange={() => onChange()}
                className={`h-4 w-4 rounded`}
            />
        </div>
    </div>
    )
}

export default MyCheckBox
