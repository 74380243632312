import Footer from "Components/Structure/Foundation/Footer"
import StandardButton from "Components/Structure/StandardButton"
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
    const history = useHistory();

    return (
        <div className="bg-blue-50 min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <div className="bg-white px-6 py-8 pt-5 rounded shadow-md text-black w-full">
                    <h1 className="mb-6 text-3xl text-center font-medium text-gray-700">404 Page Not Found</h1>
                    <div className="text-center mt-6">
                        <StandardButton
                            disabled={false}
                            onClick={e => history.push("/")}
                            text={"Return To Home Page"}
                            isSpinning={false}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
  }
  
  export default PageNotFound