import StreamBuilder from 'Pages/StreamBuilder'
import { Home } from 'Pages/Home'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SignUp from 'Components/Authentication/SignUp'
import './App.css'
import Login from 'Components/Authentication/Login'
import ResetPassword from 'Components/Authentication/ResetPassword'
import PageNotFound from 'Components/Structure/Foundation/PageNotFound'
import VerifyEmail from 'Components/Authentication/VerifyEmail'
import NewPassword from 'Components/Authentication/NewPassword'
import { useStores } from 'Store/StoreConfig'
import { observer } from 'mobx-react'
import { ManageStreams } from 'Components/Dashboard/ManageStreams'
import { Contact } from 'Pages/Contact'
import { ProtectedUserRoute } from 'Components/Structure/Foundation/ProtectedRoute'
import { TemplateView } from 'Pages/TemplateView'
import { Support } from 'Pages/Support'
import ScrollToTop from 'Utilities/ScrollToTop'
import { Pricing } from 'Pages/Pricing'
import { TemplateGallery } from 'Pages/TemplateGallery'
import { TermsOfService } from 'Pages/TermsOfService'
import { PrivacyPolicy } from 'Pages/PrivacyPolicy'
import { AccountSettings } from 'Components/Dashboard/AccountSettings'
import PageSpinner from 'Components/Structure/PageSpinner'
import { Guide } from 'Pages/Guide/Guide'
import { GuideArticle } from 'Pages/Guide/GuideArticle'

export const App =  observer(() => {
	const { UserStore } = useStores()

	if(!UserStore.isLoaded){
		UserStore.loadUser()
	}

	return (
		<div>
			{!UserStore.isLoaded ? 
			  <PageSpinner isEntireScreen={true}/>
			:
			<Router>
				 <ScrollToTop />
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/Templates/Countdown" component={TemplateGallery} />
					<Route exact path="/Templates/VotingPoll" component={TemplateGallery} />
					<Route exact path="/Pricing" component={Pricing} />
					<Route exact path="/StreamBuilder" component={StreamBuilder} />
					<Route exact path="/Guides" component={Guide} />
					<Route exact path="/Guides/*" component={GuideArticle} />
					<Route exact path="/Contact" component={Contact} />
					<Route exact path="/AboutUs" component={Home} />
					<Route exact path="/Support" component={Support} />
					<Route exact path="/TermsOfService" component={TermsOfService} />
					<Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
					<Route exact path="/SignUp" component={SignUp} />
					<Route exact path="/Login" component={Login} />
					<Route exact path="/ResetPassword" component={ResetPassword} />
					<Route exact path="/NewPassword" component={NewPassword} />
					<Route path="/VerifyEmail/:verifyId" component={VerifyEmail} />
					<Route path="/Template/:configId/:token" component={TemplateView} />
					<ProtectedUserRoute exact path="/Dashboard/ManageStreams" component={ManageStreams} /> 
					<ProtectedUserRoute exact path="/Dashboard/AccountSettings" component={AccountSettings} />
					<Route path="*" component={PageNotFound} />
				</Switch>
			</Router>
			}
		</div>
	)
})

export default App
