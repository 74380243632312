import Footer from "Components/Structure/Foundation/Footer"
import {Link} from 'react-router-dom'
import StandardButton from "Components/Structure/StandardButton"
import InputWithLabel from "../Structure/InputWithLabel"
import { useCallback, useEffect, useState } from "react"
import {userPool} from "Services/AuthService"
import RenderIf from "Components/Structure/RenderIf"
import { GENERIC_ERROR_MSG, INVALID_EMAIL_MSG, MISSING_FIRSTNAME_MSG, PASSWORD_MISMATCH_MSG } from "Utilities/GeneralConstants"
import { useHistory } from "react-router-dom"
import { CognitoUserAttribute } from "amazon-cognito-identity-js"
import { hashText, isEmailValid, isPasswordValid } from "Utilities/HelperFunctions"
import { createCustomer } from "Services/CustomerService"


const isFirstNameValid = input => input.length > 0
const isConfirmPasswordValid = (p1, p2)=> p1 === p2

const SignUp = () => {
    const [firstName, setFirstname] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [createAccountError, setCreateAccountError] = useState("")

    const [showFirstNameError, setShowFirstNameError] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false)
    const [showCreateAccountError, setShowCreateAccountError] = useState(false)
    const [loading, setLoading] = useState(false)

    const complete = isFirstNameValid(firstName) && isEmailValid(email) && isPasswordValid(password) && isConfirmPasswordValid(password,confirmPassword)
    const history = useHistory()

    const confirmSignup  = useCallback((event) => {
        event.preventDefault()
        setShowCreateAccountError(false)
        if(complete) {
            setLoading(true)
            var givenName = new CognitoUserAttribute({ Name : "given_name", Value : firstName})
            var familyName = new CognitoUserAttribute({ Name : "family_name", Value : lastName})

            userPool.signUp(email, password, [givenName,familyName], null, async (err,data) => {
                if(err){
                    if(err["code"] === "UsernameExistsException"){
                        setCreateAccountError(err.message)
                    }else{
                        setCreateAccountError(GENERIC_ERROR_MSG)
                    }
                    setShowCreateAccountError(true)
                }else{
                    const result = await createCustomer(data.userSub, firstName, lastName, email)
                    if(result){
                        history.push("/VerifyEmail/" + hashText(email))
                    }else{
                        setCreateAccountError(GENERIC_ERROR_MSG)
                        setShowCreateAccountError(true)
                    }
                }
                setLoading(false)
            }, {emailId : hashText(email) })   
        }
    },[complete, email, history, password])


    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" && complete) {
                confirmSignup(event)
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [firstName, email, password, confirmPassword, complete, confirmSignup])
    

    return (
        <div className="bg-blue-50 min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <Link to="/">
                  <img className="h-9 w-auto" src={process.env.PUBLIC_URL + '/images/StreamCreateLogo.svg'} alt="" />
                </Link> 
                <div className="bg-white px-6 py-8 pt-5 mt-6 rounded shadow-md text-black w-full">
                    <h1 className="mb-6 text-2xl text-center font-medium text-gray-600">Sign up</h1>

                    <InputWithLabel
                        id="firstName"
                        label=""
                        placeholder="First name"
                        onChange={e => setFirstname(e.target.value)}
                        onBlur={() => setShowFirstNameError(true)}
                        value={firstName}
                        type="text"
                        showError={!isFirstNameValid(firstName) && showFirstNameError}
                        errorMessage={MISSING_FIRSTNAME_MSG}
                    />

                    <InputWithLabel
                        id="lastName"
                        label=""
                        placeholder="Last name"
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                        type="text"
                    />

                    <InputWithLabel
                        id="email"
                        label=""
                        placeholder="Email" 
                        onChange={e => setEmail(e.target.value)}
                        onBlur={() => setShowEmailError(true)}
                        value={email}
                        type="text"
                        showError={!isEmailValid(email) && showEmailError}
                        errorMessage={INVALID_EMAIL_MSG}
                        maxLength={80}
                    />

                    <InputWithLabel
                        id="password"
                        label=""
                        placeholder="Password" 
                        onChange={e => setPassword(e.target.value)}
                        onBlur={() => setShowPasswordError(true)}
                        showError={!isPasswordValid(password) && showPasswordError}
                        value={password}
                        type="password"
                    />

                    <RenderIf condition={!isPasswordValid(password) && showPasswordError}>
                        <div className="mb-2 text-sm text-red-600 ml-5 ">
                            <ul className="list-disc">
                                <li>8 characters minimum.</li>
                                <li>must contain a number.</li>
                                <li>an uppercase and a lowercase.</li>                            
                            </ul>
                        </div>
                    </RenderIf>

                    <InputWithLabel
                        id="confirmPassword"
                        label=""
                        placeholder="Confirm password" 
                        onChange={e => setConfirmPassword(e.target.value)}
                        onBlur={() => setShowConfirmPasswordError(true)}
                        value={confirmPassword}
                        type="password"
                        showError={!isConfirmPasswordValid(password, confirmPassword) && showConfirmPasswordError}
                        errorMessage={PASSWORD_MISMATCH_MSG}
                    />

                    <RenderIf condition={showCreateAccountError}>
                        <div className="mb-4 text-sm text-red-600">{createAccountError}</div>
                    </RenderIf>

                    <div className="text-center text-sm text-grey-dark mt-4">
                        <StandardButton
                            disabled={!complete}
                            onClick={e => confirmSignup(e)}
                            text={"Create account"}
                            isSpinning={loading}
                        />
                    </div>
                </div>

                <div className="text-gray-500 mt-4">
                    Already have an account?<span> </span>
                    <Link className="border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  to="../Login/"> Log in.</Link>
                </div>
            </div>
            <Footer />
        </div>
    )
  }
  
  export default SignUp