import { Dialog } from '@headlessui/react'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import InputWithLabel from 'Components/Structure/InputWithLabel'
import RenderIf from 'Components/Structure/RenderIf'
import StandardButton from 'Components/Structure/StandardButton'
import React, { useState } from 'react'
import { userPool } from 'Services/AuthService'
import { createCustomer } from 'Services/CustomerService'
import { useStores } from 'Store/StoreConfig'
import { AuthModalTypes } from 'Types/GeneralTypes'
import { GENERIC_ERROR_MSG, INVALID_EMAIL_MSG, MISSING_FIRSTNAME_MSG, PASSWORD_MISMATCH_MSG } from 'Utilities/GeneralConstants'
import { hashText, isEmailValid, isPasswordValid } from 'Utilities/HelperFunctions'

const isConfirmPasswordValid = (p1, p2)=> p1 === p2
const isFirstNameValid = input => input.length > 0


export const CreateUserModalBody = ({setCurrentModal, cardFullName, preSetEmail}) => {
    const [firstName, setFirstname] = useState("")
    const [showFirstNameError, setShowFirstNameError] = useState(false)
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState(preSetEmail)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [createAccountError, setCreateAccountError] = useState("")

    const [showEmailError, setShowEmailError] = useState(false)
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false)
    const [showCreateAccountError, setShowCreateAccountError] = useState(false)
    const [loading, setLoading] = useState(false)

    const complete =  isEmailValid(email) && isPasswordValid(password) && isConfirmPasswordValid(password,confirmPassword)
    const { UserStore } = useStores()


    const confirmSignup  = (event) => {
        event.preventDefault()
        setShowCreateAccountError(false)
        if (complete) {
            setLoading(true)
            let givenName = firstName
            let familyName = lastName

            if (cardFullName && (cardFullName.length > 0)) {
                const fullNameSplit = cardFullName.split(' ')
                givenName = fullNameSplit[0]
                familyName =  fullNameSplit.length > 1 ? fullNameSplit.slice(1).join(' ') : ""
            }

            var givenNameAttr = new CognitoUserAttribute({ Name : "given_name", Value : givenName})
            var familyNameAttr = new CognitoUserAttribute({ Name : "family_name", Value : familyName})

            userPool.signUp(email, password, [givenNameAttr,familyNameAttr], null, async (err,data) => {
                if(err){
                    if(err["code"] === "UsernameExistsException"){
                        setCreateAccountError(err.message)
                    }else{
                        setCreateAccountError(GENERIC_ERROR_MSG)
                    }
                    setShowCreateAccountError(true)
                }else{
                    const result = await createCustomer(data.userSub, givenName, familyName, email)
                    if(result){
                        UserStore.setSignupEmail(email)
                        setCurrentModal(AuthModalTypes.VERIFY)
                    }else{
                        setCreateAccountError(GENERIC_ERROR_MSG)
                        setShowCreateAccountError(true)
                    }
                }
                setLoading(false)
            }, {emailId : hashText(email) })   
        }
    }

  return (
    <React.Fragment>
              <div>
                <div className="text-center mb-4">
                  <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                    Create an account
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      An account will enable you to track, update and publish your live streams via a dashboard.
                    </p>
                  </div>
                </div>
                
                    <RenderIf condition={!(cardFullName && cardFullName.length > 0)} >
                        <InputWithLabel
                                id="firstName"
                                label=""
                                placeholder="First name"
                                onChange={e => setFirstname(e.target.value)}
                                onBlur={() => setShowFirstNameError(true)}
                                value={firstName}
                                type="text"
                                showError={!isFirstNameValid(firstName) && showFirstNameError}
                                errorMessage={MISSING_FIRSTNAME_MSG}
                            />

                            <InputWithLabel
                                id="lastName"
                                label=""
                                placeholder="Last name"
                                onChange={e => setLastName(e.target.value)}
                                value={lastName}
                                type="text"
                            />
                    </RenderIf>

                    <InputWithLabel
                        id="email"
                        label=""
                        placeholder="Email" 
                        onChange={e => {setEmail(e.target.value)}}
                        onBlur={() => setShowEmailError(true)}
                        value={email}
                        type="text"
                        showError={!isEmailValid(email) && showEmailError}
                        errorMessage={INVALID_EMAIL_MSG}
                        maxLength={80}
                    />

                    <InputWithLabel
                        id="password"
                        label=""
                        placeholder="Password" 
                        onChange={e => setPassword(e.target.value)}
                        onBlur={() => setShowPasswordError(true)}
                        value={password}
                        showError={!isPasswordValid(password) && showPasswordError}
                        type="password"
                    />

                    <RenderIf condition={!isPasswordValid(password) && showPasswordError}>
                        <div className="mb-2 text-sm text-red-600 ml-5 ">
                            <ul className="list-disc">
                                <li>8 characters minimum.</li>
                                <li>must contain a number.</li>
                                <li>an uppercase and a lowercase.</li>                            
                            </ul>
                        </div>
                    </RenderIf>

                    <InputWithLabel
                        id="confirmPassword"
                        label=""
                        placeholder="Confirm password" 
                        onChange={e => setConfirmPassword(e.target.value)}
                        onBlur={() => setShowConfirmPasswordError(true)}
                        value={confirmPassword}
                        type="password"
                        showError={!isConfirmPasswordValid(password, confirmPassword) && showConfirmPasswordError}
                        errorMessage={PASSWORD_MISMATCH_MSG}
                    />

                    <RenderIf condition={showCreateAccountError}>
                        <div className="mb-4 text-sm text-red-600">{createAccountError}</div>
                    </RenderIf>


              </div>
              <div className="text-center text-sm text-grey-dark mt-4">
                <StandardButton
                    disabled={!complete}
                    onClick={e => confirmSignup(e)}
                    text={"Create account"}
                    isSpinning={loading}
                />
             </div>

             <div className="text-gray-500 mt-4 text-center">
                Already have an account?<span> </span>
                <button className="border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  onClick={() => {setCurrentModal(AuthModalTypes.LOGIN)} }> Log in.</button>
             </div>
  </React.Fragment>
  )
}
