import { Dialog } from '@headlessui/react'
import React from 'react'


export const VideoModalBody = ({title, videoSource}) => {
    return (
        <React.Fragment>
              <div>
                  <Dialog.Title className="text-lg leading-6 font-medium text-gray-600 -mt-4">
                    {title}
                  </Dialog.Title>
                  <div className="aspect-w-16 aspect-h-9 mt-3">
                    <iframe src={videoSource} frameBorder="0"  allow='autoplay' allowFullScreen ></iframe>
                  </div>
              </div>
        </React.Fragment>
    )
}
