import React, { useEffect, useState } from 'react'
import InputWithLabel from '../Structure/InputWithLabel'
import { useStores } from 'Store/StoreConfig'
import ParagraphBoldLarge from 'Components/Typography/ParagraphBoldLarge'
import { InformationAlert } from 'Components/Structure/Alerts/InformationAlert'
import { ModalFrame } from './Modals/ModalFrame'
import { CreateUserModalBody } from './Modals/CreateUserModalBody'
import { TechnicalSupport } from './TechnicalSupport'
import { LoginModalBody } from './Modals/LoginModalBody'
import { VerifyEmailModalBody } from './Modals/VerifyEmailModalBody'
import { ResetPasswordModalBody } from './Modals/ResetPasswordModalBody'
import StandardButton from 'Components/Structure/StandardButton'
import { observer } from 'mobx-react'
import { AuthModalTypes } from 'Types/GeneralTypes'
import { updateStreamConfig } from 'Services/StreamConfigService'
import { StreamConfigResponse } from 'Types/StreamTypes'
import { useHistory } from "react-router-dom"
import { isStreamKeyValid, isVideoIdValid } from 'Utilities/HelperFunctions'
import { deployStreamConfig } from 'Services/StreamManagementService'
import { PlayIcon } from '@heroicons/react/outline'
import RenderIf from 'Components/Structure/RenderIf'
import StreamPublishSuccess from './StreamPublishSuccess'
import { PUBLISH_STREAM_VIDEO_URL_WATCH } from 'Utilities/GeneralConstants'


const BuildStepFive = observer(() => {
  const { StreamBuilderStore, CheckoutStore, UserStore } = useStores()
  const [streamConfig] = useState(StreamBuilderStore.getStreamConfig(true))
  const [checkoutDetails] = useState(CheckoutStore.checkoutDetails)
  const [showStreamKeyError, setShowStreamKeyError] = useState(false)
  const [showVideoIdError, setShowVideoIdError] = useState(false)
  const [publishingStream, setPublishingStream] = useState(false)
  const [savingAndExiting, setSavingAndExiting] = useState(false)
  const incomplete = isStreamKeyValid(streamConfig.streamKey) === false || isVideoIdValid(streamConfig.videoId) === false
  const [openAuthModal, setOpenAuthModal] = useState(false)
  const [currentModal, setCurrentModal] = useState(AuthModalTypes.CREATE)
  const [streamPublishSuccess, setStreamPublishSuccess] = useState(false)
  const history = useHistory()

  const saveAndPublishStream = async () => {
    if (UserStore.user) {
      setPublishingStream(true)
      let streamConfigResponse : StreamConfigResponse = await saveStream()
      if (streamConfigResponse) {
        await deployStreamConfig(streamConfig.id)
        setStreamPublishSuccess(true)
      }
      setPublishingStream(false)
    } else {
      setCurrentModal(AuthModalTypes.LOGIN)
      setOpenAuthModal(true)
    }
  }

  const saveStream = async () => {
    if (UserStore.user) {
      const customerEmail = UserStore.user.username
      updateStoreStreamConfig(streamConfig.videoId,streamConfig.streamKey, customerEmail)
    } else {
      updateStoreStreamConfig(streamConfig.videoId,streamConfig.streamKey, streamConfig.customerId)
    }
    return await updateStreamConfig(streamConfig.id,streamConfig)
  }


  useEffect(() => {
    if (!UserStore.user) {
      setOpenAuthModal(true)
    }
  },[]);

  const updateStoreStreamConfig = (newVideoId, newStreamKey, newCustomerId) => {
    let newStreamConfig = StreamBuilderStore.streamConfig
    newStreamConfig.videoId = newVideoId
    newStreamConfig.streamKey = newStreamKey
    newStreamConfig.customerId = newCustomerId
    StreamBuilderStore.setStreamConfig(newStreamConfig,true)
  }


  const editInMyDashboard = () => {
    if (UserStore.user) {
      saveAndExitBuilder()
      history.push("/Dashboard/ManageStreams")
    } else {
      setCurrentModal(AuthModalTypes.LOGIN)
      setOpenAuthModal(true)
    }
  }


  const saveAndExitBuilder = async () => {
    setSavingAndExiting(true)
    await saveStream()
    CheckoutStore.resetCheckoutDetails()
    StreamBuilderStore.resetBuilderStreamConfig()
    StreamBuilderStore.setStreamBuilderStep(0)
    setSavingAndExiting(false)
  }

  return (
    <React.Fragment>
      <RenderIf condition={!streamPublishSuccess} >
        <div className="flex flex-col lg:flex-row mt-3 sm:mt-4 mx-5 pb-10">
            <div className="lg:w-8/12 pb-4 ">
              <ParagraphBoldLarge>You are nearly there! We just need more info so we know where to publish your stream</ParagraphBoldLarge>
              <div className="max-w-sm mt-6">
                <InputWithLabel
                  id="video-id"
                  label="Video Id"
                  onChange={e => updateStoreStreamConfig(e.target.value,streamConfig.streamKey, streamConfig.customerId)}
                  onBlur={() => setShowVideoIdError(true)}
                  value={streamConfig.videoId}
                  type="text"
                  showError={isVideoIdValid(streamConfig.videoId) === false && showVideoIdError}
                  errorMessage={"YouTube Video Id is invalid."}
                />
                
              </div>
              <div className="max-w-sm mt-4">
                <InputWithLabel
                  id="stream-key"
                  label="Stream key"
                  onChange={e => updateStoreStreamConfig(streamConfig.videoId, e.target.value, streamConfig.customerId)}
                  onBlur={() => setShowStreamKeyError(true)}
                  value={streamConfig.streamKey}
                  type="text"
                  showError={isStreamKeyValid(streamConfig.streamKey) === false && showStreamKeyError}
                  errorMessage={"Stream key format is incorrect."}
                />
              </div>

              <p className="font-medium text-base	">
                  <a className="items-center font-medium text-sm border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={PUBLISH_STREAM_VIDEO_URL_WATCH} target="_blank" rel="noreferrer">
                      <PlayIcon className="mr-1 h-6 w-6 inline" aria-hidden="true" />Play Video: <span className="ml-1 font-normal inline-block">How to publish your stream to YouTube</span>
                  </a>
              </p>

            </div>
            

            <div className="lg:pl-5 lg:pt-10 md:w-12/12 lg:w-4/12">
              < TechnicalSupport />
              <div className="pt-8">
              <InformationAlert title={"Stream management"} message={"If you wish to publish later or update your stream, you will need to go to Dashboard>Manage Streams."}/>
              </div>
            </div>
            <ModalFrame 
              open={openAuthModal} 
              setOpen={setOpenAuthModal} 
              modalWidth={"24rem"}
              isBasicCloseStyle={true}
              modalBody={ currentModal === AuthModalTypes.CREATE ? 
                          <CreateUserModalBody setCurrentModal={setCurrentModal} cardFullName={checkoutDetails.fullName} preSetEmail={checkoutDetails.email} /> 
                        
                          : currentModal === AuthModalTypes.LOGIN ?  <LoginModalBody setCurrentModal={setCurrentModal} setOpenAuthModal={setOpenAuthModal} subHeadingText={"Link your stream to your existing account."} /> 
                      
                          : currentModal === AuthModalTypes.VERIFY ? <VerifyEmailModalBody setCurrentModal={setCurrentModal} message={"Congratulations! Your stream will now be linked to your newly created account."} /> 
                          
                          : currentModal === AuthModalTypes.RESETPASSWORD ? <ResetPasswordModalBody setCurrentModal={setCurrentModal}/> :  null
                        }  
              easyClose={false}
            />
        </div>
      </RenderIf>

      <RenderIf condition={streamPublishSuccess} >
        <StreamPublishSuccess streamKey={streamConfig.streamKey} />
      </RenderIf>


      <div className="flex justify-end mr-4">
        <StandardButton
          disabled={false}
          text={"Dashboard"}
          onClick={() =>{editInMyDashboard()}}
          isSpinning={savingAndExiting}
          extendClassName='mr-4'
          customColor='bg-blue-400 hover:bg-blue-700'
        />

        <RenderIf condition={!streamPublishSuccess} >
          <StandardButton
              disabled={incomplete}
              onClick={e => {saveAndPublishStream()}}
              text={"Publish stream"}
              isSpinning={publishingStream}
          />
        </RenderIf>
      </div>

    </React.Fragment>
  )
})

export default BuildStepFive
