export const HOME_TITLE = "Stream Create - Build and stream beautiful 24/7 live polls and countdowns to YouTube"
export const HOME_DESCRIPTION = "Stream Create enables you to design and host beautiful 24/7 live polls and countdowns on YouTube within minutes."

export const TEMPLATES_COUNTDOWN_TITLE = "Live Countdown Stream | Host a beautiful 24/7 countdown live stream on YouTube"
export const TEMPLATES_COUNTDOWN_DESCRIPTION = "With the live countdown stream template you can create countdowns to events or product launches that are important to you and your fans. This will help grow you channel engagement and your viewers will spend more time interacting with your content." 

export const TEMPLATES_POLL_TITLE = "Live Voting Poll Stream | Host a beautiful 24/7 voting poll live stream on YouTube"
export const TEMPLATES_POLL_DESCRIPTION = "With the live voting poll stream template you can create live polls on YouTube with 1 to 10 vote options. We will read the live chat of your stream and both update and rank the vote counts in real time. Additionally, we will display the name of the last five voters on stream. This will greatly increase your YouTube channel engagement and your stream engagement." 

export const STREAMBUILDER_TITLE = "Stream Builder | Design, purchase and host your 24/7 live stream within minutes"
export const STREAMBUILDER_DESCRIPTION = "Here you can customize your 24/7 live stream content. You can change the text, color and font. You can use one of our predesigned background images or you can upload your own image. We also provide a selection of great background music for your live stream. Once you are ready, you can preview, purchase and the publish your 24/7 live stream! We will host your live stream on our dedicated streaming servers." 

export const PRICING_TITLE = "Pricing | Affordable 24/7 live stream hosting"
export const PRICING_DESCRIPTION = "Here you can calculate how much it will cost to host your 24/7 live stream based on whether you selected a live countdown stream or a live voting poll stream." 

export const CONTACT_TITLE = "Contact | Reach out to our friendly team of streaming experts"
export const CONTACT_DESCRIPTION = "Here you can submit a question to the StreamCreate customer support team. We can help you with general queries as well as technical questions on how to get your live stream up and running." 

export const SUPPORT_TITLE = "Support | Frequently asked questions, Contact Us"
export const SUPPORT_DESCRIPTION = "Find answers to questions about 24/7 live streaming with StreamCreate. Otherwise, contact our friendly customer support team." 

export const GUIDE_TITLE = "Streaming Guides | how-to's and guides on 24/7 live streaming"
export const GUIDE_DESCRIPTION = "Here you can find helpful guides and tutorials related to 24/7 live streaming on YouTube." 

export const TERMSOFSERVICE_TITLE = "Terms of Service"
export const TERMSOFSERVICE_DESCRIPTION = "Here you can find the terms you must agree to in order to use our streaming services." 

export const PRIVACYPOLICY_TITLE = "Privacy Policy"
export const PRIVACYPOLICY_DESCRIPTION = "Here you can find our privacy policy disclosure." 

export const MANAGESTREAMS_TITLE = "StreamCreate Manage Streams"

export const ACCOUNTSETTINGS_TITLE = "StreamCreate Account Settings"