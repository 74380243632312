import "react-datepicker/dist/react-datepicker.css";
import './styles.css'
import DateAdapter from '@mui/lab/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, TextField } from "@mui/material";
import moment from "moment";


const theme = createTheme({
    palette: {
      primary: {
        main: "#2563eb",
      }
    },
  });

const standardInput = ({ inputRef, inputProps, InputProps }) => (
  <Box  sx={{ display: 'flex', alignItems: 'center' }}>
    <input  ref={inputRef} {...inputProps} />
    {InputProps?.endAdornment}
  </Box>
)


const isDateOutOfRange = (date, minRange, maxRange) =>  {
  if (moment(date).utc() > moment(maxRange).utc()) {
    return true
  } else if (moment(date).utc() < moment(minRange).utc()) {
    return true
  }
  return false
}


const MyDateTimePicker = ({inputDate, setInputDate, onError, minDateTime, maxDateTime, isStandardInput, showErrorOutLine}) => {

    const textFieldInput = (props) => {
      return <TextField {...props} style={ isDateOutOfRange(props.inputProps.value, minDateTime, maxDateTime) && showErrorOutLine ? {border: "1px solid  rgb(220 38 38)", borderRadius:'5px' } : {}} error={ isDateOutOfRange(props.inputProps.value, minDateTime, maxDateTime) && showErrorOutLine}  />
    }

    return (
        <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
              <DateTimePicker
                inputFormat="DD MMM YYYY hh:mm a"
                minDateTime={moment(minDateTime).utc()}
                maxDateTime={moment(maxDateTime).utc()}
                renderInput={isStandardInput ? standardInput : textFieldInput}
                value={inputDate}
                onChange={(newValue: any) => {
                    setInputDate(newValue);
                }}
                onError={(error) => {
                  onError(error)
                }}
           
            />
        </LocalizationProvider>
        </ThemeProvider>
    )
}

export default MyDateTimePicker;

