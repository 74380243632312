import React from 'react'

export const GuideTitleTextLarge = ({title, text}) => {
    return (
        <React.Fragment>
            <h3 className="text-lg leading-relaxed font-bold text-gray-600 ">{title}</h3>
            <p className="text-md sm:text-lg leading-7 sm:leading-8 font-normal text-gray-600 mb-4">
                  {text} 
            </p>
        </React.Fragment>
      )
}


