import axios from 'axios'
import { StreamBuilderStore } from 'Store/StreamBuilderStore'
import { MyFile, Page } from 'Types/GeneralTypes'
import {  BackgroundType, ProductConfig, StreamConfig, StreamConfigResponse } from 'Types/StreamTypes'
import { S3_BUCKET_BASE_URL, STATUS_SUCCESS, STATUS_UNAUTHORIZED } from 'Utilities/GeneralConstants'
import { API_BASE_URL } from './ApiConfig'
import { getAuthHeader, getAuthHeaderWithContentJson, getSession, handleUnauthorizedRequest } from './AuthService'


export async function updateStreamConfig(streamConfigId: string, streamConfig : StreamConfig) : Promise<StreamConfigResponse> {
    getSession()
    try {
        const response = await axios.put(API_BASE_URL + "/streams/configs/" + streamConfigId, streamConfig, { headers: getAuthHeader()})
        if (response.status === STATUS_SUCCESS) {
            return response.data
        } 
    } catch (error) {
        if (error.response.status === STATUS_UNAUTHORIZED) {
            await handleUnauthorizedRequest()
        } 
        throw error;
    }
}


export async function getUserStreamConfigs(page : number, size : number, sort : string) :  Promise<Page<StreamConfigResponse>> {
    getSession()
    try {
        const response = await axios.get(API_BASE_URL + "/streams/configs/customers", { headers: getAuthHeader(), params: {page: page, size: size, sort: sort} })
        if (response.status === STATUS_SUCCESS) {
            return response.data
        } 
    } catch (error) {
        if (error.response && error.response.status === STATUS_UNAUTHORIZED) {
            await handleUnauthorizedRequest()
        }
        return null
    }
}


export async function linkCustomerToStreamConfig(streamConfigId : string) {
    getSession()
    if(streamConfigId.length > 0) {
        try {
            const response = await axios.put(API_BASE_URL + "/streams/configs/" + streamConfigId + "/customers/link", {} , { headers: getAuthHeader()})
            if (response.status === STATUS_SUCCESS) {
                return true
            } 
        } catch (error) {
            if (error.response.status === STATUS_UNAUTHORIZED) {
                await handleUnauthorizedRequest()
            }
            return false
        }
    }
    return false
}


export async function uploadUserCustomBackground(streamConfigId : string, isBuilderConfig : boolean, streamBuilderStore: StreamBuilderStore) {
    getSession()
    try {
        const myFileUpload : MyFile =  streamBuilderStore.getSelectedImage(isBuilderConfig) 
        var bodyFormData = new FormData();
        bodyFormData.append('file', myFileUpload.file);
        const response = await axios.put(API_BASE_URL + "/streams/configs/" + streamConfigId + "/upload/user/customBackground", bodyFormData,{ headers : getAuthHeaderWithContentJson() })
        if (response.status === 200) {
            return response.data.backgroundUrl
        }
    } catch (error) {
        if (error.response.status === STATUS_UNAUTHORIZED) {
            await handleUnauthorizedRequest()
        }
        throw error;
    }
}


export async function uploadCustomBackground(streamConfigId : string, isBuilderConfig : boolean, streamBuilderStore: StreamBuilderStore) {
    try {
        const myFileUpload : MyFile =  streamBuilderStore.getSelectedImage(isBuilderConfig) 
        var bodyFormData = new FormData();
        bodyFormData.append('file', myFileUpload.file);
        const response = await axios.put(API_BASE_URL + "/streams/configs/" + streamConfigId + "/upload/customBackground", bodyFormData,{ headers : { "Content-Type": "multipart/form-data" } })
        if (response.status === 200) {
            return true
        }
    } catch (error) {
        return false
    }
    return false
}

export async function deleteCustomBackground(streamConfigId : string) {
    getSession()
    try {
        const response = await axios.delete(API_BASE_URL + "/streams/configs/" + streamConfigId + "/delete/customBackground",  { headers: getAuthHeader()})
        if (response.status === STATUS_SUCCESS) {
            return true
        }
    } catch (error) {
        if (error.response.status === STATUS_UNAUTHORIZED) {
            await handleUnauthorizedRequest()
        }
        throw error;
    }
}

export async function getStreamConfigById(streamConfigId: string, token: string ) :  Promise<StreamConfigResponse> {
    const response = await axios.get(API_BASE_URL + "/streams/configs/" + streamConfigId, {params: {token: token} })
    if (response.status === 200) {
        return response.data
    } 
    return null
}

export async function getTemplateGallery() {
    const response = await axios.get(API_BASE_URL + "/streams/configs/templateGallery")
    if (response.status === 200) {
        return response.data
    } 
    return null
}


export function previewWindowStyle(pc : ProductConfig, backgroundImage, width, height) {
    if (pc.backgroundType === BackgroundType.BACKGROUND_COLOR) {
        return { 
            backgroundColor : pc.backgroundColor, 
            fontSize: `${(width/1000) * 60}px`
        }
    } else if (pc.backgroundType === BackgroundType.CUSTOM_BACKGROUND) {
        return {
            backgroundImage:`url(${backgroundImage && backgroundImage.url ? backgroundImage.url : pc.customBackgroundUrl})`,
            fontSize : `${(width/1000) * 60}px`,
            backgroundSize: `${width}px ${height}px`
          }
    } else if (pc.backgroundType === BackgroundType.DEFAULT_BACKGROUND) {
        return {
            backgroundImage: `url(${S3_BUCKET_BASE_URL + '/default/' + pc.defaultBackgroundUrl}.png)`,
            fontSize : `${(width/1000) * 60}px`,
            backgroundSize: `${width}px ${height}px`
        }
    }
}