import RenderIf from "Components/Structure/RenderIf";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStores } from "Store/StoreConfig";
import { MyFile } from "Types/GeneralTypes";
import { SUPPORTED_IMAGE_TYPES } from "Utilities/GeneralConstants";
import ImageGalleryItem from "./ImageGalleryItem";

const FIVE_MB_IN_BYTES = 5000000

const UploadImageBox = observer(({updateCallback,isBuilderConfig}) => {
    const { StreamBuilderStore } = useStores();
    const [uploadedImageFiles, setUploadedImageFiles] = useState(StreamBuilderStore.getUploadedImageFiles(isBuilderConfig))
    const [selectedImage, setSelectedImage] = useState(StreamBuilderStore.getUploadedImagesSelected(isBuilderConfig))
    const [hoverActive, setHoverActive] = useState(false)
    const [imageError, setImageError] = useState("")
    
    const handleDragEnter = e => {
        e.preventDefault();
        setHoverActive(true)
    };

    const handleDragLeave = e => {
        e.preventDefault();
        setHoverActive(false)
    };

    const handleDragOver = e => {
        e.preventDefault();
        setHoverActive(true)
    };

    const handleDrop = e => {
        e.preventDefault();    
        let newFile : File = e.dataTransfer.files[0]
        imageUploaded(newFile)
        setHoverActive(false)
    };

    const imageClicked = (imageNumber) => {
        setSelectedImage(imageNumber)
        StreamBuilderStore.setUploadedImageSelected(imageNumber,isBuilderConfig)
        updateCallback(uploadedImageFiles[imageNumber].url)
    }

    const deleteClicked = (e,imageNumber) => {
        e.stopPropagation();
        let newUploadedImageFiles = uploadedImageFiles.filter((value, index) => index!==imageNumber)
        setUploadedImageFiles(newUploadedImageFiles) 
        StreamBuilderStore.setUploadedImageFiles(newUploadedImageFiles,isBuilderConfig,true)
        if (newUploadedImageFiles.length === 0) {
            updateCallback("")
        }
    }

    const imageUploaded = (newFile : File ) => {
        setImageError("")
        if (validateImageUpload(newFile)) {
            let newMyFile : MyFile = { name: newFile.name , size: Math.round(newFile.size/1000), url: URL.createObjectURL(newFile), file: newFile }
            let newUploadedImageFiles = []
            if (uploadedImageFiles.length === 3) {
                newUploadedImageFiles=[uploadedImageFiles[1],uploadedImageFiles[2],newMyFile]
            } else {
                newUploadedImageFiles = [...uploadedImageFiles,newMyFile]
            }
            setUploadedImageFiles(newUploadedImageFiles)
            StreamBuilderStore.setUploadedImageFiles(newUploadedImageFiles,isBuilderConfig, true)
            setSelectedImage(newUploadedImageFiles.length - 1)
            StreamBuilderStore.setUploadedImageSelected(newUploadedImageFiles.length - 1,isBuilderConfig)
            updateCallback(newUploadedImageFiles[newUploadedImageFiles.length - 1].url)
        }
    }

    const validateImageUpload = (file : File) => {
        if (file.size > FIVE_MB_IN_BYTES) {
            setImageError("Please ensure your file is smaller than 5MB.")
            return false
        }
        if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
            setImageError("Please ensure your file is a PNG or JPG file.")
            return false
        }
        return true
    }


    useEffect(() => {
    }, [uploadedImageFiles, setSelectedImage, setUploadedImageFiles]);


    return (
        <div className="mt-1 sm:mt-0">
            <div className={`flex justify-center px-6 pt-4 pb-5  border-2 ${hoverActive ? 'border-blue-600' : 'border-gray-300'} border-dashed rounded-md`}
                onDrop={e => handleDrop(e)}
                onDragOver={e => handleDragOver(e)}
                onDragEnter={e => handleDragEnter(e)}
                onDragLeave={e => handleDragLeave(e)}
            >
            <div className="text-center">
                    <svg
                    className={`mx-auto h-12 w-12 ${hoverActive ? 'text-blue-600' : 'text-gray-400'}`}
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                    >
                    <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                    <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none "
                    >
                        <span>Upload a file</span>
                        <input onChange={e => imageUploaded(e.target.files[0])} id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, 1280x720px, up to 5MB </p>

            </div>
            </div>

            <RenderIf condition={imageError.length > 0}>
                <div className="text-sm text-red-600  mt-1">{imageError}</div>
            </RenderIf>

            <RenderIf condition={uploadedImageFiles.length > 0}>
                <ul id="gallery" className="flex flex-1 flex-wrap -m-1 mt-2">
                <ImageGalleryItem 
                    fileName={uploadedImageFiles.length > 0 ? uploadedImageFiles[0].name : "" } 
                    fileSize={uploadedImageFiles.length > 0 ? uploadedImageFiles[0].size : "" } 
                    imageSource={uploadedImageFiles.length > 0 ? uploadedImageFiles[0].url : "" } 
                    selected={selectedImage === 0}
                    imageClicked={ () =>imageClicked(0)}
                    deleteClicked={ (e) => deleteClicked(e,0) }
                />
                <ImageGalleryItem 
                    fileName={uploadedImageFiles.length > 1 ? uploadedImageFiles[1].name : "" } 
                    fileSize={uploadedImageFiles.length > 1 ? uploadedImageFiles[1].size : "" } 
                    imageSource={uploadedImageFiles.length > 1 ? uploadedImageFiles[1].url  : ""} 
                    selected={selectedImage === 1}
                    imageClicked={ () =>imageClicked(1)}
                    deleteClicked={ (e) => deleteClicked(e,1) }
                />
                <ImageGalleryItem 
                    fileName={uploadedImageFiles.length > 2 ? uploadedImageFiles[2].name : "" } 
                    fileSize={uploadedImageFiles.length > 2 ? uploadedImageFiles[2].size : "" } 
                    imageSource={uploadedImageFiles.length > 2 ? uploadedImageFiles[2].url  : ""} 
                    selected={selectedImage === 2}
                    imageClicked={ () =>imageClicked(2)}
                    deleteClicked={ (e) => deleteClicked(e,2) }
                />
                </ul>
            </RenderIf>
        </div>
    )
})

export default UploadImageBox

