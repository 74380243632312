import { EyeIcon } from "@heroicons/react/outline"
import { PencilAltIcon } from "@heroicons/react/outline"
import Pagination from "Components/Structure/Pagination"
import React, { useEffect, useState } from "react"
import { getUserStreamConfigs } from "Services/StreamConfigService"
import { useStores } from "Store/StoreConfig"
import { MyFile, Page } from "Types/GeneralTypes"
import { StreamConfig, StreamConfigResponse, StreamStatus } from "Types/StreamTypes"
import { formatDate, formatTime, truncateText } from "Utilities/HelperFunctions"
import { PreviewStreamModalBody } from "Components/StreamBuilder/Modals/PreviewStreamModalBody"
import { ModalFrame } from "Components/StreamBuilder/Modals/ModalFrame"
import { DEFAULT_STREAM_CONFIG } from "Store/StreamBuilderStore"
import { EditStreamModalBody } from "Components/StreamBuilder/Modals/EditStreamModalBody"
import { getProductTitle } from "Utilities/GeneralConstants"
import { PublishStreamModalBody } from "Components/StreamBuilder/Modals/PublishStreamModalBody"
const REFRESH_MILLI_SECONDS = 5000

const getStatusComponent = (streamStatus : StreamStatus) => {
    if (streamStatus === StreamStatus.PENDING) {
        return  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800"> Draft </span>
    }else if (streamStatus === StreamStatus.DEPLOYING) {
        return  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"> Load </span>
    }else if (streamStatus === StreamStatus.ACTIVE) {
        return  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Live </span>
    }else if (streamStatus === StreamStatus.COMPLETED) {
        return  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"> Ended </span>
    }
}

const RocketShipIcon = () => {
    return (
      <svg className="ml-1.5 -mr-1" width="18" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path strokeWidth="1.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6.13395 13.8133C5.16029 16.7343 1.26566 16.7343 1.26566 16.7343C1.26566 16.7343 1.26566 12.8397 4.18664 11.866M9.05493 12.8397L14.8969 6.99774C17.109 4.78564 17.0874 2.57354 16.9467 1.63013C16.925 1.48488 16.8573 1.35042 16.7534 1.24657C16.6496 1.14273 16.5151 1.07499 16.3699 1.05334C15.4265 0.91258 13.2144 0.891002 11.0023 3.1031L5.1603 8.94505M9.05493 12.8397L5.1603 8.94505M9.05493 12.8397L9.77631 16.4465C9.80074 16.5687 9.85789 16.682 9.94165 16.7743C10.0254 16.8665 10.1326 16.9343 10.2519 16.9705C10.3712 17.0066 10.498 17.0096 10.6189 16.9793C10.7397 16.949 10.8501 16.8865 10.9382 16.7983L13.7216 14.015C13.7855 13.9511 13.8362 13.8752 13.8708 13.7917C13.9054 13.7081 13.9232 13.6186 13.9232 13.5282V7.97141M5.1603 8.94505L1.55346 8.2237C1.43126 8.19926 1.31799 8.14211 1.22573 8.05836C1.13346 7.9746 1.06565 7.86737 1.02954 7.7481C0.99343 7.62883 0.990366 7.502 1.02068 7.38113C1.05099 7.26025 1.11354 7.14987 1.20165 7.06176L3.98499 4.27842C4.04892 4.21449 4.12481 4.16378 4.20835 4.12918C4.29188 4.09458 4.3814 4.07677 4.47182 4.07677H10.0286" />
      </svg>
    )
  }

const Spinner = () => {
    return (
        <svg className="animate-spin  ml-1.5 -mr-0.5 h-4 w-4 text-blue"  fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    )
}

enum ManageStreamModalTypes {
    PUBLISH = 'PUBLISH',
    PREVIEW = 'PREVIEW',
    EDIT = 'EDIT',
}

const getManageStreamModalWidth = (modalType : ManageStreamModalTypes) => {
    if (modalType === ManageStreamModalTypes.PREVIEW) {
        return "70rem" 
    } else if (modalType === ManageStreamModalTypes.EDIT) {
        return "78rem" 
    } else if (modalType === ManageStreamModalTypes.PUBLISH) {
        return "60rem"
    }
}

export const StreamTable = ({currentTimeUTC}) => {
    const { StreamBuilderStore } = useStores()
    const [openManageStreamsModal, setOpenManageStreamsModal] = useState(false)
    const [streamConfigToAction, setStreamConfigToAction] = useState<StreamConfig>(DEFAULT_STREAM_CONFIG)
    const PAGE_SIZE = 8;
    const [userStreamConfigsPage , setUserStreamConfigsPage] = useState<Page<StreamConfigResponse>>({items: [],hasNext : false,page: 0,totalItems: 0})
    const [currentModal, setCurrentModal] =  useState(ManageStreamModalTypes.PREVIEW)
    const [editModalSpinning, setEditModalSpinning] = useState(false)
    const [previewModalSpinning, setPreviewModelSpinning] = useState(false)
    const [allIntervals, setAllIntervals] = useState([])
    

    async function fetchUserStreamConfigs(newPage) {
        try {
            let userStreamConfigs = await getUserStreamConfigs(newPage, PAGE_SIZE, "createdAt,DESC")
            clearInterval(StreamBuilderStore.dashBoardStreamTableInterval)
            const interval = setInterval(async () => {
                let userStreamConfigs = await getUserStreamConfigs(newPage, PAGE_SIZE, "createdAt,DESC")
                if (userStreamConfigs) {
                    setUserStreamConfigsPage(userStreamConfigs)
                }
            }, REFRESH_MILLI_SECONDS);
            setAllIntervals([...allIntervals,interval])
            StreamBuilderStore.setDashBoardStreamTableInterval(interval)
            if (userStreamConfigs) {
                setUserStreamConfigsPage(userStreamConfigs)
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        updatePage(0)
        return () =>  clearInterval(StreamBuilderStore.dashBoardStreamTableInterval)
    }, []);
    

    const updatePage = async (newPage) => {
        fetchUserStreamConfigs(newPage)
    }


    const streamConfigUpdated = async (newStreamConfig) => {
        var foundIndex = userStreamConfigsPage.items.findIndex(x => x.id === newStreamConfig.id);
        userStreamConfigsPage.items[foundIndex] = newStreamConfig
        setUserStreamConfigsPage(userStreamConfigsPage)
    }


    const openManageStreamModal = async (streamConfig : StreamConfigResponse, modalType: ManageStreamModalTypes) => {
        StreamBuilderStore.resetDashboardStreamSelected()
        setStreamConfigToAction(streamConfig)
        StreamBuilderStore.setStreamConfig(streamConfig, false)
        if (modalType === ManageStreamModalTypes.EDIT && streamConfig.productConfig.customBackgroundUrl) {
            await loadCustomBackgroundForEdit(streamConfig.productConfig.customBackgroundUrl)
        }else if (modalType === ManageStreamModalTypes.PREVIEW && streamConfig.productConfig.customBackgroundUrl) {
            await loadCustomBackgroundForPreview(streamConfig.productConfig.customBackgroundUrl)
        }
        setCurrentModal(modalType)
        setOpenManageStreamsModal(true)
    }

    const loadCustomBackgroundForPreview = async (customBackgroundUrl) => {
        setPreviewModelSpinning(true)
        await loadCustomBackground(customBackgroundUrl)
        setPreviewModelSpinning(false)
    }

    const loadCustomBackgroundForEdit = async (customBackgroundUrl) => {
        setEditModalSpinning(true)
        await loadCustomBackground(customBackgroundUrl)
        setEditModalSpinning(false)
    }

    const loadCustomBackground = async (customBackgroundUrl) => {
        await fetch(customBackgroundUrl, {headers: {"Cache-Control" : 'no-cache'}})
        .then(async response => {
            const contentType = response.headers.get('content-type')
            const blob = await response.blob()
            const file = new File([blob], "background", { type: contentType })
            let myFileLoad : MyFile = { name: file.name , size: Math.round(file.size/1000), url: URL.createObjectURL(file), file: file }
            StreamBuilderStore.setUploadedImageFiles([myFileLoad],false,false)
            StreamBuilderStore.setUploadedImageSelected(0,false)
        })
    }


    const setIsOpenModal = (isOpen) => {
        setOpenManageStreamsModal(isOpen)
    }

    return (
        <React.Fragment>
            <div className="-my-2 overflow-x-auto sm:-mx-3 lg:-mx-4">
                <div className="py-2 align-middle inline-block min-w-full lg:px-4">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="table-fixed min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th 
                            scope="col"
                            className="w-1/6 pl-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"228px"}}
                        >
                            Stream Name
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"70px"}}
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 pl-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"115px"}}
                        >
                            Created
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 pl-4  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"115px"}}
                        >
                            Countdown
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3  pl-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"115px"}}
                        >
                            Stream end
                        </th>
                        <th
                            scope="col"
                            className="w-1/6 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"200px"}}
                        >
                            Stream key
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{minWidth:"315px"}}
                        >
                            Action
                        </th>
                        </tr>
                    </thead>  
                    <tbody className="bg-white divide-y divide-gray-200">
                        {userStreamConfigsPage.items.map((streamConfig) => (
                            <tr key={streamConfig.id}>
                                <td className="pl-4 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900" >{truncateText(streamConfig.productConfig.title,30)}</div>
                                    <div className="text-sm text-gray-500">{getProductTitle(streamConfig.productType)}</div>
                                </td>
                                <td className="px-2 py-4 whitespace-nowrap" >
                                    {getStatusComponent(streamConfig.streamStatus)}
                                </td>
                                <td className="pl-4 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900" >{formatDate(streamConfig.createdAt)}</div>
                                    <div className="text-sm text-gray-500">{formatTime(streamConfig.createdAt)}</div>
                                </td>
                                <td className="pl-4 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900" >{streamConfig.productConfig.countdownDate ? formatDate(streamConfig.productConfig.countdownDate) : "- -\xa0\xa0\- - -\xa0\xa0\- - - -"}</div>
                                    <div className="text-sm text-gray-500">{streamConfig.productConfig.countdownDate ? formatTime(streamConfig.productConfig.countdownDate) : "- - : - -\xa0\xa0\- -"}</div>
                                </td>
                                <td className="pl-4 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900" >{formatDate(streamConfig.streamEnd)}</div>
                                    <div className="text-sm text-gray-500">{formatTime(streamConfig.streamEnd)}</div>
                                </td>
                                <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500" >{streamConfig.streamKey}</td>
                                <td className="pr-4 py-4 whitespace-nowrap text-right text-sm font-medium" >

                                    {streamConfig.streamStatus === "PENDING" || streamConfig.streamStatus === "ACTIVE" ?
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-2 py-2  border border-transparent text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
                                            onClick={e => openManageStreamModal(streamConfig,ManageStreamModalTypes.EDIT) }
                                        >
                                            Update
                                            {editModalSpinning && streamConfig.id === streamConfigToAction.id ? 
                                            <div className="mt-0.5 mb-0.5">
                                                 <Spinner/>
                                            </div>
                                            :
                                            <React.Fragment>
                                                <PencilAltIcon className="ml-1 -mr-1 h-5 w-5" aria-hidden="true" />
                                            </React.Fragment>
                                        }
                                        </button>: <div/>
                                    }

                                    {streamConfig.streamStatus === "PENDING" || streamConfig.streamStatus === "ACTIVE"  ?
                                        <button
                                          type="button"
                                          className="inline-flex items-center px-2 py-2 ml-2.5 mr-2.5 border border-transparent text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
                                          onClick={e => openManageStreamModal(streamConfig,ManageStreamModalTypes.PUBLISH) }
                                        >
                                            Publish
                                            <RocketShipIcon />
                                        </button> : <div/>
                                    }

                                    <button
                                        type="button"
                                        className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
                                        onClick={e => openManageStreamModal(streamConfig,ManageStreamModalTypes.PREVIEW) }
                                    >
                                        Preview
                                        {previewModalSpinning && streamConfig.id === streamConfigToAction.id ?  
                                            <div className="mt-0.5 mb-0.5">
                                                <Spinner/>
                                            </div>
                                            :
                                            <React.Fragment>
                                                 <EyeIcon className="ml-1 -mr-1 h-5 w-5" aria-hidden="true" />
                                            </React.Fragment>
                                        }
                                    </button>

                                </td>
                            </tr>
                        ))}
                        { Array.from(Array( (PAGE_SIZE - userStreamConfigsPage.items.length)).keys()).map((num) => (
                            <tr key={num.toString()}>
                                <td className="px-2" style={{height: "73px"}}>
                     
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            <Pagination hasNext={userStreamConfigsPage.hasNext} page={userStreamConfigsPage.page} pageSize={PAGE_SIZE} totalItems={userStreamConfigsPage.totalItems} updatePage={updatePage}/>
            
            <ModalFrame 
                open={openManageStreamsModal} 
                setOpen={setIsOpenModal} 
                modalWidth={getManageStreamModalWidth(currentModal)}
                modalBody={ currentModal === ManageStreamModalTypes.PREVIEW ? 
                            <PreviewStreamModalBody productType={streamConfigToAction.productType} productConfig={streamConfigToAction.productConfig} backgroundImage={StreamBuilderStore.getSelectedImage(false)}/>
                            : currentModal === ManageStreamModalTypes.EDIT ?  
                            <EditStreamModalBody streamConfigUpdated={streamConfig => streamConfigUpdated(streamConfig)} />  :  <PublishStreamModalBody streamConfigUpdated={streamConfig => streamConfigUpdated(streamConfig)} currentTimeUTC={currentTimeUTC} /> 
                          } 
                isBasicCloseStyle={false}
                easyClose={false}
              />
        </React.Fragment>
    )
}
  

