import React from 'react'

export const GuideParagraph = ({text}) => {
    return (
        <React.Fragment>
            <p className="text-md sm:text-lg leading-7 sm:leading-8 font-normal text-gray-600 ">
                  {text} 
            </p>
        </React.Fragment>
    )
}


