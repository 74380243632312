import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export const ModalFrame = ({open, setOpen, modalBody, modalWidth, isBasicCloseStyle, easyClose}) => {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={easyClose ? setOpen : () =>  {}}>
        <div className="justify-center min-h-screen px-4 pb-20 text-center block sm:mx-10 md:mx-10 ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {isBasicCloseStyle ? 
                <div style={{maxWidth: modalWidth}} className="w-full inline-block align-bottom bg-white rounded-lg px-6 pb-6 text-left overflow-hidden shadow-xl transform transition-all my-20 sm:align-middle pt-8">
                  <div className=" sm:block absolute top-0 right-0 pt-3 pr-6 text-lg">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-700 "
                      onClick={() => setOpen(false)}
                    >
                      
                      <XIcon className="h-6 w-6 inline-block  rounded-lg ml-2 mb-0.5" aria-hidden="true" />
                    </button>
                  </div>
                  {modalBody}
                </div>
              :
                <div style={{maxWidth: modalWidth}} className="w-full inline-block align-bottom bg-white rounded-lg px-6 pb-6 text-left overflow-hidden shadow-xl transform transition-all my-20 sm:align-middle pt-4">
                  <div className=" sm:block absolute top-0 right-0 pt-3 pr-6 text-lg">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-600 hover:text-gray-800 "
                      onClick={() => setOpen(false)}
                    >
                      Close
                      <XIcon className="h-6 w-6 inline-block bg-gray-200 rounded-lg ml-2 mb-0.5" aria-hidden="true" />
                    </button>
                  </div>
                  {modalBody}
              </div>
            }
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}