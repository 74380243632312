import CountdownAudioView from 'Components/StreamView/CountdownAudioView'
import MultiVoteAudioView from 'Components/StreamView/MultiVoteAudioView'
import RenderIf from 'Components/Structure/RenderIf'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { ProductType } from 'Types/StreamTypes'

const PreviewWindow = observer(({productType, productConfig, backgroundImage, defaultWidth, defaultHeight, borderStyle }) => {
  const componentRef = useRef()
  if (document.documentElement.clientWidth < defaultWidth) {
    defaultWidth = document.documentElement.clientWidth - 50
  }
  const { width, height } = useResize(componentRef, defaultWidth, defaultHeight)
  return <div style={{ paddingBottom: '56.25%', border: borderStyle, position: 'relative' }}>
    <div ref={componentRef} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>

    <RenderIf condition={productType === ProductType.COUNTDOWN}>
      <CountdownAudioView  productConfig={productConfig} backgroundImage={backgroundImage} width={width} height={height} />
    </RenderIf>

    <RenderIf condition={productType === ProductType.MULTI_VOTE}>
      <MultiVoteAudioView  productConfig={productConfig} backgroundImage={backgroundImage} width={width} height={height} />
    </RenderIf>
          
    </div>
  </div>
})

export default PreviewWindow

export const useResize = (myRef, defaultWidth, defaultHeight) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight
  })

  const [dimensions, setDimensions] = useState({ width: defaultWidth, height: defaultHeight })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};