import PreviewWindow from "Components/StreamBuilder/PreviewWindow";
import RenderIf from "Components/Structure/RenderIf";
import { useEffect, useState } from "react";
import { getStreamConfigById } from "Services/StreamConfigService";
import { StreamConfigResponse } from "Types/StreamTypes";
import { useStores } from "Store/StoreConfig"

const REFRESH_MILLI_SECONDS = 3000

export const TemplateView = () => {

    const [streamConfig, setStreamConfig] = useState<StreamConfigResponse>(null)
    const { StreamBuilderStore } = useStores()

    useEffect(() => {
        var tawk = StreamBuilderStore.getTawkTo()
        tawk.hideWidget()
        const pathNameSplit = window.location.pathname.split("/")
        async function fetchStreamConfig(interval) {
            try {
                const streamConfigRes = await getStreamConfigById(pathNameSplit[2], pathNameSplit[3])
                setStreamConfig(streamConfigRes)
            } catch (e) {
                console.error(e);
            }
        };
        fetchStreamConfig(null)
        const interval = setInterval(async () => {
            fetchStreamConfig(interval)
            tawk.hideWidget()
        }, REFRESH_MILLI_SECONDS);
        return () => clearInterval(interval);
    }, []);
  
    return (
        <RenderIf condition={streamConfig != null}>
            <PreviewWindow productType={streamConfig ? streamConfig.productType : null} productConfig={streamConfig ? streamConfig.productConfig : null} backgroundImage={null} defaultWidth={1280} defaultHeight={720} borderStyle={'none'}/>
        </RenderIf>
    )
}


