import { Dialog } from '@headlessui/react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import InputWithLabel from 'Components/Structure/InputWithLabel'
import RenderIf from 'Components/Structure/RenderIf'
import StandardButton from 'Components/Structure/StandardButton'
import React, { useState } from 'react'
import { createCognitoUser } from 'Services/AuthService'
import { GENERIC_ERROR_MSG} from 'Utilities/GeneralConstants'
import { hashText, isNumber } from 'Utilities/HelperFunctions'
import { CheckIcon } from '@heroicons/react/outline'
import { AuthModalTypes } from 'Types/GeneralTypes'
import { verifyCustomerEmail } from 'Services/CustomerService'
import { useStores } from 'Store/StoreConfig'


export const VerifyEmailModalBody = ({setCurrentModal, message}) => {
    const { UserStore } = useStores()
    const [customerEmail] = useState(UserStore.signUpEmail)
    const [code, setCode] = useState("")
    const [verifyEmailError, setVerifyEmailError] = useState("")
    const [codeResent, setCodeResent] = useState("")
    const [loading, setLoading] = useState(false)
    const [hash] = useState(hashText(customerEmail))
    const complete = isNumber(code) && code.length === 6
    const [verified, setVerified] = useState(false)

    const verifyEmail = (event) => {
        event.preventDefault()
        if(complete){
            setLoading(true)
            const cognitoUser : CognitoUser = createCognitoUser(customerEmail)
            cognitoUser.confirmRegistration(code, true, async function(err, result) {
                if (err) {
                    if(err["code"] === "CodeMismatchException"){
                        setVerifyEmailError("Invalid or expired verfication code")
                    }else if(err["code"] === "ExpiredCodeException"){
                        setVerifyEmailError("Invalid or expired verfication code")
                    }else if(err.message === "User cannot be confirmed. Current status is CONFIRMED"){
                        setVerified(true)
                    }else{
                        setVerifyEmailError(GENERIC_ERROR_MSG)
                    }
                }else{
                    await verifyCustomerEmail(customerEmail)
                    setVerified(true)
                }
                setLoading(false)
            },  {emailId :hash} )
        }
    }


    const inputCode = (event) => {
        const inputValue = event.target.value.replace(/\s+/g, '')
        if(!inputValue || (inputValue.length <= 6 &&  isNumber(inputValue)) ){
            setCode(inputValue)
        }else{
            event.preventDefault()
        }        
    }


    const resendCode = () => {
        const cognitoUser : CognitoUser = createCognitoUser(customerEmail)
        cognitoUser.resendConfirmationCode(function(err, result) {
            if (err) {
                setCodeResent("Failed to resend code.")
            }else{
                setCodeResent("Successfully resent code!")
            }
        }, {emailId :hash})
    }


    return (
        <React.Fragment>
              <RenderIf condition={!verified} >
              <div>
                <div className="text-center mb-4">
                  <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                    Verify email
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                        A verfication code has been sent to your email. Please insert and verify your code below.
                    </p>
                  </div>
                </div>

                <InputWithLabel
                    id="code"
                    label=""
                    placeholder="Six digit verification code" 
                    onChange={e => inputCode(e)}
                    value={code}
                    type="text"
                />

                <RenderIf condition={!codeResent}>
                    <div className="text-right text-sm text-gray-500">
                        <button  onClick={e => resendCode()} className="border-blue-250 ">Resend code</button>
                    </div>
                </RenderIf>

                <RenderIf condition={codeResent.length > 0}>
                    <div className="text-right text-sm text-gray-500" >
                        {codeResent}
                    </div>
                </RenderIf>


                <RenderIf condition={verifyEmailError.length > 0}>
                    <div className="mb-4 mt-2 text-sm text-red-600 text-center"> {verifyEmailError} </div>
                </RenderIf>

              </div>

              <div className="text-center mt-4">
                    <StandardButton
                        disabled={!complete}
                        onClick={e => verifyEmail(e)}
                        text={"Verify"}
                        isSpinning={loading}
                    />
                </div>
            </RenderIf>

            <RenderIf condition={verified} >
            <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="text-center mb-4">
                  <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                    Sucessfully verified!
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                        {message}
                    </p>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                    <StandardButton
                        disabled={false}
                        onClick={e => setCurrentModal(AuthModalTypes.LOGIN)}
                        text={"Log in"}
                        isSpinning={false}
                    />
                </div>
            </RenderIf>

        </React.Fragment>
    )
}
