import './styles.css'
import BackNext from 'Components/Structure/BackNext'
import { useStores } from 'Store/StoreConfig'
import React from 'react'
import CustomizeStream from './CustomizeStream'
import { BackgroundType, ProductType, StreamConfig } from 'Types/StreamTypes'
import { WHITE } from 'Utilities/GeneralConstants'
import { getFutureDateTime } from 'Utilities/HelperFunctions'


const BuildStepTwo = () => {
  const incomplete = false
  const { StreamBuilderStore } = useStores();

  const toBuildStepThree = () => {
    const streamConfig : StreamConfig = StreamBuilderStore.getStreamConfig(true)
    if (streamConfig.productConfig.backgroundType === BackgroundType.CUSTOM_BACKGROUND && streamConfig.productConfig.customBackgroundUrl.length === 0) {
      streamConfig.productConfig.backgroundType = BackgroundType.BACKGROUND_COLOR
      streamConfig.productConfig.backgroundColor = WHITE
    }
    streamConfig.streamEnd = streamConfig.productType === ProductType.COUNTDOWN ? streamConfig.productConfig.countdownDate : getFutureDateTime(10)
    StreamBuilderStore.setStreamConfig(streamConfig,true)
    StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep + 1)
  }

  return (
    <React.Fragment>
      <div className="mt-3 sm:mt-6" />

        <CustomizeStream isBuilderConfig={true}/>

      <BackNext
        onBack={() => StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep - 1)}
        onNext={() => toBuildStepThree()}
        disableNext={incomplete} />
    </React.Fragment>
  )
}

export default BuildStepTwo