import Layout from "../../Components/Structure/Foundation/Layout"
import Body from "Components/Structure/Foundation/Body"
import { Helmet } from "react-helmet"
import { GUIDE_DESCRIPTION, GUIDE_TITLE } from "Utilities/SEOConstants"
import { Link } from "react-router-dom"
import { GuidePost } from "Types/GeneralTypes"
import { choosingVideoLatencyContent } from "./GuideContent/ChoosingVideoLatency"

const guidePosts : Array<GuidePost> = [
    choosingVideoLatencyContent,
]

export const Guide = () => {

  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
        <Helmet>
            <title>{GUIDE_TITLE}</title>
            <meta name="description" content={GUIDE_DESCRIPTION} />
        </Helmet>
        <div className={`bg-blue-800 pt-10 lg:pt-16 pb-48 -mb-40 lg:-mb-40 text-center px-8 `}> 
            <h1 className="tracking-tight font-bold text-white text-4xl md:text-5xl lg:text-5xl xl:text-6xl text-center ">
                Streaming Guides
            </h1>
            <h1 className="tracking-tight font-bold text-blue-300 text-4xl md:text-5xl lg:text-5xl xl:text-6xl text-center ">
                level up your game
            </h1>
            <p className="tracking-tight text-lg sm:text-xl text-white font-normal mt-6 -mb-4 md:mt-10">
                Simple and easy to follow tutorials to help you master 24/7 live streaming
            </p>
        </div>
        <Body> 
            <div className="relative pt-8 pb-20 px-4 sm:px-6 lg:pt-10 lg:px-8">
                <div className="relative max-w-7xl mx-auto">
                    <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {guidePosts.map((post) => (
                            <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <Link to={post.href}> 
                                        <img className="w-full" src={post.imageUrl} alt="" />
                                    </Link>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <Link className="block mt-2" to={post.href}> 
                                            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                                            <p className="mt-3 text-base text-gray-500">{post.introduction}</p>
                                        </Link>
                                    </div>
                                    <div className="mt-6 flex items-center">
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            {post.datePublished}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Body> 
    </Layout>
  )
}
