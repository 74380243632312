import {observable, action, makeObservable} from "mobx"
import { MyFile } from "Types/GeneralTypes"
import { BackgroundType, CountdownType, ProductType, StreamConfig, VoteConfig } from "Types/StreamTypes"
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_COUNTDOWN_FONT_COLOR, DEFAULT_COUNTDOWN_FONT, 
         DEFAULT_SUBTITLE, DEFAULT_TITLE, DEFAULT_TITLE_COLOR, DEFAULT_TITLE_FONT, DEFAULT_COUNTDOWN_BOX_COLOR, DEFAULT_VOTE_TABLE_FONT, DEFAULT_AUDIO } from "Utilities/GeneralConstants"
import { getFutureDateTime } from "Utilities/HelperFunctions"
import { v4 as uuidv4 } from 'uuid';
import TawkTo from 'tawkto-react'

export const getDefaultVoteOption = (number) => {
    return {
            "id" : uuidv4(),
            "name" : `Name${number}`,
            "code" :  `!Code${number}`,
            "count" :  "0",
            "avatarUrl" : process.env.PUBLIC_URL + '/images/DefaultMultiVoteAvatar.svg',
            "imagesUpdated": false
        }
}

export const DEFAULT_VOTE_CONFIG : VoteConfig = {  
    apiKey : "",
    includeImages: true,
    font: DEFAULT_VOTE_TABLE_FONT,
    fontColor: DEFAULT_TITLE_COLOR,
    dropShadow: false,
    voteOptions : [
        getDefaultVoteOption(1),
        getDefaultVoteOption(2),
        getDefaultVoteOption(3),
        getDefaultVoteOption(4)
    ]
}

export const DEFAULT_STREAM_CONFIG = { id: "", videoId : "", streamKey: "", customerId: "", paymentEmail: "", productType: ProductType.COUNTDOWN, streamEnd: null, productConfig: 
    { title : DEFAULT_TITLE, titleColor : DEFAULT_TITLE_COLOR, titleFont: DEFAULT_TITLE_FONT, titleExtraLarge: false, titleDropShadow: false, subtitle : DEFAULT_SUBTITLE, backgroundType: BackgroundType.DEFAULT_BACKGROUND,
      backgroundColor : DEFAULT_BACKGROUND_COLOR, defaultBackgroundUrl: "TakeOff", customBackgroundUrl : null, customAudioUrl : null, defaultAudio: DEFAULT_AUDIO[0], audioEnabled: false, countdownDate :  getFutureDateTime(30), 
      countdownFontColor : DEFAULT_COUNTDOWN_FONT_COLOR, countdownBoxColor: DEFAULT_COUNTDOWN_BOX_COLOR , countdownFont: DEFAULT_COUNTDOWN_FONT, countdownType: CountdownType.BASIC, countdownExtraLarge: false, countdownDropShadow: false, voteConfig: null}}

export class StreamBuilderStore {
    streamBuilderStep: number = 0
    streamConfig : StreamConfig = DEFAULT_STREAM_CONFIG
    uploadedImageFiles : Array<MyFile> = []
    uploadedImageSelected : number = 0 

    dashBoardStreamConfig : StreamConfig = DEFAULT_STREAM_CONFIG
    dashBoardUploadedImageFiles : Array<MyFile> = []
    dashboardUploadedImageSelected : number = 0 
    dashboardNewImageUploaded : boolean = false
    dashBoardStreamTableInterval: number
    tawk = new TawkTo("634d19b2b0d6371309c9e7cf", "1gfiiooge")

    constructor() {
        makeObservable(this, {
            streamBuilderStep: observable,
            streamConfig: observable,
            dashBoardStreamConfig: observable,
            resetDashboardStreamSelected: action,
            setStreamBuilderStep: action,
            setStreamConfig: action,
            setVoteConfig: action,
            resetBuilderStreamConfig : action,
            resetProductConfig: action
        })
    }

    getUploadedImageFiles(isBuilderConfig: boolean) {
        return isBuilderConfig ? this.uploadedImageFiles : this.dashBoardUploadedImageFiles
    }

    getUploadedImagesSelected(isBuilderConfig: boolean) {
        return isBuilderConfig ? this.uploadedImageSelected : this.dashboardUploadedImageSelected
    }

    getSelectedImage(isBuilderConfig: boolean) : MyFile {
        if (isBuilderConfig) {
            return this.uploadedImageFiles.length > 0 ? this.uploadedImageFiles[this.uploadedImageSelected] : null
        } else {
            return this.dashBoardUploadedImageFiles.length > 0 ? this.dashBoardUploadedImageFiles[this.dashboardUploadedImageSelected] : null
        }
    }

    getStreamConfig(isBuilderConfig: boolean) {
        if (isBuilderConfig) {
            return this.streamConfig
        } else {
            return this.dashBoardStreamConfig
        }
    }

    getProductConfig(isBuilderConfig: boolean) {
        if (isBuilderConfig) {
            return this.streamConfig.productConfig
        } else {
            return this.dashBoardStreamConfig.productConfig
        }
    }

    getTawkTo() {
        return this.tawk
    }

    setStreamBuilderStep(step : number){
        this.streamBuilderStep = step
    }

    setStreamConfig(streamConfig : StreamConfig, isBuilderConfig: boolean) {
        if (isBuilderConfig) {
            this.streamConfig = streamConfig
        } else {
            this.dashBoardStreamConfig = streamConfig
        }
    }

    setUploadedImageFiles(newUploadedImageFiles : Array<MyFile>, isBuilderConfig: boolean, userEdit: boolean) {
        if (isBuilderConfig) {
            this.uploadedImageFiles = newUploadedImageFiles
        } else {
            if (userEdit) {
                this.dashboardNewImageUploaded = true
            }   
            this.dashBoardUploadedImageFiles = newUploadedImageFiles
        }
    }

    setDashBoardStreamTableInterval(interval) {
        this.dashBoardStreamTableInterval = interval
    }

    setUploadedImageSelected(uploadedImageNumber : number, isBuilderConfig: boolean) {
        if (isBuilderConfig) {
            this.uploadedImageSelected = uploadedImageNumber
        } else {
            this.dashboardUploadedImageSelected = uploadedImageNumber
        }
    }

    setVoteConfig(voteConfig: VoteConfig, isBuilderConfig : boolean) {
        if (isBuilderConfig) {
            let newStreamConfig = this.streamConfig
            newStreamConfig.productConfig.voteConfig = voteConfig
            this.streamConfig = newStreamConfig;
        } else {
            let newDashBoardStreamConfig = this.dashBoardStreamConfig
            newDashBoardStreamConfig.productConfig.voteConfig = voteConfig
            this.dashBoardStreamConfig = newDashBoardStreamConfig
        }   
    }

    resetBuilderStreamConfig() {
        this.resetProductConfig(true,this.streamConfig.productType)
        let newStreamConfig = this.streamConfig
        newStreamConfig.id = ""
        newStreamConfig.videoId = ""
        newStreamConfig.streamKey = ""
        newStreamConfig.customerId = ""
        newStreamConfig.paymentEmail = ""
        newStreamConfig.productType = ProductType.COUNTDOWN
        newStreamConfig.streamEnd = null
        this.streamConfig = newStreamConfig
    }

    resetProductConfig(isBuilderConfig: boolean, productType: ProductType) {
        let newStreamConfig = this.streamConfig
        if (!isBuilderConfig) { 
            newStreamConfig = this.dashBoardStreamConfig
            this.dashBoardUploadedImageFiles = []
            this.dashboardUploadedImageSelected = 0 
            this.dashboardNewImageUploaded = false
        } else {
            this.uploadedImageFiles = []
            this.uploadedImageSelected = 0 
        }
        newStreamConfig.productConfig.title = DEFAULT_TITLE
        newStreamConfig.productConfig.titleColor = DEFAULT_TITLE_COLOR
        newStreamConfig.productConfig.titleFont = DEFAULT_TITLE_FONT
        newStreamConfig.productConfig.titleExtraLarge = false
        newStreamConfig.productConfig.titleDropShadow = false
        newStreamConfig.productConfig.subtitle = DEFAULT_SUBTITLE
        newStreamConfig.productConfig.backgroundType = BackgroundType.DEFAULT_BACKGROUND
        newStreamConfig.productConfig.backgroundColor = DEFAULT_BACKGROUND_COLOR
        newStreamConfig.productConfig.defaultBackgroundUrl = "TakeOff"
        newStreamConfig.productConfig.customBackgroundUrl = null
        newStreamConfig.productConfig.customAudioUrl = null
        newStreamConfig.productConfig.defaultAudio = DEFAULT_AUDIO[0]
        newStreamConfig.productConfig.audioEnabled = false
        if (productType === ProductType.MULTI_VOTE) {
            newStreamConfig.productConfig.countdownDate = null
            newStreamConfig.productConfig.countdownFontColor = null
            newStreamConfig.productConfig.countdownBoxColor = null
            newStreamConfig.productConfig.countdownFont = null
            newStreamConfig.productConfig.countdownType = null
            newStreamConfig.productConfig.voteConfig = DEFAULT_VOTE_CONFIG
            newStreamConfig.productConfig.voteConfig.font = DEFAULT_TITLE_FONT
        } else {
            newStreamConfig.productConfig.countdownDate = getFutureDateTime(30)
            newStreamConfig.productConfig.countdownFontColor = DEFAULT_COUNTDOWN_FONT_COLOR
            newStreamConfig.productConfig.countdownBoxColor = DEFAULT_COUNTDOWN_BOX_COLOR
            newStreamConfig.productConfig.countdownFont = DEFAULT_COUNTDOWN_FONT
            newStreamConfig.productConfig.countdownType = CountdownType.BASIC
            newStreamConfig.productConfig.countdownExtraLarge = false
            newStreamConfig.productConfig.countdownDropShadow = false
            newStreamConfig.productConfig.voteConfig = null
        }
        if (isBuilderConfig) { 
            this.streamConfig = newStreamConfig
        } else {
            this.dashBoardStreamConfig = newStreamConfig
        }
    }

    resetDashboardStreamSelected() {
        this.dashBoardStreamConfig = DEFAULT_STREAM_CONFIG
        this.dashBoardUploadedImageFiles = []
        this.dashboardUploadedImageSelected = 0 
        this.dashboardNewImageUploaded = false
    }
    
}
