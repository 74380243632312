import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { MAX_STREAM_DAYS } from 'Utilities/GeneralConstants';


const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '15',
  },
  {
    value: 50,
    label: '30',
  },
  {
    value: 75,
    label: '45',
  },
  {
    value: 100,
    label: '60',
  },
];

const StyledSlider = styled(Slider)({
  color: 'rgb(37 99 235)',
  height: 7,
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'rgb(37 99 235)',
  },
});

function valuetext(value) {
  return `${value}`;
}

export default function LabelSlider({setSliderDays}) {
  return (
    <Box sx={{ width: '100%', p: 1 }}>
      <StyledSlider
        aria-label="Always visible"
        defaultValue={25}
        getAriaValueText={valuetext}
        onChange ={(_, newValue:any) => {setSliderDays(Math.floor(newValue*(MAX_STREAM_DAYS/100)))}}
        step={100/MAX_STREAM_DAYS}
        marks={marks}
        valueLabelDisplay="on"
        valueLabelFormat={value => <div>{Math.floor(value*(MAX_STREAM_DAYS/100))}</div>}
      />
    </Box>
  );
}