import { useCallback, useEffect, useState } from "react"
import {Link} from 'react-router-dom'
import Footer from "Components/Structure/Foundation/Footer"
import StandardButton from "Components/Structure/StandardButton"
import InputWithLabel from "../Structure/InputWithLabel"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"
import {createAuthDetails, createCognitoUser, loadUser} from "Services/AuthService"
import { useHistory } from "react-router-dom"
import { EMAIL_NOT_VERIFIED, GENERIC_ERROR_MSG, INCORRECT_LOGIN_MSG } from "Utilities/GeneralConstants"
import RenderIf from "Components/Structure/RenderIf"
import { observer } from "mobx-react"
import { useStores } from "Store/StoreConfig"
import { isEmailValid } from "Utilities/HelperFunctions"

const Login = observer(() => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")
    const [showLoginError, setShowLoginError] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const complete = isEmailValid(email) && password.length > 0
    const { UserStore } = useStores()


    const onSubmit = useCallback((event) => {
        event.preventDefault()
        setShowLoginError(false)
        if(complete){
            const authDetails : AuthenticationDetails = createAuthDetails(email,password)
            const user : CognitoUser = createCognitoUser(email)
            setLoading(true)
            user.authenticateUser(authDetails,{
                onSuccess: async (data) => {
                    if (await loadUser(user, UserStore, data.getAccessToken()['jwtToken'])) {
                        history.push("/")
                    } else {
                        setLoginError(GENERIC_ERROR_MSG)
                        setShowLoginError(true)
                    }
                    setLoading(false)
                },
                onFailure: (err) => {
                    if (err["code"] === "NotAuthorizedException") {
                        setLoginError(INCORRECT_LOGIN_MSG)
                    } else if (err["code"] === "UserNotConfirmedException") {
                        setLoginError(EMAIL_NOT_VERIFIED)
                    } else {
                        setLoginError(GENERIC_ERROR_MSG)
                    }
                    setShowLoginError(true)
                    setLoading(false)
                }
            })
        }
    },[complete, email, history, password])


    useEffect(() => {
        if(UserStore.user){
            history.push("/")
        }

        const listener = event => {
            if (event.code === "Enter" && complete) {
                onSubmit(event)
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [email,password, complete, onSubmit])


    return (
        <div className="bg-blue-50 min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <Link to="/">
                  <img className="h-9 w-auto" src={process.env.PUBLIC_URL + '/images/StreamCreateLogo.svg'} alt="" />
                </Link>
                <div className="bg-white px-6 py-8 pt-5 mt-6 rounded shadow-md text-black w-full">
                    <h1 className="mb-6 text-2xl text-center font-medium text-gray-600">Log in</h1>
                    <InputWithLabel
                        id="email"
                        label=""
                        placeholder="Email" 
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        maxLength={80}
                    />
                    <InputWithLabel
                        id="password"
                        label=""
                        placeholder="Password" 
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                    />

                    <div className="text-right">
                        <Link className="text-sm text-gray-500"  to="../ResetPassword/"> Forgot your password? </Link>
                    </div>

                                        
                    <RenderIf condition={showLoginError}>
                        <div className="mb-4 mt-2 text-sm text-red-600 text-center">{loginError}</div>
                    </RenderIf>


                    <div className="text-center mt-4">
                        <StandardButton
                            disabled={!complete}                            
                            onClick={e => onSubmit(e)}
                            text={"Login"}
                            isSpinning={loading}
                        />
                    </div>
                </div>

                <div className="text-gray-500 mt-4">
                    Don't have an account?<span> </span>
                    <Link className="border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  to="../Signup/"> Sign up.</Link>
                </div>
            </div>
            <Footer />
        </div>
    )
  })
  
  export default Login