import {observable, action, makeObservable} from "mobx"
import { CheckoutDetails } from "Types/CheckoutTypes"

export class CheckoutStore {
    checkoutDetails : CheckoutDetails = { fullName: "", email: "", agreeTerms: false, totalCost : 0, cardDetails : {cardName : "", cardNumber : undefined, expirationDate : "", cvc: undefined}, streamEndDateError: false, validReferralCode: ""}
    highlightErrors: boolean = false

    constructor() {
        makeObservable(this, {
            checkoutDetails: observable,
            setCheckoutDetails: action,
            setHighlightErrors: action,
            resetCheckoutDetails: action
        })
    }

    setCheckoutDetails(checkoutDetails : CheckoutDetails) {
        this.checkoutDetails = checkoutDetails
    }

    setHighlightErrors() {
        this.highlightErrors = true   
    }

    resetCheckoutDetails() { 
        let newCheckoutDetails = this.checkoutDetails
        newCheckoutDetails.fullName = ""
        newCheckoutDetails.email = ""
        newCheckoutDetails.agreeTerms = false
        newCheckoutDetails.totalCost = 0
        newCheckoutDetails.cardDetails = {cardName : "", cardNumber : undefined, expirationDate : "", cvc: undefined}
        newCheckoutDetails.streamEndDateError = false
        this.checkoutDetails = newCheckoutDetails
    }   


}
