import { CognitoUser } from "amazon-cognito-identity-js"
import {observable, action, makeObservable} from "mobx"
import { getSession, removeTokenCookie } from "Services/AuthService"
import { User } from "Types/UserTypes"

export class UserStore {
    isLoaded: boolean = false
    user : User
    cognitoUser: CognitoUser
    signUpEmail: string

    constructor() {
        makeObservable(this, {
            isLoaded: observable,
            user: observable,
            cognitoUser: observable,
            signUpEmail: observable,
            setUser: action,
            loadUser: action,
            setSignupEmail: action
        })
    }


    async loadUser() {
        getSession()
    }


    setIsLoaded() {
        this.isLoaded = true
    }
    

    clearUser() {
        this.setUser(null,null)
        removeTokenCookie()
    }
    

    setUser(cognitoUser : CognitoUser, userAttributes){
        if (cognitoUser) {
            const emailAttr = userAttributes.find(ua => ua.Name === 'email')
            const firstNameAttr = userAttributes.find(ua => ua.Name === 'given_name')
            const lastNameAttr = userAttributes.find(ua => ua.Name === 'family_name')
            this.user = {
                congitoUser: cognitoUser,
                username: cognitoUser.getUsername(),
                email : emailAttr ? emailAttr.Value : "",
                firstName : firstNameAttr ? firstNameAttr.Value : "",
                lastName : lastNameAttr ? lastNameAttr.Value : "",
            }
            this.cognitoUser = cognitoUser
        } else {
            this.user = null
            this.cognitoUser = null
        }
    }


    setSignupEmail(email : string) {
        this.signUpEmail = email
    }

}
