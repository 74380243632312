import axios from 'axios'
import { VoteOption } from 'Types/StreamTypes';
import { STATUS_UNAUTHORIZED } from 'Utilities/GeneralConstants';
import { API_BASE_URL } from './ApiConfig'
import { getAuthHeaderWithContentJson, getSession, handleUnauthorizedRequest } from './AuthService';

export async function uploadVoteAvatars(streamConfigId : string, voteOptions : Array<VoteOption>, isUserUploaded: boolean) {
    var bodyFormData = new FormData();
    let newUpdates : boolean = false
    bodyFormData.append('streamConfigId', streamConfigId);
    voteOptions.forEach(vo => {
        if (vo.imagesUpdated) {
            newUpdates = true
            bodyFormData.append('voteOptionIds', vo.id);
            bodyFormData.append('files', vo.avatarFile);
        }
    })
    if (newUpdates) {
        if (isUserUploaded) {
            return uploadUserVoteAvatars(bodyFormData)
        } else {
            return uploadPurchaseVoteAvatars(bodyFormData)
        }
    }
    return true
}

export async function uploadUserVoteAvatars(bodyFormData) {
    getSession()
    try {
        const response = await axios.put(API_BASE_URL + "/streams/voteConfigs/upload/user/avatars" , bodyFormData,{ headers : getAuthHeaderWithContentJson() })
        if (response.status === 200) {
            return true
        }
    } catch (error) {
        if (error.response.status === STATUS_UNAUTHORIZED) {
            await handleUnauthorizedRequest()
        }
        throw error
    }
}

export async function uploadPurchaseVoteAvatars(bodyFormData) {
    try {
        const response = await axios.put(API_BASE_URL + "/streams/voteConfigs/upload/avatars" , bodyFormData,{ headers : { "Content-Type": "multipart/form-data" }})
        if (response.status === 200) {
            return true
        }
    } catch (error) {
        return false
    }
    return false
}