import moment from "moment";
import { ProductType } from "Types/StreamTypes";
import { COUNTDOWN_PRODUCT_NAME, MULTIVOTE_PRODUCT_NAME, VOTE_TABLE_SIZES } from "./GeneralConstants";

export function truncateText(text, length) {
    if (text.length <= length) {
        return text;
    }
    return text.substr(0, length) + '\u2026'
}

export function getDaysDifferenceFromToday(date1) {
    return Math.ceil(moment(date1).diff(moment(moment.utc().format('YYYY-MM-DDTHH:mm:ss')),'hours')/24)
}

export const getFutureDateTime = (days) => {
    let futureDateTime = moment.utc().add(days,'days').format('YYYY-MM-DDTHH:mm:ss')
    return futureDateTime
}

export function numBetweenVals(num,smaller,larger) {
    return num >= smaller && num <= larger
}

export function currentYearAsYY(){ return parseInt(new Date().getFullYear().toString().substr(-2))}

export const hashText = (text : string) => {
    return btoa(text)
}

export const deHashText = (ecodedText : string) => {
    return atob(ecodedText)
}

export const sleepMilliseconds = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const subList = (list, start,end) => {
    if (!end) { end = -1; } 
    return list.slice(start, list.length + 1 - (end * -1));
}

export const reformatFileName = (fileName) => {
    const fileNameExtension = fileName.split('.').pop()
    let formattedFileName = fileName.replace('.' + fileNameExtension,'')
    return formattedFileName.replace(/[^a-zA-Z0-9-_]/g, '') + '.' + fileNameExtension;
}

export const isStreamKeyValid = input => /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/.test(input)

export const isFirstNameValid = input => input.length >= 2 && input.length <= 40

export const isChannelUrlValid = input => typeof input === 'string' && input.length > 0

export const isVideoIdValid = input => /^[A-Za-z0-9_-]{11}$/.test(input)

export const isEmailValid = input => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)

export const isPasswordValid = input=> input.length >= 8 && hasNumber(input) && isUpperCase(input)  && isLowerCase(input)

export const hasNumber = input => /[0-9]/.test(input)

export const isNumber = input => /^\d+$/.test(input)

export const isUpperCase = input => /[A-Z]/.test(input)

export const isLowerCase = input => /[a-z]/.test(input)

export const formatDate = dateTime => moment(dateTime).format("DD MMM YYYY")

export const formatDateTime = dateTime => moment(dateTime).format("DD MMM YYYY HH:mm")

export const formatTime = dateTime => moment(dateTime).format('LT')

export function compareRanking( a, b ) {
    const aValue = Number(a['VoteCount'])
    const bValue = Number(b['VoteCount'])
  
    if ( aValue < bValue ){
      return 1;
    }
    if ( aValue > bValue ){
      return -1;
    }
    return 0;
  }

  
export function getRowHeight( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["rowHeight"] : 0;
}

export function getRowFontSize( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["rowFontSize"] : 0;
}

export function getRowMarginTop( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["rowMarginTop"] : 0;
}

export function getNumberMarginRight( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["numberMarginRight"] : 0;
}

export function getNumberMarginLeftNoImage( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["numberMarginLeftNoImage"] : 0;
}

export function getNumberMarginLeftImage( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["numberMarginLeftImage"] : 0;
}

export function getLastFiveVoterPadding( voteOptions ) {
    return ( voteOptions in VOTE_TABLE_SIZES ) ? VOTE_TABLE_SIZES[voteOptions]["lastFiveVoterPadding"] : 0;
}


export function calculatePricingFromDays(productType : ProductType, days : number) {
    let preciseCost;
    if (days <= 0) {
        return 0.00
    }
    if (productType === ProductType.COUNTDOWN) {
        preciseCost = days + Math.min(0.25*days,6.25) + Math.min(0.25*days,2.5) + Math.min(0.5*days,2) + Math.min(days,2) + Math.min(2*days,2);
    } else if (productType === ProductType.MULTI_VOTE) {
        preciseCost = 1.5*days + Math.min(days,10) + Math.min(days,4) + Math.min(1.5*days,3) + Math.min(3*days,3) 
    }
    return Math.round((preciseCost*0.8)*2)/2.0;
}

export function getOptionProductType(templatePath) {
    if (templatePath === "VotingPoll") { 
      return ProductType.MULTI_VOTE
    }
    return ProductType.COUNTDOWN
}
  

export function getTemplateOption(productType) {
    if (productType === ProductType.COUNTDOWN) { 
        return "1"
    } else if (productType === ProductType.MULTI_VOTE) { 
        return "2"
    }
}
  
export function getProductName(productType) {
    if (productType === ProductType.COUNTDOWN) { 
        return COUNTDOWN_PRODUCT_NAME
    } else if (productType === ProductType.MULTI_VOTE) { 
        return MULTIVOTE_PRODUCT_NAME
    }
}