
import React from "react"
import HeadingThreeBold from "Components/Typography/HeadingThreeBold"

export const LegalSection = ({heading,sections}) => {
  return (
    <React.Fragment>
        <HeadingThreeBold> <li> {heading} </li></HeadingThreeBold> 
        <ul className="list-alpha ml-5 md:ml-10 mt ">
            {sections.map((section) => (
                <li className="pl-4 text-lg	text-gray-700 mt-2">
                    {section.content}
                    <ul className="list-roman ml-8">
                        {section.subsections.map((subsection) => (
                            <li className="pl-3 md:pl-6">
                                {subsection}
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>

    </React.Fragment>
  )
}