import { getCountdownFontStyle, getCountdownNumStyle } from "../../../Utilities/TextFunctions"

const TextLabelCountdown = ({fontColor, fontName, countdownExtraLarge, countdownDropShadow, windowWidth, days, hours, minutes, seconds}) => {

    const getGap = () => {
        if (windowWidth < 300) {
            return "gap-1"
        } else if (windowWidth < 450) {
            return "gap-2"
        } else if(windowWidth < 640) {
            return "gap-2"
        } else if(windowWidth < 768) {
            return "gap-4"
        } else if(windowWidth < 1024) {
            return "gap-4"
        } else if(windowWidth < 1280) {
            return "gap-7"
        } else {
            return "gap-8"
        }
    }

    let countdownFontSize = (Math.round((windowWidth/250) * 4) / 4).toFixed(2)
    
    return (
        <div className={`grid grid-flow-col ${getGap()} text-center auto-cols-max`} >
            <TextLabel value={days} valueName={"days"} fontColor={fontColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
            <TextLabel value={hours} valueName={"hours"} fontColor={fontColor}  fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow} />
            <TextLabel value={minutes} valueName={"min"} fontColor={fontColor}  fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
            <TextLabel value={seconds} valueName={"sec"} fontColor={fontColor}  fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
        </div>
    )
}

const TextLabel = ({value, valueName, fontColor, fontName, countdownFontSize, countdownExtraLarge, countdownDropShadow}) => {
    return (
        <div style={{fontFamily : fontName }} className="flex flex-col">
            <span style={getCountdownNumStyle(countdownExtraLarge, countdownDropShadow, countdownFontSize)} className={`countdown`}>
                    {/*@ts-ignore */}
                    <span style={{"--value" : value, color: fontColor }}></span>
            </span>
            <div style={getCountdownFontStyle(countdownExtraLarge, countdownDropShadow, fontColor, 0.45)} > {valueName} </div>
      </div>
    )
}

export default TextLabelCountdown