import React from "react"
import RenderIf from "./RenderIf"
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'

export const SuccessOrErrorMessage = ({showComponent, showSuccess, successMessage, errorMessage}) => {
    return (
        <React.Fragment>
        <RenderIf condition={showComponent && showSuccess}>
            <div className="mt-2 flex items-start lg:col-span-1 justify-end">
                <div className="flex-shrink-0 ml-4">
                    <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <p className="ml-2 text-green-600"> {successMessage}</p>
            </div>
        </RenderIf>

        <RenderIf condition={showComponent && !showSuccess}>
            <div className="mt-2 flex items-start lg:col-span-1 justify-end">
                <div className="flex-shrink-0 ml-4">
                    <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <p className="ml-2 text-red-400"> {errorMessage} </p>
            </div>
        </RenderIf>
        </React.Fragment>
    )
}

