import RenderIf from "Components/Structure/RenderIf"

const BackNext = ({
  showBack = true,
  showNext = true,
  disableBack = false,
  disableNext = false,
  onNext = () => {},
  onBack = () => {},
}) => {
  return (
    <div className="mt-4 sm:mt-8 flex justify-end">
      <RenderIf condition={showBack}>
        <button
          type="button"
          className={`inline-flex items-center mr-4 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 focus:outline-none focus:ring-offset-2 focus:ring-blue-300 bg-blue-200 hover:bg-blue-300`}
          disabled={disableBack}
          onClick={() => onBack()}>
          Back
        </button>
      </RenderIf>
      <RenderIf condition={showNext}>
        <button
          type="button"
          className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-offset-2 focus:ring-blue-700 ${disableNext ? `bg-gray-400` : `bg-blue-700 hover:bg-blue-800`}`}
          disabled={disableNext}
          onClick={() => onNext()}>
          Next
        </button>
      </RenderIf>
    </div>
  )
}

export default BackNext