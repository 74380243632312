import { Payment } from "./CheckoutTypes";

export enum ProductType {
    TWO_VOTE = 'TWOVOTE',
    MULTI_VOTE = 'MULTIVOTE',
    COUNTDOWN = 'COUNTDOWN'
}

export enum BackgroundType {
    BACKGROUND_COLOR = 'COLOR',
    DEFAULT_BACKGROUND = 'DEFAULT',
    CUSTOM_BACKGROUND = 'CUSTOM'
}

export enum CountdownType {
    BASIC = "BASIC",
    BASICSIDE = "BASICSIDE",
    BOXED = "BOXED"
}

export enum StreamStatus {
    PENDING = "PENDING",
    DEPLOYING = "DEPLOYING",
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED"
}

export interface StreamConfigResponse {
    id : string,
    productType : ProductType,
    videoId : string,
    streamKey: string,
    customerId: string,
    paymentEmail: string,
    streamEnd: string,
    productConfig : ProductConfig,
    streamStatus : StreamStatus,
    createdAt : string,
    updatedAt : string,
    publishedAt : string,
    payment: Payment,
    redeploymentCount?: number
}

export interface StreamConfig {
    id : string,
    productType : ProductType,
    videoId : string,
    streamKey : string,
    customerId : string,
    paymentEmail : string,
    streamEnd : string,
    productConfig : ProductConfig,
    redeploymentCount?: number
}

export interface ProductConfig {
    title : string,
    titleColor : string,
    titleFont: string,
    titleExtraLarge : boolean,
    titleDropShadow: boolean,
    subtitle : string,
    backgroundType : BackgroundType,
    backgroundColor : string,
    defaultBackgroundUrl : string,
    customBackgroundUrl : string,
    customAudioUrl : string,
    defaultAudio : string,
    audioEnabled: boolean,
    countdownDate : string,
    countdownFontColor : string,
    countdownBoxColor: string,
    countdownFont : string,
    countdownType: CountdownType,
    countdownExtraLarge: boolean,
    countdownDropShadow : boolean,
    voteConfig? : VoteConfig
}

export interface VoteConfig {
    apiKey : string,
    includeImages: boolean,
    font: string,
    fontColor: string,
    dropShadow: boolean,
    voteOptions : Array<VoteOption>
}

export interface VoteOption {
    id: string,
    name : string,
    code : string,
    count : string,
    avatarUrl : string,
    avatarFile? : File
    imagesUpdated?: boolean
}
