import StandardButton from 'Components/Structure/StandardButton'
import React from 'react'
import { ExclamationIcon, XCircleIcon } from '@heroicons/react/solid'
import { useHistory } from "react-router-dom"


export const PublishStreamWarningBody = ({currentTimeUTC, setWarningShown, isError}) => {

    let hourDifference = 24 - currentTimeUTC.hour() 
    let minuteDifference = 60 - currentTimeUTC.minute()
    const history = useHistory()

    return (
        <React.Fragment>
            <div>

                <div className="p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            {isError ?            
                                <XCircleIcon className="h-9 w-9 text-red-400" aria-hidden="true" />
                                :
                                <ExclamationIcon className="h-9 w-9 text-yellow-400" aria-hidden="true" />
                            }
                        </div>
                        <div className="ml-3">
                        <h3 className="text-lg font-medium text-gray-800">
                            { isError ? "Daily publishing limit reached" : "One more publish left for today!"}
                        </h3>
                        <div className="mt-2 text-sm text-gray-600">

                            { 
                                isError ?
                                <div>
                                    <p>
                                        You have reached the 24 hour publishing limit. 
                                    </p>
                                    <p>
                                        If you need to urgently publish your stream please contact support.
                                    </p>
                                    <p>
                                        This limit will reset in <b>{hourDifference} hours and {minuteDifference} minutes</b>
                                    </p>
                                </div>
                            
                            : 
                                <div>
                                    <p>
                                        You can only publish once more until you reach the 24 hour publishing limit.
                                    </p>
                                    <p>
                                        This limit will reset in <b>{hourDifference} hours and {minuteDifference} minutes</b>
                                    </p>
                                </div>
                            }

                        </div>
                        </div>
                    </div>
                </div>


                <div className="mr-4 flex justify-end">
                { 
                    isError ?
                        <StandardButton
                        disabled={false}
                        onClick={e => history.push("/Contact") }
                        text={"Contact us"} 
                        isSpinning={false}                    
                      />
                    :
                    <StandardButton
                        disabled={false}
                        onClick={e => {setWarningShown(true)} }
                        text={"Continue"} 
                        isSpinning={false}                    
                    />
                }
                    
                </div>

            </div>
                
        </React.Fragment>
    )
}
