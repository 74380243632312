import { observer } from "mobx-react"
import { truncateText } from "Utilities/HelperFunctions"

function formatFileName(fileName){
   let splitFileName = fileName.split(".")
   return truncateText(splitFileName[0],10)
}

const ImageGalleryItem = observer(({fileName, fileSize, imageSource, selected, imageClicked, deleteClicked}) => {
   
    return (
        <li className="block p-1 w-1/3  h-24" >
        { imageSource ? 
         <article onClick={() => imageClicked()} className="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative shadow-sm">
         <img alt="uploaded-background" className={` ${selected ? 'border-2 border-blue-500' : ''} img-preview w-full h-full sticky object-cover rounded-md bg-fixed`} src={imageSource}></img>
        
         <section className=" text-transparent  hover:text-white flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
            <h1 className="flex-1">{formatFileName(fileName)}</h1>
            <div className="flex">
            <span className="p-1">
                <i>
                <svg className="fill-current w-4 h-4 ml-auto pt-" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"></path>
                </svg>
                </i>
            </span>

            <p className=" p-1 size text-xs">{fileSize + 'kb'}</p>
            <button onClick={(e) => deleteClicked(e)} className="text-white delete ml-auto focus:outline-none  p-1 rounded-md" >
                <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"></path>
                </svg>
            </button>
            </div>
         </section>
         </article>
         : <div></div> }
      </li>
    )
})

export default ImageGalleryItem










