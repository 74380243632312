import React from "react"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import HeadingThreeBold from "Components/Typography/HeadingThreeBold"



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const FaqSection = ({title, faqs}) => {
    return (
        <React.Fragment>
          <div className="mt-6"/>
          <HeadingThreeBold> {title} </HeadingThreeBold>

          <div className="ml-5 mt-5 mb-10">
            <div className="border-t"/>
            <dl className="space-y-3 divide-y divide-gray-200">
              {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-4">
                      {({ open }) => (
                      <>
                          <dt className="text-md">
                              <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                  <span className="font-medium text-gray-600">{faq.question}</span>
                                  <span className="ml-6 h-7 flex items-center">
                                      <ChevronDownIcon
                                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                          aria-hidden="true"
                                      />
                                  </span>
                              </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base text-gray-500">{faq.answer}</p>
                          </Disclosure.Panel>
                      </>
                      )}
                  </Disclosure>
              ))}
            </dl>
            <div className="border-b mt-3"/>
          </div>
        </React.Fragment>

    )
    
}
  
export default FaqSection
  