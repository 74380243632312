import { Dialog } from '@headlessui/react'
import { AuthenticationDetails, CognitoUser, } from 'amazon-cognito-identity-js'
import InputWithLabel from 'Components/Structure/InputWithLabel'
import RenderIf from 'Components/Structure/RenderIf'
import React, { useState } from 'react'
import { createAuthDetails, createCognitoUser, loadUser } from 'Services/AuthService'
import { useStores } from 'Store/StoreConfig'
import { EMAIL_NOT_VERIFIED, GENERIC_ERROR_MSG, INCORRECT_LOGIN_MSG} from 'Utilities/GeneralConstants'
import StandardButton from 'Components/Structure/StandardButton'
import { AuthModalTypes } from 'Types/GeneralTypes'
import { linkCustomerToStreamConfig } from 'Services/StreamConfigService'
import { isEmailValid } from 'Utilities/HelperFunctions'


export const LoginModalBody = ({ setCurrentModal, setOpenAuthModal, subHeadingText}) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")
    const [showLoginError, setShowLoginError] = useState(false)
    const [loading, setLoading] = useState(false)
    const complete = isEmailValid(email) && password.length > 0
    const { UserStore, StreamBuilderStore } = useStores()

    const onSubmit = (event) => {
        event.preventDefault()
        setShowLoginError(false)
        if(complete){
            const authDetails : AuthenticationDetails = createAuthDetails(email,password)
            const user : CognitoUser = createCognitoUser(email)
            setLoading(true)
            user.authenticateUser(authDetails,{
                onSuccess: async (data) => {
                    if (await loadUser(user, UserStore, data.getAccessToken()['jwtToken'])) {
                        const streamConfigId = StreamBuilderStore.streamConfig.id
                        await linkCustomerToStreamConfig(streamConfigId)
                        setOpenAuthModal(false)
                    } else {
                        setLoginError(GENERIC_ERROR_MSG)
                        setShowLoginError(true)
                    }
                    setLoading(false)
                },
                onFailure: (err) => {
                    if(err["code"] === "NotAuthorizedException"){
                        setLoginError(INCORRECT_LOGIN_MSG)
                    } else if (err["code"] === "UserNotConfirmedException") {
                        setLoginError(EMAIL_NOT_VERIFIED)
                    }else{
                        setLoginError(GENERIC_ERROR_MSG)
                    }
                    setShowLoginError(true)
                    setLoading(false)
                }
            })
        }
    }


  return (
    <React.Fragment>
                <div className="text-center mb-4">
                  <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                    Log in
                  </Dialog.Title>
                  <div className={`${subHeadingText.length > 0 ? "mt-4" : "mt-6"}`}>
                    <p className="text-sm text-gray-500">
                      {subHeadingText}
                    </p>
                  </div>
                </div>
             
                <InputWithLabel
                        id="email"
                        label=""
                        placeholder="Email" 
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        maxLength={80}
                    />

                    <InputWithLabel
                        id="password"
                        label=""
                        placeholder="Password" 
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                    />

                    <div className="text-right">
                        <button className="text-sm text-gray-500" onClick={() => {setCurrentModal(AuthModalTypes.RESETPASSWORD)}}> Forgot your password? </button>
                    </div>

                                        
                    <RenderIf condition={showLoginError}>
                        <div className="mb-4 mt-2 text-sm text-red-600 text-center">{loginError}</div>
                    </RenderIf>


                    <div className="text-center mt-4">
                        <StandardButton
                            disabled={!complete}                            
                            onClick={e => onSubmit(e)}
                            text={"Log in"}
                            isSpinning={loading}
                        />
                    </div>
                    <div className="text-gray-500 mt-4 text-center">
                         Don't have an account?<span> </span>
                        <button className="border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  onClick={() => {setCurrentModal(AuthModalTypes.CREATE)} }> Create one.</button>
                    </div>
    </React.Fragment>
  )
}