export const TitleTextBox = ({title, text}) => {
    return (
      <div  className="flex flex-col bg-white rounded-md shadow-md bg-white pt-4 px-6">
        <div className="flex-1 relative pb-6 ">
          <h3 className="text-xl font-medium text-gray-900">
              {title}
          </h3>
          <p className="mt-4 text-base text-gray-600">
             {text}
          </p>
        </div>
      </div>
    )
  }