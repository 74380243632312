import { useState } from "react"
import { useStores } from "Store/StoreConfig"
import moment from 'moment'
import OptionSelector from "./Utilities/OptionSelector"
import MyColorPicker from "./Utilities/MyColorPicker"
import SelectMenu from "./Utilities/SelectMenu"
import { BLACK, DROPDOWN_FONTS, INVALID_DATE_ENTERED, INVALID_STREAM_DURATION, MAX_STREAM_DAYS, WHITE, WORLD_TIME_SERVER_URL } from "Utilities/GeneralConstants"
import { observer } from "mobx-react"
import { BackgroundType, CountdownType } from "Types/StreamTypes"
import { ArrowRightIcon } from "@heroicons/react/solid"
import MyDateTimePicker from "../Checkout/MyDateTimePicker"
import { getDaysDifferenceFromToday, getFutureDateTime } from "Utilities/HelperFunctions"
import RenderIf from "Components/Structure/RenderIf"
import MyCheckBox from "./Utilities/MyCheckBox"

const CountdownTimerConfig = observer(({isBuilderConfig}) => {
    const { StreamBuilderStore } = useStores()
    const [pc, setpc] = useState(StreamBuilderStore.getProductConfig(isBuilderConfig))
    const [countdownDateError, setCountdownDateError] = useState(null)
    
    const updateCountdown = (newCountdown, newcountdownFontColor, newcountdownBoxColor, newCountDownFont, newCountdownType, newCountdownExtraLarge, newCountdownDropShadow) => {
      let newStreamConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig)
      const newDaysDifference = getDaysDifferenceFromToday(newCountdown)
      if (!isNaN(newDaysDifference) && newDaysDifference > 0 && newDaysDifference <= MAX_STREAM_DAYS) {
        newStreamConfig.productConfig.countdownDate = newCountdown
        setCountdownDateError(null)
      } else {
        if (newDaysDifference <= 0 || newDaysDifference > MAX_STREAM_DAYS) {
          setCountdownDateError(INVALID_STREAM_DURATION)
        } else {
          setCountdownDateError(INVALID_DATE_ENTERED)
        }
      }
      newStreamConfig.productConfig.countdownFontColor = newcountdownFontColor
      newStreamConfig.productConfig.countdownBoxColor = newcountdownBoxColor
      newStreamConfig.productConfig.countdownFont = newCountDownFont
      newStreamConfig.productConfig.countdownType = newCountdownType
      newStreamConfig.productConfig.countdownExtraLarge = newCountdownExtraLarge
      newStreamConfig.productConfig.countdownDropShadow = newCountdownDropShadow
      StreamBuilderStore.setStreamConfig(newStreamConfig,isBuilderConfig)
      setpc(newStreamConfig.productConfig)
  }


  const setDateInputError = (error) => {
    if (error) {
      setCountdownDateError(INVALID_DATE_ENTERED)
    }
  }

  return (
      <div className="p-4 pt-2 text-gray-800">
        
        <label className="text-sm text-base font-medium text-gray-700" htmlFor="countdown-datetime">Countdown to</label>
        <div className="my-1 rounded-md  xl:w-8/12">
            <MyDateTimePicker inputDate={pc.countdownDate} setInputDate={(newDate) => updateCountdown(newDate.format('YYYY-MM-DDTHH:mm:ss'), pc.countdownFontColor, pc.countdownBoxColor, pc.countdownFont, pc.countdownType, pc.countdownExtraLarge, pc.countdownDropShadow)} onError={setDateInputError} minDateTime={moment.utc()} maxDateTime={getFutureDateTime(MAX_STREAM_DAYS)} isStandardInput={true} showErrorOutLine={false}/>
        </div>
        <RenderIf condition={countdownDateError} >
          <p className="mb-2 text-sm text-red-600"> {countdownDateError} </p>
        </RenderIf>
        <p className="text-sm text-gray-500 pb-2">Our time is set up in 
          <a className="inline-flex items-center font-medium border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700 ml-1" href={WORLD_TIME_SERVER_URL} target="_blank" rel="noreferrer">
            UTC <ArrowRightIcon className="ml-0.5  h-4 w-4 fk" aria-hidden="true" />
          </a>
        </p>

        <div className="flex flex-col xl:flex-row">
          <div className="flex-1 inline-block lg:w-full sm:w-1/2 ">
            <label className="text-sm text-base font-medium text-gray-700 " >Font</label>
            <SelectMenu  items={DROPDOWN_FONTS} itemName={pc.countdownFont} updateCallback={font => updateCountdown(pc.countdownDate, pc.countdownFontColor, pc.countdownBoxColor, font, pc.countdownType, pc.countdownExtraLarge, pc.countdownDropShadow)} />
          </div>
          <div className=" inline-block  sm:w-1/2 lg:w-full xl:w-4/12 xl:pl-2 pt-2 xl:pt-0">
              <label className=" text-sm text-base font-medium text-gray-700 " >Color</label>
              <div className="mt-0.5 -mr-0.5">
                <MyColorPicker selectedColor={pc.countdownFontColor} updateCallback={color => updateCountdown(pc.countdownDate, color, pc.countdownBoxColor,  pc.countdownFont, pc.countdownType, pc.countdownExtraLarge, pc.countdownDropShadow)} width="100%" height="45px"/>
              </div>
          </div>
        </div>

        <label className="block text-sm text-base font-medium text-gray-700 mb-1 pt-2" htmlFor="countdown-datetime">Countdown Style</label>


        <div className="flex flex-col lg:flex-row mb-2">
          <div className="w-8/12 ">
          <OptionSelector text={"Basic"} active={pc.countdownType === CountdownType.BASIC} 
          onClick={() => updateCountdown(pc.countdownDate, pc.countdownFontColor === WHITE && pc.backgroundColor === WHITE && pc.backgroundType === BackgroundType.BACKGROUND_COLOR  ? BLACK : pc.countdownFontColor, pc.countdownBoxColor, pc.countdownFont, CountdownType.BASIC, pc.countdownExtraLarge, pc.countdownDropShadow)} />
          <div className="mt-2"></div>
          <OptionSelector text={"BasicSide"} active={pc.countdownType ===  CountdownType.BASICSIDE} 
          onClick={() => updateCountdown(pc.countdownDate, pc.countdownFontColor === WHITE && pc.backgroundColor === WHITE && pc.backgroundType === BackgroundType.BACKGROUND_COLOR  ? BLACK : pc.countdownFontColor, pc.countdownBoxColor,  pc.countdownFont,CountdownType.BASICSIDE, pc.countdownExtraLarge, pc.countdownDropShadow)} />
          <div className="mt-2"></div>
          <OptionSelector text={"Boxed countdown"} active={pc.countdownType === CountdownType.BOXED} 
          onClick={() => updateCountdown(pc.countdownDate, pc.countdownFontColor === BLACK && pc.countdownBoxColor === BLACK  ? WHITE : pc.countdownFontColor, pc.countdownBoxColor, pc.countdownFont, CountdownType.BOXED, pc.countdownExtraLarge, pc.countdownDropShadow)} />
          </div>
          <div className=" inline-block  sm:w-1/2  lg:w-4/12  pt-2 lg:pt-0 lg:ml-2 ml-0 lg:mt-4">
            <label className=" text-sm text-base font-medium text-gray-700 " > Box color</label>
              <div className="mt-1 -mr-0.5">
                <MyColorPicker selectedColor={pc.countdownBoxColor} updateCallback={color => updateCountdown(pc.countdownDate, pc.countdownFontColor, color, pc.countdownFont, pc.countdownType, pc.countdownExtraLarge, pc.countdownDropShadow)} width="100%" height="45px"/>
              </div>
          </div>
        </div>

        <label className="text-sm text-base font-medium text-gray-700 " >Advanced settings</label>

        <div className="xs:flex mt-2">
          <MyCheckBox text={"Extra large"} 
            checked={pc.countdownExtraLarge} 
            onChange={() => updateCountdown(pc.countdownDate, pc.countdownFontColor, pc.countdownBoxColor, pc.countdownFont, pc.countdownType, !pc.countdownExtraLarge, pc.countdownDropShadow)} 
          />
          <div className="ml-4 mt-2 xs:mt-0"></div>
          <MyCheckBox text={"Drop shadow"} 
            checked={pc.countdownDropShadow} 
            onChange={() => updateCountdown(pc.countdownDate, pc.countdownFontColor, pc.countdownBoxColor, pc.countdownFont, pc.countdownType, pc.countdownExtraLarge, !pc.countdownDropShadow)} 
          />
        </div>

      </div>
  )
})

export default CountdownTimerConfig
