import RenderIf from '../RenderIf'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'Store/StoreConfig'
import {Link} from 'react-router-dom'
import { userPool } from 'Services/AuthService'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { HeaderDropDown } from './HeaderDropDown'
import { ModalFrame } from 'Components/StreamBuilder/Modals/ModalFrame'
import { AuthModalTypes } from 'Types/GeneralTypes'
import { LoginModalBody } from 'Components/StreamBuilder/Modals/LoginModalBody'
import { VerifyEmailModalBody } from 'Components/StreamBuilder/Modals/VerifyEmailModalBody'
import { ResetPasswordModalBody } from 'Components/StreamBuilder/Modals/ResetPasswordModalBody'
import { CreateUserModalBody } from 'Components/StreamBuilder/Modals/CreateUserModalBody'

const Header = observer(({ showMenu = true, showAccount = true }) => {
  const { UserStore } = useStores()
  const [isOpen, setIsOpen] = useState(false)
  const [logOutLoading, setLogOutLoading] = useState(false)
  const [openAuthModal, setOpenAuthModal] = useState(false)
  const [currentModal, setCurrentModal] = useState(AuthModalTypes.LOGIN)
  const [currentPage, setCurrentPage] = useState(null)

  useEffect(() => {
    const currentPathSplit = window.location.pathname.split("/") 
    let currentPage = currentPathSplit[1]
    if (currentPathSplit[2]) {
      currentPage = currentPage + "/" + currentPathSplit[2]
    }
    setCurrentPage(currentPage)
  }, []);

  const logOut = () => {
    setLogOutLoading(true)
    const user : CognitoUser = userPool.getCurrentUser()
    if(user){
        user.signOut(() => {
          UserStore.clearUser()
          setLogOutLoading(false)
          document.location.href="/";
        })
    }else{
        UserStore.clearUser()
        setLogOutLoading(false)
        document.location.href="/";
    }
  }

  return (
    <div className="relative bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-8 ">
          <div className="flex justify-between items-center py-4 lg:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
                  <span className="sr-only">Workflow</span>
                  <Link to="/">
                    <img className="h-7 w-auto" src={process.env.PUBLIC_URL + '/images/StreamCreateLogo.svg'} alt="" />
                  </Link>
            </div>

            <RenderIf condition={showMenu}>
              <nav className="hidden lg:flex">
                <Link className={`text-base font-medium text-gray-500 hover:text-gray-800 py-1.5 px-2 xl:px-3 mx-1 ${currentPage === '' ? "rounded-md bg-gray-100 text-gray-800" : ''}`} to="/"> Home </Link>
                <Link className={`text-base font-medium text-gray-500 hover:text-gray-800 py-1.5 px-2 xl:px-3 mx-1 ${currentPage === 'Templates' ? "rounded-md bg-gray-100 text-gray-800" : ''}`} to="/Templates/Countdown"> Templates </Link>
                <Link className={`text-base font-medium text-gray-500 hover:text-gray-800 py-1.5 px-2 xl:px-3 mx-1 ${currentPage === 'StreamBuilder' ? "rounded-md bg-gray-100 text-gray-800" : ''}`} to="/StreamBuilder"> Stream Builder </Link>
                <Link className={`text-base font-medium text-gray-500 hover:text-gray-800 py-1.5 px-2 xl:px-3 mx-1 ${currentPage === 'Pricing' ? "rounded-md bg-gray-100 text-gray-800" : ''}`} to="/Pricing"> Pricing </Link>
                {/*<Link className={`text-base font-medium text-gray-500 hover:text-gray-800 py-1.5 px-2 xl:px-3 mx-1 ${currentPage === 'Guides' ? "rounded-md bg-gray-100 text-gray-800" : ''}`} to="/Guides"> Guides </Link>*/}
                <Link className={`text-base font-medium text-gray-500 hover:text-gray-800 py-1.5 px-2 xl:px-3 mx-1 ${currentPage === 'Support' ? "rounded-md bg-gray-100 text-gray-800" : ''}`} to="/Support"> Support </Link>
              </nav>
              <div className="lg:hidden">
                <button type="button" onClick={(event) => setIsOpen(!isOpen)}className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-inset">
                {!isOpen ? <svg className="h-7 w-7" fill="none" viewBox="0 0 22 22" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 5h20M2 12h20M2 19h20" />
                  </svg> :
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 22 22" overflow="visible" stroke="currentColor" strokeWidth="2" strokeLinecap="round">
                    <line x2="22" y2="22" />
                    <line x1="22" y2="22" />
                  </svg>
                  }
                </button>
              </div>
            </RenderIf>


            <RenderIf condition={showAccount}>
              {!UserStore.user ?
                <div className="hidden lg:flex items-center justify-end lg:flex-1 ">
                  <button className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"  onClick={() => {setCurrentModal(AuthModalTypes.LOGIN);setOpenAuthModal(true)}}> Log in </button>
                  <button className="ml-6 xl:ml-8 inline-flex justify-center px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white font-medium text-white bg-blue-700 hover:bg-blue-800"  onClick={() => {setCurrentModal(AuthModalTypes.CREATE);setOpenAuthModal(true)}}> Sign up</button>
                </div>
                : 
                <div className="hidden lg:flex items-center justify-end lg:flex-1 ">
                  <HeaderDropDown user={UserStore.user} logOut={() => logOut()} logOutLoading={logOutLoading} />
                </div>
              }
            </RenderIf>
          </div>
        </div>

        <RenderIf condition={showMenu && isOpen}>
          <div className="mx-2 lg:hidden grid grid-cols-1 divide-y divide-gray-300 border-t border-b border-gray-300">
              <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === '' ? "bg-gray-100 " : ''}`} to="/"> Home </Link>
              <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'Templates' ? "bg-gray-100 " : ''}`} to="/Templates/Countdown"> Templates </Link>
              <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'StreamBuilder' ? "bg-gray-100 " : ''}`} to="/StreamBuilder"> Stream Builder </Link>
              <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'Pricing' ? "bg-gray-100 " : ''}`} to="/Pricing"> Pricing </Link>
              {/*<Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'Guides' ? "bg-gray-100 " : ''}`} to="/Guides"> Guides </Link>*/}
              <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'Support' ? "bg-gray-100 " : ''}`} to="/Support"> Support </Link>

              <RenderIf condition={!UserStore.user}>
                <button className="block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 text-left" onClick={() =>{setCurrentModal(AuthModalTypes.LOGIN);setOpenAuthModal(true)}}> Log in </button>
                <button className="block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 text-left" onClick={() => {setCurrentModal(AuthModalTypes.CREATE);setOpenAuthModal(true)}}>  Sign up </button>
              </RenderIf>
              <RenderIf condition={UserStore.user }>
                <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'DashBoard/AccountSettings' ? "bg-gray-100 " : ''}`} to="/DashBoard/AccountSettings"> Account Settings </Link>
                <Link className={`block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 ${currentPage === 'DashBoard/ManageStreams' ? "bg-gray-100 " : ''}`} to="/DashBoard/ManageStreams"> Manage Streams </Link>
                <button className="block px-2 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 text-left" onClick={e => logOut()}> Log out </button>
              </RenderIf>
          </div>
        </RenderIf>

        <ModalFrame 
                open={openAuthModal} 
                setOpen={setOpenAuthModal} 
                modalWidth={"24rem"}
                isBasicCloseStyle={true}
                modalBody={ currentModal === AuthModalTypes.CREATE ? 
                            <CreateUserModalBody setCurrentModal={setCurrentModal} cardFullName={""} preSetEmail={""} /> 
                         
                            :  currentModal === AuthModalTypes.LOGIN ?  <LoginModalBody setCurrentModal={setCurrentModal} setOpenAuthModal={setOpenAuthModal} subHeadingText={""} /> 
                        
                            : currentModal === AuthModalTypes.VERIFY ? <VerifyEmailModalBody setCurrentModal={setCurrentModal} message={"Congratulations, your account is now fully active."}/> 
                            
                            : currentModal === AuthModalTypes.RESETPASSWORD ? <ResetPasswordModalBody setCurrentModal={setCurrentModal}/> :  null
                          } 
                easyClose={false} 
              />

      </div>
  )
})

export default Header
