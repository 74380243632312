import React from 'react'

export const GuideHeaderTwo = ({text}) => {
    return (
        <React.Fragment>
            <h2 className="text-2xl font-bold text-gray-600 mb-2">
                {text}
            </h2>
        </React.Fragment>
    )
}

