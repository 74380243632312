import Layout from "../Components/Structure/Foundation/Layout"
import Body from "Components/Structure/Foundation/Body"
import RenderIf from "Components/Structure/RenderIf"
import { useEffect, useState } from "react"
import { ProductType } from "Types/StreamTypes"
import { useHistory } from "react-router-dom"
import HeadingTwo from "Components/Typography/HeadingTwo"
import { ArrowRightIcon } from "@heroicons/react/solid"
import { getTemplateGallery } from "Services/StreamConfigService"
import PreviewWindow from "Components/StreamBuilder/PreviewWindow"
import PageSpinner from "Components/Structure/PageSpinner"
import { calculatePricingFromDays, getOptionProductType, getTemplateOption, sleepMilliseconds } from "Utilities/HelperFunctions"
import { PreviewStreamModalBody } from "Components/StreamBuilder/Modals/PreviewStreamModalBody"
import { ModalFrame } from "Components/StreamBuilder/Modals/ModalFrame"
import { COUNTDOWN_PRODUCT_NAME, MULTIVOTE_PRODUCT_NAME, PROMOTION_VIDEO_URL_EMBED_COUNTDOWN, PROMOTION_VIDEO_URL_EMBED_MULTIVOTE } from "Utilities/GeneralConstants"
import { VideoModalBody } from "Components/StreamBuilder/Modals/VIdeoModalBody"
import { Helmet } from "react-helmet"
import { TEMPLATES_COUNTDOWN_DESCRIPTION, TEMPLATES_COUNTDOWN_TITLE, TEMPLATES_POLL_DESCRIPTION, TEMPLATES_POLL_TITLE } from "Utilities/SEOConstants"


const getTemplateHeading = (productType : ProductType) => { 
    if (productType === ProductType.COUNTDOWN) {
      return COUNTDOWN_PRODUCT_NAME
    }
    else if (productType === ProductType.MULTI_VOTE) {
      return MULTIVOTE_PRODUCT_NAME
    }
}

const getVideoURL = (productType : ProductType) => { 
  if (productType === ProductType.COUNTDOWN) {
    return PROMOTION_VIDEO_URL_EMBED_COUNTDOWN
  }
  else if (productType === ProductType.MULTI_VOTE) {
    return PROMOTION_VIDEO_URL_EMBED_MULTIVOTE
  }
}

const getTemplateMetaTitle = (productType : ProductType) => { 
  if (productType === ProductType.COUNTDOWN) {
    return TEMPLATES_COUNTDOWN_TITLE
  }
  else if (productType === ProductType.MULTI_VOTE) {
    return TEMPLATES_POLL_TITLE
  }
}


const getTemplateMetaDescription = (productType : ProductType) => { 
  if (productType === ProductType.COUNTDOWN) {
    return TEMPLATES_COUNTDOWN_DESCRIPTION
  }
  else if (productType === ProductType.MULTI_VOTE) {
    return TEMPLATES_POLL_DESCRIPTION
  }
}

const getTemplateText = (productType : ProductType) => { 
  if (productType === ProductType.COUNTDOWN) {
    return `Live stream countdowns to events that are important 
            to you and your fans, this will help grow their 
            anticipation and increase your channel engagement.`
  }
  else if (productType === ProductType.MULTI_VOTE) {
    return `Versatile template that allows you to configure up to ten voting options.
            Your YouTube audience is able to vote in the live chat using a vote code. 
            Our system will collect the votes in real time, rank the options and display the latest votes and voters.`
  }
}

export const TemplateGallery = () => {
  const templatePath = window.location.pathname.split("/")[2]
  const [productType, setProductType] = useState(getOptionProductType(templatePath))
  const [templateGallery, setTemplateGallery] = useState({"countdownTemplates": [], "votingPollTemplates" : []})
  const [galleryLoading, setGalleryLoading] = useState(true)
  const [previewProductConfig, setPreviewProductConfig] = useState(null)
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const history = useHistory()


  useEffect(() => {
    const templatePath = window.location.pathname.split("/")[2]
    setProductType(getOptionProductType(templatePath))
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
    async function fetchTemplateGallery() {
        setGalleryLoading(true)
        try {
            let templateGallery = await getTemplateGallery()
            setTemplateGallery(templateGallery)
        } catch (e) {
            console.error(e);
        }
        setGalleryLoading(false)
    };
    fetchTemplateGallery()
  }, []);

  const loadBetweenTemplates = async (newProductType) => {
    if (newProductType === ProductType.COUNTDOWN) {
      history.push("/Templates/Countdown")
    } else if (newProductType === ProductType.MULTI_VOTE) {
      history.push("/Templates/VotingPoll")
    }
    setProductType(newProductType)
    setGalleryLoading(true)
    await sleepMilliseconds(100);
    setGalleryLoading(false)
  }

  const previewTemplate = (productConfig) => {
    setPreviewProductConfig(productConfig)
    setOpenTemplatePreview(true)
  }

  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
        <Helmet>
          <title>{getTemplateMetaTitle(productType)}</title>
          <meta name="description" content={getTemplateMetaDescription(productType)} />
        </Helmet> 
        <div className={`bg-blue-800 pt-10 lg:pt-16 pb-48 -mb-40 lg:-mb-40 text-center px-8 `}> 
            <h1 className="tracking-tight font-bold text-white text-4xl md:text-5xl lg:text-5xl xl:text-6xl text-center ">
                StreamCreate templates
            </h1>
            <h1 className="tracking-tight font-bold text-blue-300 text-4xl md:text-5xl lg:text-5xl xl:text-6xl text-center ">
                Simple. User friendly.
            </h1>
            <p className="tracking-tight text-lg sm:text-xl text-white font-normal mt-6 md:mt-10 -mb-1 md:mb-0">
               Design your stream for free using our Stream Builder and purchase to go live. 
            </p>
        </div>

        <div className="text-center -mb-3 sm:-mb-5 md:mt-2">
            <button
              type="button"
              className={`relative rounded-tl-md py-2 font-medium whitespace-nowrap focus:outline-none focus:z-10 w-5/12 sm:w-auto sm:px-8 ${productType === ProductType.COUNTDOWN ? "text-gray-700 bg-white" : "bg-blue-600  text-white"}`}
              onClick={() => {loadBetweenTemplates(ProductType.COUNTDOWN)}}
            >
              Countdown Stream
            </button>
            <button
              type="button"
              className={`relative rounded-tr-md py-2 font-medium whitespace-nowrap focus:outline-none  focus:z-10 w-5/12 sm:w-auto sm:px-8 ${productType === ProductType.MULTI_VOTE ?  "text-gray-700 bg-white" : "bg-blue-600  text-white" }`}
              onClick={() => {loadBetweenTemplates(ProductType.MULTI_VOTE)}}
            >
              Voting Poll Stream
            </button>
        </div>
        
        <Body> 
          <div className="text-center md:text-left max-w-7xl mx-auto py-2 sm:px-6 lg:px-16 pb-8 md:pt-6">
            <HeadingTwo>{getTemplateHeading(productType)}</HeadingTwo>
            <div className="flex flex-col lg:flex-row my-2"  >
              <div className="lg:w-5/12  xl:w-6/12">
                <p className="px-2 xs:px-4 md:px-0 mx-auto text-lg sm:text-xl text-gray-800">
                  {getTemplateText(productType)} 
                </p>
                <button 
                  onClick={() => {history.push("/Pricing?option=" + getTemplateOption(productType)) }}
                  className="mt-2 inline-flex items-center font-medium text-lg sm:text-xl border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700 " >
                  Prices start from ${calculatePricingFromDays(productType,1).toFixed(2)} <ArrowRightIcon className="ml-1 h-5 w-5 " aria-hidden="true" />
                </button>
                <div className="mt-5 lg:mt-10 sm:px-5 px-10 xs:px-24 lg:px-0 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <button
                      onClick={() => {history.push("/StreamBuilder")}}
                      className="w-full flex items-center justify-center border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 py-2 md:text-lg px-5"
                    >
                      Get started
                    </button>
                  </div>
                  
                  <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <button
                      onClick={() => {setOpenVideoModal(true)}}
                      className="w-full flex items-center justify-center border border-transparent text-base font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 py-2  md:text-lg px-5"
                    >
                      Watch video
                    </button>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/12 xl:w-1/12"></div>
              <div className="lg:w-6/12 xl:w-5/12 mt-6 sm:mx-0 lg:-mt-14 md:mx-24 lg:mx-0" >
                  <RenderIf condition={productType === ProductType.MULTI_VOTE}>
                      <img style={{"width": '100%'  }}   src={process.env.PUBLIC_URL + '/images/MultiVoteTemplate.svg'} alt="" />
                  </RenderIf>
                  <RenderIf condition={productType === ProductType.COUNTDOWN}>
                      <img style={{"width": '100%' }}  src={process.env.PUBLIC_URL + '/images/CountDownTemplate.svg'} alt="" />
                  </RenderIf>
              </div>
            </div>

            <div className="relative pt-6 sm:pt-8 pb-8">
                <div className="w-full border-t border-gray-300" />
            </div>
            <RenderIf condition={galleryLoading} >
              <PageSpinner isEntireScreen={false}/>
            </RenderIf>
            <RenderIf condition={productType === ProductType.COUNTDOWN && !galleryLoading} >
            <ul role="list" className="space-y-6 lg:grid lg:gap-x-6 lg:gap-y-12 lg:space-y-0 lg:grid-cols-2 lg:gap-x-8">
              {templateGallery["countdownTemplates"].map((pc) => (
                <li key={pc.id}>
                  <div style={{cursor: 'pointer'}} className="space-y-4" onClick={() => {previewTemplate(pc)}}>
                    <PreviewWindow productType={productType} productConfig={pc} backgroundImage={pc.customBackgroundUrl} defaultWidth={502} defaultHeight={284} borderStyle={'1px solid rgba(229, 231, 235, 1)'} />
                  </div>
                </li>
              ))}
            </ul>
            </RenderIf>

            <RenderIf condition={productType === ProductType.MULTI_VOTE && !galleryLoading} >
            <ul role="list" className="space-y-6 lg:grid lg:gap-x-6 lg:gap-y-12 lg:space-y-0 lg:grid-cols-2 lg:gap-x-8">
              {templateGallery["votingPollTemplates"].map((pc) => (
                <li key={pc.id}>
                  <div style={{cursor: 'pointer'}} className="space-y-4" onClick={() => {previewTemplate(pc)}}>
                    <PreviewWindow productType={productType} productConfig={pc} backgroundImage={pc.customBackgroundUrl} defaultWidth={502} defaultHeight={284} borderStyle={'1px solid rgba(229, 231, 235, 1)'} />
                  </div>
                </li>
              ))}
            </ul>
            </RenderIf>
            
          </div>

          <ModalFrame 
            open={openTemplatePreview} 
            setOpen={setOpenTemplatePreview} 
            modalWidth={"70rem"}
            modalBody={<PreviewStreamModalBody productType={productType} productConfig={previewProductConfig} backgroundImage={previewProductConfig ? previewProductConfig.customBackgroundUrl : null}/>}
            isBasicCloseStyle={false}
            easyClose={false}
          />

          <ModalFrame 
            open={openVideoModal}
            setOpen={setOpenVideoModal}
            modalWidth={"80rem"}
            isBasicCloseStyle={true}
            modalBody={<VideoModalBody videoSource={getVideoURL(productType) + "?autoplay=1"} title={"Welcome to StreamCreate"}/>}
            easyClose={true}
          />

        </Body> 
    </Layout>
  )
}