import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react"
import { previewWindowStyle } from "Services/StreamConfigService"
import { MULTIVOTE_TEXT_MULITPLIER } from "Utilities/GeneralConstants";
import { truncateText } from "Utilities/HelperFunctions";
import { subTitleHeading, titleHeading } from "Utilities/TextFunctions";
import VoteTable from "./VoteComponents/VoteTable";


const formatLatestVoters = (latestVoters) => {
  const latestVotersTruncated = latestVoters.map(v => truncateText(v,15))
  return latestVotersTruncated.join(", ")
}

const MultiVoteAudioView = observer(({productConfig, backgroundImage, width, height}) => {
    
    const titleGrid = {
      paddingLeft: '4.5%',
      marginTop: '2%',
      marginBottom: '-0.75%',
      fontFamily : productConfig.titleFont
    }
    
    const subTitleGrid = {
      paddingLeft: '4.75%',
      marginBottom: '0.5%',
      fontFamily : productConfig.titleFont
    }


    return (
        <div className="h-full" style={previewWindowStyle(productConfig, backgroundImage, width, height)}>
            <Grid container >
            <Grid xs={12} style={titleGrid}  container >
               <h1 style={titleHeading(productConfig.titleColor,0.65,productConfig.titleDropShadow, productConfig.titleExtraLarge, MULTIVOTE_TEXT_MULITPLIER)}>{productConfig.title}</h1>
            </Grid>

            <Grid xs={12} style={subTitleGrid}  container >
                  <h2 style={subTitleHeading(productConfig.titleColor,0.5,productConfig.titleDropShadow, productConfig.titleExtraLarge, MULTIVOTE_TEXT_MULITPLIER)}>{productConfig.subtitle}</h2>
            </Grid>

            <Grid xs={10} justify="center" container style={{marginLeft:'-1.5%'}} >
              <VoteTable voteConfig={productConfig.voteConfig} />
            </Grid>

            <Grid xs={2} style={{paddingRight:50}}  container justify="center">
              <Grid xs={12} direction="column"  container >
                  
              </Grid>
            </Grid>
          </Grid >
          <div className="bg-black bg-opacity-60 bottom-0 w-full text-center absolute" style={{fontSize:'.28em', paddingBottom:'0.25%'}}> 
            <p className="inline-flex	text-white font-medium"> Latest Voters: </p> <p className="inline-flex text-white font-light">{productConfig.voteConfig.latestVoters ? formatLatestVoters(productConfig.voteConfig.latestVoters) : ""} </p>
          </div>
        </div>
    )
})

export default MultiVoteAudioView;
  