
const ReliabilityIcon = () => {
    return (
        <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#DBEAFE"/>
        <path d="M65.7893 29.8059C65.2646 29.8325 64.7364 29.846 64.205 29.846C56.3243 29.846 49.1357 26.8833 43.692 22.011C38.2483 26.8831 31.0598 29.8456 23.1793 29.8456C22.648 29.8456 22.1198 29.8322 21.5951 29.8056C20.9555 32.2764 20.6152 34.8676 20.6152 37.5384C20.6152 51.8756 30.4212 63.9225 43.6922 67.3382C56.9631 63.9225 66.7691 51.8756 66.7691 37.5384C66.7691 34.8677 66.4288 32.2766 65.7893 29.8059Z" fill="#FBBF24" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41 50L52 39.6875" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36 45L41 50" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const FlexibilityIcon = () => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#DBEAFE"/>
        <path d="M41.8116 26.7933C42.8776 22.4022 49.1224 22.4022 50.1884 26.7933C50.8771 29.6298 54.1269 30.9759 56.6195 29.4571C60.4782 27.106 64.894 31.5218 62.5429 35.3805C61.0241 37.8731 62.3702 41.1229 65.2067 41.8116C69.5978 42.8776 69.5978 49.1224 65.2067 50.1884C62.3702 50.8771 61.0241 54.1269 62.5429 56.6195C64.894 60.4782 60.4782 64.894 56.6195 62.5429C54.1269 61.0241 50.8771 62.3702 50.1884 65.2067C49.1224 69.5978 42.8776 69.5978 41.8116 65.2067C41.1229 62.3702 37.8731 61.0241 35.3805 62.5429C31.5218 64.894 27.106 60.4782 29.4571 56.6195C30.9759 54.1269 29.6298 50.8771 26.7933 50.1884C22.4022 49.1224 22.4022 42.8776 26.7933 41.8116C29.6298 41.1229 30.9759 37.8731 29.4571 35.3805C27.106 31.5218 31.5218 27.106 35.3805 29.4571C37.8731 30.9759 41.1229 29.6298 41.8116 26.7933Z" fill="#FCD34D"/>
        <path d="M53.5 46C53.5 50.1421 50.1421 53.5 46 53.5C41.8579 53.5 38.5 50.1421 38.5 46C38.5 41.8579 41.8579 38.5 46 38.5C50.1421 38.5 53.5 41.8579 53.5 46Z" fill="#FCD34D"/>
        <path d="M41.8116 26.7933C42.8776 22.4022 49.1224 22.4022 50.1884 26.7933C50.8771 29.6298 54.1269 30.9759 56.6195 29.4571C60.4782 27.106 64.894 31.5218 62.5429 35.3805C61.0241 37.8731 62.3702 41.1229 65.2067 41.8116C69.5978 42.8776 69.5978 49.1224 65.2067 50.1884C62.3702 50.8771 61.0241 54.1269 62.5429 56.6195C64.894 60.4782 60.4782 64.894 56.6195 62.5429C54.1269 61.0241 50.8771 62.3702 50.1884 65.2067C49.1224 69.5978 42.8776 69.5978 41.8116 65.2067C41.1229 62.3702 37.8731 61.0241 35.3805 62.5429C31.5218 64.894 27.106 60.4782 29.4571 56.6195C30.9759 54.1269 29.6298 50.8771 26.7933 50.1884C22.4022 49.1224 22.4022 42.8776 26.7933 41.8116C29.6298 41.1229 30.9759 37.8731 29.4571 35.3805C27.106 31.5218 31.5218 27.106 35.3805 29.4571C37.8731 30.9759 41.1229 29.6298 41.8116 26.7933Z" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M53.5 46C53.5 50.1421 50.1421 53.5 46 53.5C41.8579 53.5 38.5 50.1421 38.5 46C38.5 41.8579 41.8579 38.5 46 38.5C50.1421 38.5 53.5 41.8579 53.5 46Z" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="45.5" cy="45.5" r="5.5" fill="white"/>
        </svg>
    )
}

const HDIcon = () => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#DBEAFE"/>
        <rect x="24" y="26" width="47" height="42.8772" rx="8" fill="#FBBF24" stroke="#1D4ED8" stroke-width="3"/>
        <g clip-path="url(#clip0_328_256)">
        <path d="M56.788 40.8818L54.1006 56.3385L56.0406 56.3544C58.182 56.3544 59.8994 55.5698 61.1928 54.0009C62.4968 52.4319 63.2441 50.0625 63.4349 46.8927L63.4667 46.3839C63.4985 44.6347 63.1646 43.2936 62.4649 42.3607C61.7652 41.4278 60.721 40.9401 59.3323 40.8977L56.788 40.8818Z" fill="#FCD34D"/>
        <path d="M56.788 40.8818L54.1006 56.3385L56.0406 56.3544C58.182 56.3544 59.8994 55.5698 61.1928 54.0009C62.4968 52.4319 63.2441 50.0625 63.4349 46.8927L63.4667 46.3839C63.4985 44.6347 63.1646 43.2936 62.4649 42.3607C61.7652 41.4278 60.721 40.9401 59.3323 40.8977L56.788 40.8818Z" fill="#FCD34D"/>
        <path d="M56.788 40.8818L54.1006 56.3385L56.0406 56.3544C58.182 56.3544 59.8994 55.5698 61.1928 54.0009C62.4968 52.4319 63.2441 50.0625 63.4349 46.8927L63.4667 46.3839C63.4985 44.6347 63.1646 43.2936 62.4649 42.3607C61.7652 41.4278 60.721 40.9401 59.3323 40.8977L56.788 40.8818Z" fill="#FCD34D"/>
        <path d="M45.138 59.4153H40.7108L42.3427 49.9867H33.8056L32.1586 59.4153H27.7314L31.5542 37.4153H35.9814L34.4251 46.3301H42.9773L44.5185 37.4153H48.9457L45.138 59.4153ZM49.0968 59.4153L52.9196 37.4153L59.2356 37.4304C60.7163 37.4606 62.046 37.7981 63.2246 38.4428C64.4132 39.0774 65.3752 39.994 66.1105 41.1927C66.8459 42.3915 67.2891 43.7413 67.4402 45.2422C67.5208 46.1186 67.5108 47.0151 67.41 47.9318L67.3042 48.7326C66.861 51.956 65.5918 54.5448 63.4965 56.4991C61.4114 58.4432 58.878 59.4153 55.8962 59.4153H49.0968ZM56.7122 41.087L54.1587 55.7738L56.002 55.7889C58.0368 55.7889 59.6687 55.0434 60.8976 53.5527C62.1367 52.0618 62.8468 49.8104 63.0281 46.7985L63.0583 46.315C63.0885 44.6529 62.7712 43.3786 62.1064 42.4922C61.4416 41.6058 60.4494 41.1424 59.1298 41.1021L56.7122 41.087Z" fill="#1D4ED8"/>
        <path d="M45.138 59.4153H40.7108L42.3427 49.9867H33.8056L32.1586 59.4153H27.7314L31.5542 37.4153H35.9814L34.4251 46.3301H42.9773L44.5185 37.4153H48.9457L45.138 59.4153ZM49.0968 59.4153L52.9196 37.4153L59.2356 37.4304C60.7163 37.4606 62.046 37.7981 63.2246 38.4428C64.4132 39.0774 65.3752 39.994 66.1105 41.1927C66.8459 42.3915 67.2891 43.7413 67.4402 45.2422C67.5208 46.1186 67.5108 47.0151 67.41 47.9318L67.3042 48.7326C66.861 51.956 65.5918 54.5448 63.4965 56.4991C61.4114 58.4432 58.878 59.4153 55.8962 59.4153H49.0968ZM56.7122 41.087L54.1587 55.7738L56.002 55.7889C58.0368 55.7889 59.6687 55.0434 60.8976 53.5527C62.1367 52.0618 62.8468 49.8104 63.0281 46.7985L63.0583 46.315C63.0885 44.6529 62.7712 43.3786 62.1064 42.4922C61.4416 41.6058 60.4494 41.1424 59.1298 41.1021L56.7122 41.087Z" fill="#1D4ED8"/>
        </g>
        <defs>
        <clipPath id="clip0_328_256">
        <rect width="42" height="24" fill="white" transform="translate(28 37)"/>
        </clipPath>
        </defs>
        </svg>
    )
}


const SimplicityIcon = () => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#DBEAFE"/>
        <path d="M62.7889 26H32.1859C29.8727 26 28 27.8762 28 30.1875V34.5625C28 36.8738 29.8727 38.75 32.1859 38.75H62.7889C65.1021 38.75 66.9748 36.8738 66.9748 34.5625V30.1875C66.9748 27.8762 65.1021 26 62.7889 26ZM45.3015 43.5H32.1859C29.8727 43.5 28 45.3762 28 47.6875V60.8125C28 63.1238 29.8727 65 32.1859 65H45.3015C47.6147 65 49.4874 63.1238 49.4874 60.8125V47.6875C49.4874 45.3762 47.6147 43.5 45.3015 43.5ZM62.7889 43.5H58.417C56.1038 43.5 54.2311 45.3762 54.2311 47.6875V60.8125C54.2311 63.1238 56.1038 65 58.417 65H62.7889C65.1021 65 66.9748 63.1238 66.9748 60.8125V47.6875C66.9748 45.3762 65.1021 43.5 62.7889 43.5Z" fill="#FBBF24" stroke="#1D4ED8" stroke-width="4"/>
        </svg>

    )
}

const MusicIcon = () => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55Z" fill="#DBEAFE"/>
        <path d="M65.4375 34.7812L39.9375 40.9062" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M38 64V29.6848L66 23V57.3152" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M58.0625 61.8125C62.4635 61.8125 66.0311 59.4129 66.0311 56.453C66.0311 53.4931 62.4635 51.0938 58.0625 51.0938C53.6615 51.0938 50.0938 53.4931 50.0938 56.453C50.0938 59.4129 53.6615 61.8125 58.0625 61.8125Z" fill="#FBBF24" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29.9687 68.7188C34.3698 68.7188 37.9375 66.3193 37.9375 63.3594C37.9375 60.3995 34.3698 58 29.9687 58C25.5677 58 22 60.3995 22 63.3594C22 66.3193 25.5677 68.7188 29.9687 68.7188Z" fill="#FBBF24" stroke="#1D4ED8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const SupportIcon = () => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.5" r="27.5" fill="#DBEAFE"/>
        <circle cx="48.875" cy="43.0417" r="23.375" fill="#FBBF24"/>
        <circle cx="47.0417" cy="43.9584" r="7.79167" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5003 22.8751C36.1095 22.8751 26.8753 32.1092 26.8753 43.5001C26.8753 54.891 36.1095 64.1251 47.5003 64.1251C58.8912 64.1251 68.1253 54.891 68.1253 43.5001C68.1253 32.1092 58.8912 22.8751 47.5003 22.8751ZM22.292 43.5001C22.292 29.5779 33.5781 18.2917 47.5003 18.2917C61.4225 18.2917 72.7087 29.5779 72.7087 43.5001C72.7087 57.4223 61.4225 68.7084 47.5003 68.7084C33.5781 68.7084 22.292 57.4223 22.292 43.5001Z" fill="#1D4ED8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5003 36.6251C43.7034 36.6251 40.6253 39.7031 40.6253 43.5001C40.6253 47.297 43.7034 50.3751 47.5003 50.3751C51.2973 50.3751 54.3753 47.297 54.3753 43.5001C54.3753 39.7031 51.2973 36.6251 47.5003 36.6251ZM36.042 43.5001C36.042 37.1718 41.1721 32.0417 47.5003 32.0417C53.8286 32.0417 58.9587 37.1718 58.9587 43.5001C58.9587 49.8283 53.8286 54.9584 47.5003 54.9584C41.1721 54.9584 36.042 49.8283 36.042 43.5001Z" fill="#1D4ED8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6778 25.6775C30.5727 24.7826 32.0237 24.7826 32.9187 25.6775L42.6354 35.3942C43.5303 36.2892 43.5303 37.7402 42.6354 38.6351C41.7404 39.5301 40.2894 39.5301 39.3945 38.6351L29.6778 28.9185C28.7828 28.0235 28.7828 26.5725 29.6778 25.6775Z" fill="#1D4ED8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M52.3653 48.365C53.2602 47.4701 54.7112 47.4701 55.6062 48.365L65.3229 58.0817C66.2178 58.9767 66.2178 60.4277 65.3229 61.3226C64.4279 62.2176 62.9769 62.2176 62.082 61.3226L52.3653 51.6059C51.4703 50.711 51.4703 49.26 52.3653 48.365Z" fill="#1D4ED8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M65.3229 25.6775C66.2178 26.5725 66.2178 28.0235 65.3229 28.9185L55.6062 38.6351C54.7112 39.5301 53.2602 39.5301 52.3653 38.6351C51.4703 37.7402 51.4703 36.2892 52.3653 35.3942L62.082 25.6775C62.9769 24.7826 64.4279 24.7826 65.3229 25.6775Z" fill="#1D4ED8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M63.6958 27.3046C64.5907 28.1996 64.5907 29.6506 63.6958 30.5455L55.6062 38.6351C54.7112 39.5301 53.2602 39.5301 52.3653 38.6351C51.4703 37.7402 51.4703 36.2892 52.3653 35.3942L60.4549 27.3046C61.3498 26.4097 62.8008 26.4097 63.6958 27.3046Z" fill="#1D4ED8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M42.6354 48.365C43.5303 49.26 43.5303 50.711 42.6354 51.6059L32.9187 61.3226C32.0237 62.2176 30.5727 62.2176 29.6778 61.3226C28.7828 60.4277 28.7828 58.9767 29.6778 58.0817L39.3945 48.365C40.2894 47.4701 41.7404 47.4701 42.6354 48.365Z" fill="#1D4ED8"/>
        </svg>
    )
}

const features = [
    { name: '24/7 Reliability', description: 'Our servers are are built with automated failure prevention and recovery mechanisms.', icon: ReliabilityIcon },
    { name: 'Simplicity', description: 'No technical knowledge required. We make it easy to build and manage live streams.', icon: SimplicityIcon },
    { name: 'High Quality', description: 'We always stream in High Definition.' , icon: HDIcon },
    { name: 'Flexibility', description: 'Flexible and easy to use templates that cater to your creative ideas.', icon: FlexibilityIcon },
    { name: 'Music Streaming', description: 'Quality background music. We ensure all of our music is copyright free.', icon: MusicIcon },
    { name: 'Support', description: 'Team of technical experts ready to help you along the way.', icon: SupportIcon }
]


export default function FeaturesSection() {
return (
    <div className="relative bg-white py-10 lg:py-10 mb-4">
        <div className="mx-auto max-w-md px-5 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            
            <p className="mt-2 text-3xl font-bold  text-whitetracking-tight sm:text-4xl text-black">
            StreamCreate Features
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-black mb-2">
            Simple and intuitive online tool designed to automate live streaming.
            </p>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {features.map((feature) => (
                    <div key={feature.name} className="pt-0 lg:pt-8">
                        <div className="flow-root shadow-lg rounded-lg px-8 pb-6  pt-2">
                                <div>
                                    <span className="inline-flex items-center justify-center p-3 ">
                                        <feature.icon  />
                                    </span>
                                </div>
                                <h3 className=" text-lg font-medium text-black tracking-tight">{feature.name}</h3>
                                <p className="mt-2 pb-16 md:pb-12 lg:mb-2 text-base text-black h-8">
                                    {feature.description}
                                </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
)
}