import { Dialog } from '@headlessui/react'
import { PlayIcon } from '@heroicons/react/outline'
import InputWithLabel from 'Components/Structure/InputWithLabel'
import RenderIf from 'Components/Structure/RenderIf'
import StandardButton from 'Components/Structure/StandardButton'
import { SuccessOrErrorMessage } from 'Components/Structure/SuccessOrErrorMessage'
import ParagraphBoldLarge from 'Components/Typography/ParagraphBoldLarge'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { updateStreamConfig } from 'Services/StreamConfigService'
import { deployStreamConfig } from 'Services/StreamManagementService'
import { useStores } from 'Store/StoreConfig'
import { StreamConfigResponse } from 'Types/StreamTypes'
import { PUBLISH_STREAM_VIDEO_URL_WATCH, MAX_DAILY_REDEPLOYMENTS, PUBLISH_STREAM_SUCCESS_NEXT_STEP_MESSAGE, PUBLSIH_STREAM_SUCCESS_CONGRATULATIONS } from 'Utilities/GeneralConstants'
import { isVideoIdValid, isStreamKeyValid } from 'Utilities/HelperFunctions'
import { TechnicalSupport } from '../TechnicalSupport'
import { PublishStreamWarningBody } from './PublishStreamWarningBody'


export const PublishStreamModalBody = observer(({streamConfigUpdated, currentTimeUTC}) => {
    const { StreamBuilderStore } = useStores()
    const [streamConfig, setStreamConfig] = useState(StreamBuilderStore.getStreamConfig(false))
    const incomplete = isStreamKeyValid(streamConfig.streamKey) === false || isVideoIdValid(streamConfig.videoId) === false
    const [showStreamKeyError, setShowStreamKeyError] = useState(false)
    const [showVideoIdError, setVideoIdError] = useState(false)
    const [changesSaved,setChangesSaved] = useState(false)
    const [changesSavedSuccess,setChangesSavedSuccess] = useState(true)
    const [loading, setLoading] = useState(false)
    const [streamPublished, setStreamPublished] = useState(false)
    const [warningShown,setWarningShown] = useState(false)
    let isLastDailyRedeployment = streamConfig.redeploymentCount === (MAX_DAILY_REDEPLOYMENTS - 1)
    let redeploymentLimitReached = streamConfig.redeploymentCount >= MAX_DAILY_REDEPLOYMENTS


    const publishStream = async () => {
        setLoading(true)
        setChangesSaved(false)
        try {
          let streamConfigUpdate : StreamConfigResponse = await updateStreamConfig(streamConfig.id,streamConfig)
          if (streamConfigUpdate) {
                streamConfigUpdate = await deployStreamConfig(streamConfig.id)
          }
          streamConfigUpdated(streamConfigUpdate)
          setStreamPublished(true)
          setChangesSavedSuccess(true)
        } catch (e) {
          setChangesSavedSuccess(false)
        }
        setLoading(false)
        setChangesSaved(true)
    }


    const updateStoreStreamConfig = (newVideoId, newStreamKey) => {
        let newStreamConfig = StreamBuilderStore.getStreamConfig(false)
        newStreamConfig.videoId = newVideoId
        newStreamConfig.streamKey = newStreamKey
        StreamBuilderStore.setStreamConfig(newStreamConfig,false)
        setStreamConfig(newStreamConfig)
    }

    return (
        <React.Fragment>
            <div>
                <Dialog.Title className="text-lg leading-6 font-medium text-gray-600">
                    Publish Stream
                </Dialog.Title>

                {streamPublished ? 
                    <React.Fragment>
                        <div className="flex -mx-6 mt-2  pt-24 "   style={{ backgroundRepeat: 'no-repeat', backgroundImage: `url(${process.env.PUBLIC_URL}/images/PublishSuccess.svg)` }} >
                            <div className="md:w-4/12 "></div>
                            <div className="md:w-6/12  m-auto md:mt-10 md:mb-28  xs:ml-8 mt-60 ml-10 mr-4">
                                <p className="text-black md:text-white font-medium text-xl ">Congratulations! <br/>
                                    <span> {PUBLSIH_STREAM_SUCCESS_CONGRATULATIONS} </span>
                                </p>
                            </div>
                        </div>
                        <div className="mt-4 mb-4 mx-2" >  
                        <ParagraphBoldLarge fontColor="text-gray-500"> What's next? </ParagraphBoldLarge>
                        <ParagraphBoldLarge fontColor="text-gray-500"> {PUBLISH_STREAM_SUCCESS_NEXT_STEP_MESSAGE} </ParagraphBoldLarge>
                        </div >  
                    </React.Fragment>
                :
                <React.Fragment>

                    <RenderIf condition={streamConfig.redeploymentCount < (MAX_DAILY_REDEPLOYMENTS - 1) || (isLastDailyRedeployment && warningShown ) }>
                        <div className="flex flex-col lg:flex-row mt-3 sm:mt-4 mx-4 pb-10">
                            <div className="lg:w-7/12 pb-4">
                                <div className="max-w-sm mt-6">
                                    <InputWithLabel
                                        id="video-id"
                                        label="Video Id"
                                        onChange={e => updateStoreStreamConfig(e.target.value,streamConfig.streamKey)}
                                        onBlur={() => setVideoIdError(true)}
                                        value={streamConfig.videoId}
                                        type="text"
                                        showError={isVideoIdValid(streamConfig.videoId) === false && showVideoIdError}
                                        errorMessage={"YouTube Video Id is invalid."}
                                    />
                                
                                </div>
                                <div className="max-w-sm mt-4">
                                    <InputWithLabel
                                        id="stream-key"
                                        label="Stream key"
                                        onChange={e => updateStoreStreamConfig(streamConfig.videoId, e.target.value)}
                                        onBlur={() => setShowStreamKeyError(true)}
                                        value={streamConfig.streamKey}
                                        type="text"
                                        showError={isStreamKeyValid(streamConfig.streamKey) === false && showStreamKeyError}
                                        errorMessage={"Stream key format is incorrect."}
                                    />
                                </div>

                                
                                <p className="font-medium text-base	">
                                    <a className="items-center font-medium text-sm border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={PUBLISH_STREAM_VIDEO_URL_WATCH} target="_blank" rel="noreferrer">
                                        <PlayIcon className="mr-1 h-6 w-6 inline" aria-hidden="true" />Play Video: <span className="ml-1 font-normal inline-block">How to publish your stream to YouTube</span>
                                    </a>
                                </p>

                            </div>

                            <div className="lg:pl-5 pt-2 md:w-12/12 lg:w-5/12">
                                <TechnicalSupport/>
                            </div>
                        </div>

                        <div className="mt-4 sm:mt-6 mr-4 flex justify-end">
                            <StandardButton
                                disabled={incomplete}
                                onClick={e => {publishStream()}}
                                text={"Publish stream"}
                                isSpinning={loading}
                            />
                            
                        </div>

                        <div className="mr-4">
                            <SuccessOrErrorMessage 
                                showComponent={changesSaved} 
                                showSuccess={changesSavedSuccess} 
                                successMessage={"Successfully updated!"} 
                                errorMessage={"Failed to publish"}
                            />
                        </div>
                        
                    </RenderIf>
                    <RenderIf condition={ (isLastDailyRedeployment && !warningShown) || redeploymentLimitReached}>
                        <PublishStreamWarningBody currentTimeUTC={currentTimeUTC} setWarningShown={setWarningShown} isError={redeploymentLimitReached}/>
                    </RenderIf>
                </React.Fragment>
                }
            </div>
                
        </React.Fragment>
    )
})
