import React, { useState } from 'react'

export interface AccordionProps {
  tabs: Array<{
    title: string
    icon: React.ReactNode,
    contents: React.ReactNode,
  }>
}

const IconUp = () => <span style={{ float: 'right' }}><svg style={{ verticalAlign: 'sub' }} className="w-5 h-5 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" /></svg></span>
const IconDown = () => <span style={{ float: 'right' }}><svg style={{ verticalAlign: 'sub' }} className="w-5 h-5 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg></span>

const Accordion = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="rounded-md border border-blue-600">
      {tabs.map((tab, idx) => {
        const isActive = idx === activeTab
        return (
          <div key={tab.title}>
            <button
              style={{
                borderLeft: 0,
                borderRight: 0,
                borderBottom: isActive ? null : 0,
                borderTop: idx === 0 ? 0 : null,
              }}
              onFocus={() => setActiveTab(idx)}
              onClick={() => setActiveTab(idx)}
              className={`focus:outline-none block w-full  text-left  font-medium	px-2 py-1.5 border-blue-600 ${
                isActive
                ? `${idx === 0 ? 'rounded-tr-md rounded-tl-md' : 'border-t '} bg-blue-100 text-blue-700 border`
                : 'text-blue-600 border'
              }`}>
                {tab.icon}
                <span className="pl-1">{tab.title}</span>
                {isActive ? <IconDown /> : <IconUp />}
            </button>
            <div className={`${isActive ? 'block' : 'hidden'}`}>
              {tab.contents}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion