import InputWithLabel from "Components/Structure/InputWithLabel"
import { useState } from "react"
import { useStores } from "Store/StoreConfig"
import MyColorPicker from "./Utilities/MyColorPicker"
import SelectMenu from "./Utilities/SelectMenu"
import { DROPDOWN_FONTS } from 'Utilities/GeneralConstants'
import { observer } from "mobx-react"
import { ProductType } from "Types/StreamTypes"
import MyCheckBox from "./Utilities/MyCheckBox"


const StreamTitleConfig = observer(({isBuilderConfig}) => {
    const { StreamBuilderStore } = useStores()
    const [streamConfig] = useState(StreamBuilderStore.getStreamConfig(isBuilderConfig))
    const [productConfig,setProductConfig] = useState(StreamBuilderStore.getProductConfig(isBuilderConfig))
    
    const updateTitles = (newTitle, newTitleColor, newTitleFont, newTitleExtraLarge, newTitleDropShadow, newSubtitle) => {
        let newStreamConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig)
        newStreamConfig.productConfig.title = newTitle
        newStreamConfig.productConfig.titleColor = newTitleColor
        newStreamConfig.productConfig.titleFont = newTitleFont
        newStreamConfig.productConfig.titleExtraLarge = newTitleExtraLarge
        newStreamConfig.productConfig.titleDropShadow = newTitleDropShadow
        newStreamConfig.productConfig.subtitle = newSubtitle
        StreamBuilderStore.setStreamConfig(newStreamConfig,isBuilderConfig)
        setProductConfig(newStreamConfig.productConfig)
    }

    return (
        <div className="p-4 pt-2">
            <div className="flex-1 sm:w-1/2 lg:w-full">
                <InputWithLabel
                    id="title"
                    label="Title"
                    onChange={e => updateTitles(e.target.value, productConfig.titleColor, productConfig.titleFont, productConfig.titleExtraLarge,  productConfig.titleDropShadow, productConfig.subtitle)}
                    onBlur={() => {}}
                    value={productConfig.title}
                    type="text"
                    labelClassName="block text-md font-medium text-gray-700 mb-2"
                    inputClassName="block border border-grey-light text-gray-700 w-full p-2 rounded -mb-2  focus:outline-none focus:border-blue-500 focus:ring-blue-500 ring-1" 
                    maxLength={streamConfig.productType === ProductType.COUNTDOWN ? 71 : 45}
                />
            </div>
            
            <div className="flex-1 sm:w-1/2 lg:w-full">
                <InputWithLabel
                    id="subtitle"
                    label="Subtitle"
                    onChange={e => updateTitles(productConfig.title, productConfig.titleColor, productConfig.titleFont, productConfig.titleExtraLarge, productConfig.titleDropShadow, e.target.value)}
                    onBlur={() => {}}
                    value={productConfig.subtitle}
                    type="text"
                    labelClassName="block text-md font-medium text-gray-700 mb-2"
                    inputClassName="block border border-grey-light text-gray-700  w-full p-2 rounded -mb-2 focus:outline-none focus:border-blue-500 focus:ring-blue-500 ring-1" 
                    maxLength={streamConfig.productType === ProductType.COUNTDOWN ? 46 : 58}
                />
            </div>

            <div className="flex flex-col xl:flex-row mb-2">
                <div className="flex-1 inline-block lg:w-full sm:w-1/2 ">
                    <label className="text-sm text-base font-medium text-gray-700 " >Font</label>
                    <SelectMenu  items={DROPDOWN_FONTS} itemName={productConfig.titleFont} updateCallback={font => updateTitles(productConfig.title, productConfig.titleColor, font, productConfig.titleExtraLarge, productConfig.titleDropShadow, productConfig.subtitle)} />
                </div>
                <div className=" inline-block  sm:w-1/2 lg:w-full xl:w-4/12 xl:pl-2 pt-2 xl:pt-0">
                    <label className=" text-sm text-base font-medium text-gray-700 " >Color</label>
                    <div className="mt-0.5 -mr-0.5">
                        <MyColorPicker selectedColor={productConfig.titleColor} updateCallback={color => updateTitles(productConfig.title, color, productConfig.titleFont,productConfig.titleExtraLarge, productConfig.titleDropShadow, productConfig.subtitle)} width="100%" height="45px"/>
                    </div>
                </div>
            </div>

            <label className="text-sm text-base font-medium text-gray-700 " >Advanced settings</label>

            <div className="xs:flex mt-1">
                <MyCheckBox text={"Extra large"} checked={productConfig.titleExtraLarge} onChange={() => updateTitles(productConfig.title, productConfig.titleColor, productConfig.titleFont, !productConfig.titleExtraLarge, productConfig.titleDropShadow , productConfig.subtitle)} />
                <div className="ml-4 mt-2 xs:mt-0"></div>
                <MyCheckBox text={"Drop shadow"} checked={productConfig.titleDropShadow} onChange={() => updateTitles(productConfig.title, productConfig.titleColor, productConfig.titleFont, productConfig.titleExtraLarge, !productConfig.titleDropShadow , productConfig.subtitle)} />
            </div>

        </div>
    )
})

export default StreamTitleConfig
