interface ParagraphBoldLargeInterface {
    fontColor?: string
    children : any
}

export const ParagraphBoldLarge = ({ children, fontColor } : ParagraphBoldLargeInterface) => {
    return <p className={`text-lg leading-normal font-medium ${ fontColor ? fontColor : "text-gray-600" }`}>{children}</p>
}
  
export default ParagraphBoldLarge
  