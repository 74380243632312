import { useCallback, useEffect, useState } from "react"
import {Link} from 'react-router-dom'
import Footer from "Components/Structure/Foundation/Footer"
import StandardButton from "Components/Structure/StandardButton"
import InputWithLabel from "../Structure/InputWithLabel"
import RenderIf from "Components/Structure/RenderIf"
import { GENERIC_ERROR_MSG, INVALID_EMAIL_MSG } from "Utilities/GeneralConstants"
import { CognitoUser } from "amazon-cognito-identity-js"
import { createCognitoUser } from "Services/AuthService"
import { hashText, isEmailValid } from "Utilities/HelperFunctions"

const ResetPassword = () => {
    const [email, setEmail] = useState("")
    const [showEmailError, setShowEmailError] = useState(false)
    const [resetPasswordError, setResetPasswordError] = useState("")
    const [complete, setComplete] = useState(false)
    const [loading, setLoading] = useState(false)


    const resetPassword = useCallback((event) => {
        event.preventDefault()
        if(isEmailValid(email)){
            setLoading(true)
            const cognitoUser : CognitoUser = createCognitoUser(email)
            cognitoUser.forgotPassword({
                onSuccess: function(data) {
                    setLoading(false)
                    setComplete(true)
                },
                onFailure: function(err) {
                    setLoading(false)
                    setResetPasswordError(GENERIC_ERROR_MSG)
                }
            }, {emailId : hashText(email) })
        }
    },[email])


    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" && isEmailValid(email)) {
                resetPassword(event)
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [email, resetPassword])


    return (
        <div className="bg-blue-50 min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <Link to="/">
                  <img className="h-9 w-auto" src={process.env.PUBLIC_URL + '/images/StreamCreateLogo.svg'} alt="" />
                </Link> 
                <div className="bg-white px-6 py-8 pt-5 mt-6 rounded shadow-md text-black w-full">

                    <RenderIf condition={!complete}>
                         <h1 className="mb-4 text-2xl text-center font-medium text-gray-600"> Reset password </h1>

                        <InputWithLabel
                            id="email"
                            label=""
                            placeholder="Email" 
                            onChange={e => setEmail(e.target.value)}
                            onBlur={() => setShowEmailError(true)}
                            value={email}
                            type="text"
                            showError={!isEmailValid(email) && showEmailError}
                            errorMessage={INVALID_EMAIL_MSG}
                        />
                        
                        <RenderIf condition={resetPasswordError.length > 0}>
                            <div className="mb-4 text-sm text-red-600 mt-4">{GENERIC_ERROR_MSG}</div>
                        </RenderIf>


                        <div className="text-center mt-6">
                            <StandardButton
                                disabled={!isEmailValid(email)}
                                onClick={e => resetPassword(e)}
                                text={"Email password reset"}
                                isSpinning={loading}
                            />
                        </div>
                    </RenderIf>

                    <RenderIf condition={complete}>
                        <h1 className="text-xl text-center font-small text-gray-600">{complete ? "Password reset instructions have been sent to your email."  : "Reset password" }</h1>
                    </RenderIf>

                </div>

                <RenderIf condition={!complete}>
                    <div className="text-gray-500 mt-4">
                        <Link className="border-b  border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  to="../Login/">Back to login</Link>
                    </div>
                </RenderIf>

            </div>
            <Footer />
        </div>
    )
  }
  
  export default ResetPassword