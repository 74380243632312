import { ArrowRightIcon } from "@heroicons/react/solid"
import {Link} from 'react-router-dom'

const SupportIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    )
  }

export const TechnicalSupport = () => {
  return (
    <div  className="flex flex-col bg-white rounded-md shadow-md bg-white pt-4 pb-4 px-6">
      <div className="flex-1 relative pb-6 ">
        <div className="flex text-blue-700  pb-2 justify-center">
          <SupportIcon />
        </div>
        <h3 className="text-xl font-medium text-gray-900">
            In need of technical support?
        </h3>
        <p className="mt-4 text-base text-gray-600">
            We are here to help. Get in touch wih our friendly tech team of experts.
        </p>
      </div>
      <div className="rounded-bl-2xl rounded-br-2xl ">
        <Link className="inline-flex items-center text-lg font-medium  text-blue-700 " to="/Contact" target="_blank" rel="noopener noreferrer">
          Contact us <ArrowRightIcon className="ml-1 h-5 w-5 fk" aria-hidden="true" />

        </Link>
      </div>
    </div>
  )
}