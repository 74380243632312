import './styles.css'
import Accordion from './Accordion/Accordion'
import PreviewWindow from './PreviewWindow'
import { useStores } from 'Store/StoreConfig'
import AccordionConfig from './Accordion/AccordionConfig'
import { observer } from 'mobx-react'
import { useState } from 'react'


const CustomizeStream = observer(({isBuilderConfig}) => {
  const { StreamBuilderStore } = useStores();
  const [streamConfig] = useState(StreamBuilderStore.getStreamConfig(isBuilderConfig))
  return (
      <div className="flex flex-col lg:flex-row mt-3 lg:mt-6 ">
        <div className="flex-1 lg:inline-block lg:w-8/12 lg:pr-4 ">
          <PreviewWindow productType={streamConfig.productType} backgroundImage={streamConfig.productConfig.customBackgroundUrl} productConfig={streamConfig.productConfig} defaultWidth={759} defaultHeight={429} borderStyle={'1px solid rgba(229, 231, 235, 1)'}/>
 
          <div className="flex ">
            <button
                type="button"
                className="inline-flex items-center px-4 py-2 mt-2 mb-2 border border-gray-100 shadow-sm text-base font-medium rounded-md text-blue-700 bg-white focus:outline-none focus:ring-offset-2 hover:bg-gray-50"
                disabled={false}
                onClick={() =>{StreamBuilderStore.resetProductConfig(isBuilderConfig, streamConfig.productType)}}>
                Restore defaults
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </button>
          </div>

        </div>
        <div className="flex-initial lg:inline-block lg:w-4/12">
          <Accordion tabs={AccordionConfig(streamConfig.productType,isBuilderConfig)} />
        </div>
      </div>
  )
})

export default CustomizeStream