
interface HeadingInterface {
  children? : any
  textColor? : string
}

export const HeadingOne = ({ children, textColor } : HeadingInterface) => {
    return <h1 className={`text-2xl md:text-3xl leading-relaxed font-semibold mb-4 ${textColor ? textColor: 'text-gray-800' }`}>{children}</h1>
}
  
export default HeadingOne
