import { ModalFrame } from "Components/StreamBuilder/Modals/ModalFrame"
import { VideoModalBody } from "Components/StreamBuilder/Modals/VIdeoModalBody"
import { useState } from "react"
import { PROMOTION_VIDEO_URL_EMBED_COUNTDOWN } from "Utilities/GeneralConstants"

const heroBackgroundStyle = (windowWidth, windowHeight) => {
  if (windowWidth >= 1280) {
    return {
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/HeroIllustration.svg)`,  
      backgroundRepeat: "no-repeat", 
      backgroundPosition: 'center'
    }
  } else {
    return {}
  }
}


export default function HeroSection({windowWidth, windowHeight, history}) {
  const [openVideoModal, setOpenVideoModal] = useState(false)
  
  return (
      <main className={`lg:relative ${windowWidth < 1280 ? 'bg-blue-50' : '' }`} style={heroBackgroundStyle(windowWidth, windowHeight)}>
        <div className="mx-auto my-auto max-w-7xl w-full text-center pt-16 md:pt-18 pb-20 xl:pb-60 -mt-1 xl:text-left">
          <div className="px-4 sm:px-8 xl:pr-16">
            <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl lg:text-5xl xl:text-6xl">
             Live stream 24/7 on YouTube
            </h1>

            <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl lg:text-5xl xl:text-6xl">
            in a few clicks
            </h1>

            <h1 className="tracking-tight font-bold text-blue-600 text-4xl md:text-5xl lg:text-5xl xl:text-6xl">
            voting polls and countdowns
            </h1>

            <p className="mt-3 px-6 md:px-0 mx-auto text-lg text-gray-800 sm:text-xl md:mt-5">
              Use one of our beautiful templates and go live within minutes.
            </p>
            <p className="mx-auto text-lg text-gray-800 sm:text-xl">
              We will host your stream in high definition via our dedicated servers.
            </p>

            <div className="mt-10 px-10  xs:px-24 lg:px-0  sm:flex sm:justify-center xl:justify-start">
              <div className="rounded-md shadow">
                <button
                  onClick={() => {history.push("/StreamBuilder")}}
                  className="w-full  flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </button>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <button
                  onClick={() => {setOpenVideoModal(true)}}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 md:py-4 md:text-lg md:px-10"
                >
                  Watch video
                </button>
              </div>
            </div>
          </div>
        </div>

        <ModalFrame 
          open={openVideoModal}
          setOpen={setOpenVideoModal}
          modalWidth={"80rem"}
          isBasicCloseStyle={true}
          modalBody={<VideoModalBody videoSource={PROMOTION_VIDEO_URL_EMBED_COUNTDOWN + "?autoplay=1"} title={"Welcome to StreamCreate"}/>}
          easyClose={true}
        />

      </main>   
  )
}