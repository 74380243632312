import RenderIf from "./RenderIf"

interface InputWithLabelInterface {
  label: string
  id: string
  placeholder?: string
  onChange: (event: any) => any
  onBlur?: (event: any) => any
  onKeyDown? : (event: any) => any
  value: any
  type: string
  labelClassName?: string
  inputClassName?: string,
  showError?: boolean,
  errorMessage?: string
  maxLength?: number
  iconRight? : any
}

const InputWithLabel = ({ label, id, placeholder, onChange, onBlur, onKeyDown, value, type, labelClassName, inputClassName, showError, errorMessage, maxLength, iconRight }: InputWithLabelInterface) => {
  return (
    <div>
      <label htmlFor="email" className={labelClassName ? labelClassName : "block text-md font-medium text-gray-700" }>{label}</label>
      <div className=" relative mt-1">
        <input
          maxLength={maxLength ? maxLength : 45}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          type={type}
          name={id}
          spellCheck="false"
          id={id}
          onKeyDown={onKeyDown}
          className={inputClassName ? inputClassName : `block border text-gray-700 w-full p-3 rounded mb-2 focus:outline-none ${showError ? "border-red-600 ring-red-600 " : "focus:border-blue-500 focus:ring-blue-500"} ring-1` }
          placeholder={placeholder} />
          {iconRight ? 
              <div className={`absolute inset-y-1 right-0 pr-3 flex items-center pointer-events-none  ${showError ? "mb-7" : ""}`}>
                  {iconRight}
              </div>  
              : null
          }
          <RenderIf condition={showError}>
              <div className="mb-2 text-sm text-red-600"> {errorMessage} </div>
          </RenderIf>
          <RenderIf condition={!showError}>
              <div className="mb-4" /> 
          </RenderIf>

      </div>
    </div>
  )
}

export default InputWithLabel