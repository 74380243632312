import BackNext from 'Components/Structure/BackNext'
import RenderIf from 'Components/Structure/RenderIf'
import HeadingTwo from 'Components/Typography/HeadingTwo'
import Paragraph from 'Components/Typography/Paragraph'
import ParagraphBold from 'Components/Typography/ParagraphBold'
import { observer } from 'mobx-react'
import React, { useRef, useState } from 'react'
import { completeStreamPurchase, completeStripePayment } from 'Services/PaymentService'
import { uploadCustomBackground } from 'Services/StreamConfigService'
import { useStores } from 'Store/StoreConfig'
import { CheckoutDetails, PaymentProvider, StreamPurchaseDto } from 'Types/CheckoutTypes'
import { BackgroundType, ProductConfig, ProductType, StreamConfigResponse } from 'Types/StreamTypes'
import {  calculatePricingFromDays, formatDate, formatTime, isEmailValid, getDaysDifferenceFromToday, getFutureDateTime, getProductName } from 'Utilities/HelperFunctions'
import ContactDetails from './Checkout/ContactDetails'
import OrderConfirmation from './Checkout/OrderConfirmation'
import Payment from './Checkout/Payment'
import MyDateTimePicker from './Checkout/MyDateTimePicker'
import moment from 'moment'
import { INVALID_DATE_ENTERED, INVALID_REFERRAL_CODE, INVALID_STREAM_DURATION, MAX_STREAM_DAYS, STREAM_PURCHASE_FAILURE } from 'Utilities/GeneralConstants'
import { uploadVoteAvatars } from 'Services/VoteConfigService'
import InputWithLabel from 'Components/Structure/InputWithLabel'
import { validateReferralCode } from 'Services/ReferralService'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'


const isFirstNameValid = input => input.length >= 2 && input.length <= 40

const BuildStepFour = observer(() => {
  const { StreamBuilderStore } = useStores()
  const [streamConfig] = useState(StreamBuilderStore.getStreamConfig(true))
  const [payment, setPayment] = useState(null);
  const [paymentComplete, setPaymentComplete] = React.useState(false)
  const [referralCode, setReferralCode] = React.useState("")
  const [discountRate, setDiscountRate] = React.useState(1)
  const [referralCodeChecking, setReferralCodeChecking] = React.useState(false)
  const [showReferralCodeError, setShowReferralCodeError] = useState(false)
  const [referralCodeSuccess, setReferralCodeSuccess] = useState(false)
  const [daysDifference, setDaysDifference] = useState(getDaysDifferenceFromToday(streamConfig.streamEnd))
  const [price, setPrice] = useState(calculatePricingFromDays(streamConfig.productType, daysDifference))
  const { CheckoutStore } = useStores()
  const [checkoutDetails] = useState(CheckoutStore.checkoutDetails)
  const[highlightErrors, setHighlightErrors] = React.useState(false)
  const topRef = useRef(null)

  function isCheckoutFormComplete() {
    if (isEmailValid(checkoutDetails.email) && isFirstNameValid(checkoutDetails.fullName) && checkoutDetails.agreeTerms && !checkoutDetails.streamEndDateError) {
      return true
    } else if (!checkoutDetails.streamEndDateError) {
      setHighlightErrors(true)
      scrollTo(topRef)
    } else {
      setHighlightErrors(true)
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    return false
  }

  const updateStreamEndDateError = (streamEndDateError) => {
    let newCheckoutDetails = CheckoutStore.checkoutDetails
    newCheckoutDetails.streamEndDateError = streamEndDateError
    CheckoutStore.setCheckoutDetails(newCheckoutDetails)
  }

  const updateValidReferralCode= (validReferralCode) => {
    let newCheckoutDetails = CheckoutStore.checkoutDetails
    newCheckoutDetails.validReferralCode = validReferralCode
    CheckoutStore.setCheckoutDetails(newCheckoutDetails)
  }

  function updateStreamEndTime(newStreamEndTime) { 
    const newDaysDifference = getDaysDifferenceFromToday(newStreamEndTime)
    if (!isNaN(newDaysDifference) && newDaysDifference > 0 && newDaysDifference <= MAX_STREAM_DAYS ) {
      setDaysDifference(newDaysDifference)
      setPrice(calculatePricingFromDays(streamConfig.productType, newDaysDifference))
      updateStoreStreamConfig(newStreamEndTime)
      updateStreamEndDateError(null)
    } else {
      if (newDaysDifference <= 0 || newDaysDifference > MAX_STREAM_DAYS) {
        updateStreamEndDateError(INVALID_STREAM_DURATION)
      } else {
        updateStreamEndDateError(INVALID_DATE_ENTERED)
      }
    }
  }

  const updateStoreStreamConfig = (newStreamEnd) => {
    let newStreamConfig = StreamBuilderStore.streamConfig
    newStreamConfig.streamEnd = newStreamEnd.format("YYYY-MM-DDTHH:mm:ss")
    StreamBuilderStore.setStreamConfig(newStreamConfig,true)
  }

  async function uploadAllImages(streamConfigResponse : StreamConfigResponse, streamPurchaseDto : StreamPurchaseDto) {
    let productConfig : ProductConfig = streamConfigResponse.productConfig
    var uploadBackgroundResult = true
    var uploadVoteAvatarsResult = true
    
    if (productConfig.backgroundType === BackgroundType.CUSTOM_BACKGROUND) {
      uploadBackgroundResult = await uploadCustomBackground(streamConfigResponse.id,true,StreamBuilderStore)
    }

    if (productConfig.voteConfig && productConfig.voteConfig.includeImages) {
      uploadVoteAvatarsResult = await uploadVoteAvatars(streamConfigResponse.id, streamPurchaseDto.streamConfig.productConfig.voteConfig.voteOptions,false)
    }

    return uploadBackgroundResult && uploadVoteAvatarsResult
  }

  async function completePaypalPayment(paypalOrder) {
    const streamPurchaseDto : StreamPurchaseDto = generateStreamPurchaseDTO(paypalOrder.purchase_units[0].payments.captures[0].id, PaymentProvider.PAYPAL, checkoutDetails.validReferralCode)
    const streamConfigResponse : StreamConfigResponse = await completeStreamPurchase(streamPurchaseDto)
    if (!streamConfigResponse) {
      return STREAM_PURCHASE_FAILURE
    }
    await uploadAllImages(streamConfigResponse, streamPurchaseDto)
    StreamBuilderStore.setStreamConfig(streamConfigResponse,true)
    setPayment(streamConfigResponse.payment)
    setPaymentComplete(!paymentComplete)
    return ""
  }

  async function submitStripePayment(paymentAmount: string, paymentElement, stripe) {
    if (isCheckoutFormComplete()) {
      const {error: stripeError, paymentIntent} = await completeStripePayment(paymentAmount,paymentElement,stripe)
      if (stripeError) {
        return stripeError.message
      }
      const streamPurchaseDto : StreamPurchaseDto = generateStreamPurchaseDTO(paymentIntent.id, PaymentProvider.STRIPE, checkoutDetails.validReferralCode)
      const streamConfigResponse : StreamConfigResponse = await completeStreamPurchase(streamPurchaseDto)
      if (!streamConfigResponse) {
        return STREAM_PURCHASE_FAILURE
      }
      await uploadAllImages(streamConfigResponse, streamPurchaseDto)
      StreamBuilderStore.setStreamConfig(streamConfigResponse,true)
      setPayment(streamConfigResponse.payment)
      setPaymentComplete(!paymentComplete)
    }
    return ""
  }

  function generateStreamPurchaseDTO(paymentProviderId, paymentProvider, referralCode) {
    let checkoutDetails : CheckoutDetails = CheckoutStore.checkoutDetails
    const streamPurchaseDto : StreamPurchaseDto = {
            paymentProviderId: paymentProviderId,
            paymentProvider: paymentProvider,
            paymentFullName: checkoutDetails.fullName,
            customerId: streamConfig.customerId,
            paymentEmail: streamConfig.paymentEmail,
            productType: streamConfig.productType,
            cost: getDiscountedPrice().toString(),
            streamConfig: streamConfig
    }
    if (referralCode.length > 0) {
      streamPurchaseDto.referralCode = referralCode
    }
    return streamPurchaseDto
  }

  const scrollTo = (ref) => {
    if (topRef && topRef.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const setDateInputError = (error) => {
    if (error) {
      updateStreamEndDateError(INVALID_DATE_ENTERED)
    }
  }

  const checkReferralCode = async () => {
    setShowReferralCodeError(false)
    setReferralCodeSuccess(false)
    setReferralCodeChecking(true)
    const referralCodeRes = await validateReferralCode(referralCode)
    setReferralCodeChecking(false)
    if (referralCodeRes == null) {
      setDiscountRate(1)
      setShowReferralCodeError(true)
      updateValidReferralCode("")
    } else {
      setDiscountRate(referralCodeRes['discountRate'])
      setReferralCodeSuccess(true)
      updateValidReferralCode(referralCode)
    }
  }

  const getPercentOff = () => {
    return ((1-discountRate)*100).toFixed(0)
  }

  const getDiscountedPrice = () => {
    return (price*(discountRate)).toFixed(2)
  }
  
  return (
    <React.Fragment>

      <RenderIf condition={paymentComplete}>
        <OrderConfirmation payment={payment}/>
      </RenderIf>

      <RenderIf condition={!paymentComplete}>
        <div className="flex flex-col md:flex-row mx-5">
            <div className="md:w-6/12 lg:w-5/12 max-w-md lg:max-w-lg ">
              <HeadingTwo>Stream duration</HeadingTwo>
                <RenderIf condition={streamConfig.productType === ProductType.COUNTDOWN} >
                  <Paragraph>Your Countdown is set to end on: {formatDate(streamConfig.productConfig.countdownDate)} {formatTime(streamConfig.productConfig.countdownDate)}, UTC.</Paragraph>
                  <div className="mt-4"></div>
                </RenderIf>
              <div className="shadow-md bg-gray-50 rounded-t-lg pl-4 py-2 pt-3 flex-row">
                <ParagraphBold>When would you like your stream to end?</ParagraphBold>
                <div className="flex  flex-col xl:flex-row">
                  <div className=" lg:w-8/12 xl:w-7/12 w-11/12 my-4 ">
                    <MyDateTimePicker inputDate={streamConfig.streamEnd} setInputDate={updateStreamEndTime} onError={setDateInputError} minDateTime={moment.utc()} maxDateTime={getFutureDateTime(MAX_STREAM_DAYS)} isStandardInput={false} showErrorOutLine={highlightErrors}/>
                    <RenderIf condition={checkoutDetails.streamEndDateError} >
                      <p className="mt-1 text-sm text-red-600 -mb-2"> {checkoutDetails.streamEndDateError} </p>
                    </RenderIf>
                  </div>
                  <div className="lg:w-8/12 xl:w-4/12 xl:mt-6 ml-1 xl:ml-4">
                    <div className="xl:float-right">
                      <p className="text-gray-600 font-bold	text-sm ">Your price for {daysDifference} days:</p>
                      <p className="text-gray-600 font-bold	text-xl xl:float-right pb-1 xl:pb-0 ">${price.toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shadow-md bg-gray-50 rounded-b-lg pl-4 flex-row pt-3 pb-2 border-t	">
                <ParagraphBold>Do you have a promo code?</ParagraphBold>
                <div className="flex xl:flex-row">
                  <div className="w-7/12 sm:w-7/12 md:w-8/12 lg:w-6/12 xl:w-5/12 mt-2 pr-2">
                    <InputWithLabel
                      id="referralCode"
                      label=""
                      placeholder="Promo code"
                      inputClassName={`${showReferralCodeError ? "border-red-600 ring-red-600 hover:border-red-600 focus:border-red-600" : "" } ${referralCodeSuccess ? "border-green-600 ring-green-600 hover:border-green-600 focus:border-green-600 " : "" } border block border-gray-300 w-full p-3 rounded-md hover:border-gray-600 focus:border-gray-600  focus:outline-none mb-1`}
                      onChange={e => {setReferralCode(e.target.value)}}
                      value={referralCode}
                      type="text"
                    />
                    <RenderIf condition={referralCodeSuccess}>
                      <li className="flex items-start -mt-2">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                        </div>
                        <p className="ml-1 text-sm text-green-600 whitespace-nowrap	"> {`Promo code applied!`}</p>
                      </li>
                    </RenderIf>
                    <RenderIf condition={showReferralCodeError}>
                      <li className="flex items-start -mt-2">
                        <div className="flex-shrink-0">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        </div>
                        <p className="ml-1 text-sm text-red-600 whitespace-nowrap	"> {INVALID_REFERRAL_CODE}</p>
                      </li>
                    </RenderIf>
                  </div>
                  <div className="w-6/12 pt-3 ">
                    <div className="xl:float-left ">
                    <button
                      type="button"
                      className={`inline-flex items-center px-4 py-3 border border-gray-300 text-base font-medium rounded-md shadow-sm text-gray-600 focus:outline-none focus:ring-offset-2 focus:ring-gray-200 bg-white  hover:border-gray-600`}
                      disabled={false}
                      onClick={() => {checkReferralCode()}}>
                        Apply
                      <RenderIf condition={referralCodeChecking}>
                          <svg className="animate-spin ml-3 h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="gray" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="gray" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                      </RenderIf>
                    </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="-mr-5">
                <BackNext
                  onBack={() =>{StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep - 1); updateStreamEndDateError(null)}}
                  showNext={false}
                  disableNext={false} />
              </div>
            </div>
            
            <div className="md:w-1/12 lg:w-2/12 mt-3"></div>

            <div ref={topRef} className="  md:w-5/12">
              <HeadingTwo>Order summary</HeadingTwo>
              <Paragraph>Stream end: {formatDate(streamConfig.streamEnd)} {formatTime(streamConfig.streamEnd)}, UTC</Paragraph>
              <div className="mt-2 overflow-hidden max-w-md  md:rounded-lg ">
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className="whitespace-nowrap py-2 text-gray-600">
                        {getProductName(streamConfig.productType)}
                      </td>
                      <td className="hidden whitespace-nowrap py-2 text-gray-600 sm:table-cell text-right">
                        ${price.toFixed(2)}
                      </td>
                    </tr>
                    <RenderIf condition={referralCodeSuccess}>
                      <tr>
                        <td className="flex items-start whitespace-nowrap py-2 text-gray-600">
                          <p className="mr-1 text-gray-600"> {`Discount code` } </p>
                          <p className="mr-1 font-medium text-gray-600"> {`(${getPercentOff()}% off)` } </p>
                        </td>
                        <td className="hidden whitespace-nowrap py-2 text-gray-600 sm:table-cell text-right">
                          -${(price*(1-discountRate)).toFixed(2)}
                        </td> 
                      </tr>
                    </RenderIf>
                    <tr>
                        <td className={`whitespace-nowrap py-2 text-lg font-medium text-gray-900`}>
                          {"Order total"}
                        </td>
                        <td className={`hidden whitespace-nowrap py-2 text-lg font-medium text-gray-900 sm:table-cell text-right`}>
                          ${getDiscountedPrice()}
                        </td>
                    </tr>
                  </tbody>
                </table>
                <p className="text-sm -mt-2 leading-normal font-normal text-gray-600 float-right">Prices are in USD</p>
              </div>

              <div className="sm:mt-2 mt-4"></div>
              <ContactDetails highlightErrors={highlightErrors}/>
              <div className="mt-6"></div>
              <Payment paymentAmount={getDiscountedPrice()} submitStripePayment={(paymentAmount, paymentElement, stripe) => submitStripePayment(paymentAmount, paymentElement, stripe)} completePaypalPayment={(paypalOrder) => completePaypalPayment(paypalOrder)} isCheckoutFormComplete={() => isCheckoutFormComplete()} />
            </div>
        </div>
      </RenderIf>
      
    </React.Fragment>
  )
})


export default BuildStepFour