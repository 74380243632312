import SafeAreaInsets from "Components/Structure/SafeAreaInsets"

const Body = ({ children }) => {

    return (
        <SafeAreaInsets>
        <div className="pt-3 sm:pt-5" />
        <div className="bg-white px-6 py-5 rounded-md shadow-md text-black">
          {children}
        </div>
        </SafeAreaInsets>
    )
}
  
export default Body