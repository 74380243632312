import { GuideHeaderTwo } from "Components/Structure/GuideComponents/GuideHeaderTwo";
import { GuideParagraph } from "Components/Structure/GuideComponents/GuideParagraph";
import { GuideTitleTextLarge } from "Components/Structure/GuideComponents/GuideTitleTextLarge";
import { GuidePost } from "Types/GeneralTypes";

export const choosingVideoLatencyContent : GuidePost = {
    title : 'How to choose the right video latency on YouTube',
    href: '/Guides/how-to-choose-the-right-video-latency-on-youtube',
    imageUrl: `${process.env.PUBLIC_URL}/images/StreamLatencyGuideImage.svg`,
    datePublished: '20 March 2022',
    metaTitle: 'How to choose the right video latency on YouTube | StreamCreate',
    metaDescription: 'All live streams on YouTube have latency. Latency is the delay between the time you transmit video data and the moment your viewers are able to see it. YouTube allows you to reduce latency on your stream but if you reduce it by too much you may experience lower video quality and buffering. On the other hand lower latency, means better live interactivity between your viewers and the stream.',
    introduction: `There are three different settings for stream latency on YouTube and they will directly impact the quality of your stream.
                   You will not be able to change these settings once your stream starts so it is important to understand them in
                   order to set them correctly at the beginning.`,
    body: [
            <img className="my-4 max-w-xl mx-auto w-full" src={process.env.PUBLIC_URL + '/images/StreamLatencyGuideImage.svg'} alt="" />,
            <GuideHeaderTwo text={"Overview:"}/>,
            <GuideParagraph text={"Stream latency is the delay between what your screen or camera captures and when it is shown to viewers. All streams have latency beause it takes time for the data from your screen or camera to be sent to YouTube. The good news is that YouTube does give you the option to minimise latency but the bad news is that it may potentially reduce your stream quality. Below we will evaluate the streaming latency options provided by YouTube."}/>,
            <br/>,
            <GuideHeaderTwo text={"YouTube's options for live stream latency:"}/>,
            <GuideTitleTextLarge 
                title={"Normal latency"} 
                text={"This setting gives you the best quality for your stream and it should be used in cases where you do not plan to interact with your audience. This setting will delay your stream by 15-60 seconds, depending on your internet connection speed. Video buffering is highly unlikely."} 
            />,
            <GuideTitleTextLarge 
                title={"Low latency"} 
                text={"A balanced option that will delay your steam by around 5-15 seconds. With a good internet connection your stream can provide high video quality and a decent level of interactivity. Occasionaly your viewers may experience video buffering but this is generally acceptable."} 
            />,
            <GuideTitleTextLarge 
                title={"Ultra low latency"} 
                text={"This setting will result in a 2-5 seconds delay in your stream, which is great for user interaction. However there is a higher chance your viewers will experience buffering during your stream and you will not be able to support 1440p and 4k resolutions."} 
            />
    ]
}


