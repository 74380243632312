import { Dialog } from '@headlessui/react'
import React from 'react'
import PreviewWindow from '../PreviewWindow'


export const PreviewStreamModalBody = ({productType, productConfig, backgroundImage}) => {
    return (
        <React.Fragment>
              <div>
                  <Dialog.Title className="text-lg leading-6 font-medium text-gray-600">
                     Preview Stream
                  </Dialog.Title>
                <div className=" lg:mx-20 py-4 mt-2">
                  <div className="mb-4 mx-1" >
                    <img  src={process.env.PUBLIC_URL + '/images/YouTubePreviewTop.svg'} alt="" width="900px" height="40px" />
                  </div> 
                  <PreviewWindow productType={productType} productConfig={productConfig} backgroundImage={backgroundImage} defaultWidth={910} defaultHeight={513} borderStyle={'1px solid rgba(229, 231, 235, 1)'} />
                  <div className="mt-2 mx-1" > 
                    <img  src={process.env.PUBLIC_URL + '/images/YouTubePreviewBottom.svg'} alt="" />
                  </div> 
                </div>
              </div>
        </React.Fragment>
    )
}
