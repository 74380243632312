import React from 'react'
import BackNext from 'Components/Structure/BackNext'
import { useStores } from 'Store/StoreConfig'
import { PRODUCTS } from 'Utilities/GeneralConstants'
import { ProductType } from 'Types/StreamTypes'
import RenderIf from 'Components/Structure/RenderIf'
import ParagraphBold from 'Components/Typography/ParagraphBold'


const BuildStepOne = () => {
  const { StreamBuilderStore } = useStores()
  const [productType, setProductType] = React.useState(StreamBuilderStore.streamConfig.productType)
  const incomplete = !productType

  const updateProductType = (productType) => {
    setProductType(productType)
    StreamBuilderStore.resetProductConfig(true,productType)
    let newStreamConfig = StreamBuilderStore.streamConfig
    newStreamConfig.productType = productType
    StreamBuilderStore.setStreamConfig(newStreamConfig,true)
  }

  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row  mt-3 sm:mt-6 lg:mb-24">
        <div className="sm:ml-0  md:ml-4 md:w-7/12  lg:w-4/12">
          <ParagraphBold>Choose the template that's right for you</ParagraphBold>
          <div className="  flex flex-col sm:mt-3  ">
            {
              PRODUCTS
              .map(item => {
                const active = item.productType === productType
                const circleStyle = active ? 'bg-blue-700' : 'border-2 border-blue-500'

                return (
                  <button 
                    disabled={item.productType !== ProductType.COUNTDOWN && item.productType !== ProductType.MULTI_VOTE}
                    onClick={() => updateProductType(item.productType)}
                    className={`ring-1 block hover:bg-blue-100 focus:bg-blue-100 shadow-sm text-gray-700 flex flex-1 text-left w-auto focus:outline-none focus:ring-blue-500 rounded px-4 py-4 my-4  ${active ? 'bg-blue-100 border-blue-500 ring-blue-500' : '' }`} key={item.productType}>
                    <span className={`box-border self-center inline-block rounded-full h-4 w-4 mr-4 ${circleStyle}`} />
                    <span className={`text-base`}>{item.title}</span>
                  </button>
                )
              })
            }
          </div>
        </div>
        <div className="md:w-1/12 lg:w-2/12"></div>
        <div className="sm:ml-0 md:ml-4  lg:ml-0 md:w-8/12 lg:w-7/12" >
          <RenderIf condition={productType === ProductType.MULTI_VOTE}>
            <img style={{"width": 500 }} src={process.env.PUBLIC_URL + '/images/MultiVoteTemplate.svg'} alt="" />
          </RenderIf>
          <RenderIf condition={productType === ProductType.COUNTDOWN}>
            <img style={{"width": 500 }}  src={process.env.PUBLIC_URL + '/images/CountDownTemplate.svg'} alt="" />
          </RenderIf>
        </div>
      </div>
        
      <br></br>
      <div className="lg:mt-14"/>
      <BackNext
        showBack={false}
        disableNext={incomplete}
        onNext={() => StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep + 1)}
      />
    </React.Fragment>
  )
}

export default BuildStepOne