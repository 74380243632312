const OptionSelector = ({text, active, onClick}) => {

    const circleStyleActive = 'bg-blue-700'
    const circleStyleInactive = 'border-2 border-blue-700'

    return (
        <button className="flex font-semibold items-center text-base  focus:outline-none " onClick={onClick}>
          <span className={`box-border self-center inline-block rounded-full h-4 w-4 mr-2 ${active ? circleStyleActive : circleStyleInactive}`} />
            {text}
        </button>
    )
}

export default OptionSelector
