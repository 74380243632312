import Layout from 'Components/Structure/Foundation/Layout'
import Body from 'Components/Structure/Foundation/Body'
import PageHeader from 'Components/Typography/PageHeader'

import { StreamTable } from './StreamTable'
import { InformationAlertAction } from 'Components/Structure/Alerts/InformationAlertAction'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { WORLD_TIME_SERVER_URL } from 'Utilities/GeneralConstants'
import { Helmet } from 'react-helmet'
import {MANAGESTREAMS_TITLE} from 'Utilities/SEOConstants'

export const ManageStreams = () => {
  const [currentTimeUTC, setCurrentTimeUTC] = useState(moment.utc())

  useEffect(() => {
    setTimeout(() => {
      setCurrentTimeUTC(moment.utc());
    },1000);
  });

  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
          <PageHeader text="Manage streams" />
          <Helmet>
            <title>{MANAGESTREAMS_TITLE}</title>
          </Helmet>
          <Body> 
            <InformationAlertAction 
              message={
                <p>
                  Note: All dates and times are set in UTC. The current time in UTC is: <span className="font-semibold whitespace-nowrap">  {currentTimeUTC.format("DD MMM YYYY")} {currentTimeUTC.format('LT')} </span>
                </p> 
              }  
              detailsMessage="Learn more about UTC"
              detailsLink={WORLD_TIME_SERVER_URL}
            />
            <div className="pt-4" />
            <StreamTable currentTimeUTC={currentTimeUTC} />
          </Body>
  </Layout>
  )
}


