import { useState, useEffect, useCallback } from "react";
import Footer from "Components/Structure/Foundation/Footer"
import StandardButton from "Components/Structure/StandardButton"
import InputWithLabel from "../Structure/InputWithLabel"
import { GENERIC_ERROR_MSG } from "Utilities/GeneralConstants";
import { useHistory } from "react-router-dom";
import { createCognitoUser } from "Services/AuthService";
import { CognitoUser } from "amazon-cognito-identity-js";
import RenderIf from "Components/Structure/RenderIf";
import { deHashText, isNumber } from "Utilities/HelperFunctions";
import {Link} from 'react-router-dom'
import { verifyCustomerEmail } from "Services/CustomerService";


const VerifyAccount = (props) => {
    const [code, setCode] = useState("")
    const [verified, setVerified] = useState(false)
    const [verifyEmailError, setVerifyEmailError] = useState("")
    const [codeResent, setCodeResent] = useState("")
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [hash, setHash] = useState("")
    const history = useHistory();
    const complete = isNumber(code) && code.length === 6


    const verifyEmail = useCallback((event) => {
        event.preventDefault()
        if(complete){
            setLoading(true)
            const cognitoUser : CognitoUser = createCognitoUser(email)
            cognitoUser.confirmRegistration(code, true, async function(err, result) {
                if (err) {
                    if(err["code"] === "CodeMismatchException"){
                        setVerifyEmailError("Invalid or expired verfication code")
                    }else if(err["code"] === "ExpiredCodeException"){
                        setVerifyEmailError("Invalid or expired verfication code")
                    }else if(err.message === "User cannot be confirmed. Current status is CONFIRMED"){
                        setVerified(true)
                    }else{
                        setVerifyEmailError(GENERIC_ERROR_MSG)
                    }
                }else{
                    await verifyCustomerEmail(email)
                    setVerified(true)
                }
                setLoading(false)
            },  {emailId : hash} )
            
        }
    },[code, complete, email, hash])


    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" && complete) {
                verifyEmail(event)
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        }
    }, [code, complete, verifyEmail])


    useEffect(() => {
        try{
            setHash(props.location.pathname.replace("/VerifyEmail/",""))
            setEmail(deHashText(hash))
                
        } catch(error){}    
    }, [hash, email, props.location.pathname])


    const inputCode = (event) => {
        const inputValue = event.target.value.replace(/\s+/g, '')
        if(!inputValue || (inputValue.length <= 6 &&  isNumber(inputValue)) ){
            setCode(inputValue)
        }else{
            event.preventDefault()
        }        
    }


    const resendCode = () => {
        const cognitoUser : CognitoUser = createCognitoUser(email)
        cognitoUser.resendConfirmationCode(function(err, result) {
            if (err) {
                setCodeResent("Failed to resend code.")
            }else{
                setCodeResent("Successfully resent code!")
            }
        }, {emailId :hash})
        
    }


    return (
        <div className="bg-blue-50 min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <Link to="/">
                  <img className="h-9 w-auto" src={process.env.PUBLIC_URL + '/images/StreamCreateLogo.svg'} alt="" />
                </Link> 
                <div className="bg-white px-6 py-8 pt-5 mt-6 rounded shadow-md text-black w-full">
                    
                    <h1 className="mb-6 text-2xl text-center font-medium text-gray-600">{verified ? "Successfully verified!" : "Verify email" } </h1>

                    <RenderIf condition={!verified}>
                        <div className="mb-4 text-sm ">A verfication code has been sent to your email. Please insert and verify your code below.</div>

                        <InputWithLabel
                            id="code"
                            label=""
                            placeholder="Six digit verification code" 
                            onChange={e => inputCode(e)}
                            value={code}
                            type="text"
                        />

            
                        <RenderIf condition={!codeResent}>
                            <div className="text-right text-sm text-gray-500">
                                <button  onClick={e => resendCode()} className="border-blue-250 ">Resend code</button>
                            </div>
                        </RenderIf>

                        <RenderIf condition={codeResent.length > 0}>
                            <div className="text-right text-sm text-gray-500" >
                                {codeResent}
                            </div>
                        </RenderIf>


                        <RenderIf condition={verifyEmailError.length > 0}>
                            <div className="mb-4 mt-2 text-sm text-red-600 text-center"> {verifyEmailError} </div>
                        </RenderIf>

                        <div className="text-center mt-4">
                            <StandardButton
                                disabled={!complete}
                                onClick={e => verifyEmail(e)}
                                text={"Verify"}
                                isSpinning={loading}
                            />
                        </div>
                    </RenderIf>


                    <RenderIf condition={verified}>
                         <div className="text-center mt-4">
                            <StandardButton
                                disabled={false}
                                onClick={e => history.push("/Login")}
                                text={"Login now"}
                                isSpinning={false}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 pl-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                            </StandardButton>
                        </div>
                    </RenderIf>

                </div>
                
            </div>
            <Footer />
        </div>
    )
  }
  
  export default VerifyAccount