import { CheckCircleIcon } from "@heroicons/react/outline"
import { CognitoUser } from "amazon-cognito-identity-js"
import { SuccessAlert } from "Components/Structure/Alerts/SuccessAlert"
import Body from "Components/Structure/Foundation/Body"
import Layout from "Components/Structure/Foundation/Layout"
import InputWithLabel from "Components/Structure/InputWithLabel"
import RenderIf from "Components/Structure/RenderIf"
import StandardButton from "Components/Structure/StandardButton"
import HeadingThree from "Components/Typography/HeadingThree"
import HeadingTwo from "Components/Typography/HeadingTwo"
import PageHeader from "Components/Typography/PageHeader"
import Paragraph from "Components/Typography/Paragraph"
import React, { useState } from "react"
import { useStores } from "Store/StoreConfig"
import { GENERIC_ERROR_MSG, INCORRECT_PASSWORD_MSG } from "Utilities/GeneralConstants"
import { isPasswordValid } from "Utilities/HelperFunctions"
import {Helmet} from "react-helmet";
import { ACCOUNTSETTINGS_TITLE } from "Utilities/SEOConstants"


export const AccountSettings = () => {
  const { UserStore } = useStores()
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [showNewPasswordError, setShowNewPasswordError] = useState(false)
  const [errorUpdatingPassword, setErrorUpdatingPassword] = useState("")
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false)
  const [successfullyUpdatedPassword, setSuccessfullyUpdatedPassword] = useState(false)
  const updatePasswordComplete =  currentPassword.length > 0 && isPasswordValid(newPassword)

  const updatePassword = async (currentPassword, newPassword) => {
    if(updatePasswordComplete){
      const cognitoUser : CognitoUser = UserStore.cognitoUser
      setUpdatePasswordLoading(true)
      cognitoUser.changePassword(currentPassword, newPassword, function(err, result) {
        if (err) {
          if (err["code"] === "NotAuthorizedException") {
            setErrorUpdatingPassword(INCORRECT_PASSWORD_MSG)
          }else{
            setErrorUpdatingPassword(GENERIC_ERROR_MSG)
          }
        }else{
          setSuccessfullyUpdatedPassword(true)
        }
        setUpdatePasswordLoading(false)
      });
    }
  }

  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
      <Helmet>
          <title>{ACCOUNTSETTINGS_TITLE}</title>
      </Helmet>
      <PageHeader text="Account settings" />
      <Body> 
        <div className="px-2 md:px-12 pb-8 pt-6">

          <HeadingTwo> Contact details </HeadingTwo>
          <div className="pt-4" />
          <HeadingThree> Full Name </HeadingThree>
          <Paragraph> {UserStore.user.firstName + " " + UserStore.user.lastName}  </Paragraph>

          <div className="pt-6" />
          <HeadingThree> Email address </HeadingThree>
          <div className="sm:flex inline-block ">
            <Paragraph> {UserStore.user.email } </Paragraph>
            <div className=" inline-block sm:ml-4 pt-1 sm:pt-0">
              <CheckCircleIcon className=" -mb-1.5 h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <p className=" inline-block  ml-2 text-green-600">Verified</p>
          </div>


          <div className="relative pt-6 sm:pt-8 pb-6">
              <div className="w-full border-t border-gray-300" />
          </div>


          <div className="flex flex-col md:flex-row">
            <div className="flex-1 inline-block xl:w-4/12  md:w-6/12 sm:w-9/12 xs:w-10/12 pr-14 mb-2">
              <HeadingTwo> Update password </HeadingTwo>

              <Paragraph> Update your password associated with your account  </Paragraph>
            </div>
            <div className="flex-initial inline-block xl:w-4/12 lg:w-4/12 md:w-6/12 sm:w-9/12 xs:w-10/12 pr-8">

              {!successfullyUpdatedPassword ?
                <React.Fragment> 
                  <div className="flex-1">
                      <InputWithLabel
                      id="currentPassword"
                      label="Current password"
                      onChange={e => setCurrentPassword(e.target.value)}
                      onBlur={() => {}}
                      value={currentPassword}
                      type="password"
                      labelClassName="block text-md font-medium text-gray-700 mb-2"
                      inputClassName="block border border-grey-light w-full p-2 rounded mb-2 focus:outline-none focus:border-blue-500 focus:ring-blue-500 ring-1" 
                      maxLength={38}
                      showError={errorUpdatingPassword.length > 0}
                      errorMessage={errorUpdatingPassword}
                      />
                  </div>

                  <div className="mt-2" />
                  
                  <div className="flex-1">
                      <InputWithLabel
                      id="newPassword"
                      label="New password"
                      onChange={e => setNewPassword(e.target.value)}
                      onBlur={() => setShowNewPasswordError(true)}
                      value={newPassword}
                      type="password"
                      labelClassName="block text-md font-medium text-gray-700 mb-2"
                      inputClassName="block border border-grey-light w-full p-2 rounded focus:outline-none focus:border-blue-500 focus:ring-blue-500 ring-1" 
                      maxLength={45}
                      />
                  </div>

                  <RenderIf condition={!isPasswordValid(newPassword) && showNewPasswordError}>
                      <div className="text-sm text-red-600 ml-5 ">
                          <ul className="list-disc">
                              <li>8 characters minimum.</li>
                              <li>must contain a number.</li>
                              <li>an uppercase and a lowercase.</li>                            
                          </ul>
                      </div>
                  </RenderIf>

                  <div className="mt-4 flex justify-end">
                    <StandardButton
                        disabled={!updatePasswordComplete}
                        onClick={e => updatePassword(currentPassword,newPassword)}
                        text={"Update"}
                        isSpinning={updatePasswordLoading}
                    />
                  </div>    
                </React.Fragment> 
                :
                <SuccessAlert message="Password successfully updated!"/>
              }

            </div>
            <div className="flex-initial inline-block xl:w-4/12 lg:w-4/12  "></div>
          </div>

        </div>
      </Body>
    </Layout>

  )
}


