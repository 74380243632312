import RenderIf from "Components/Structure/RenderIf"

interface StandardButtonInterface {
    disabled: boolean
    onClick: (event: any) => any
    text: string
    children? : any
    isSpinning: boolean
    extendClassName? : string
    customColor? : string
  }
  
  const StandardButton = ({ disabled, onClick, text, children, isSpinning, extendClassName, customColor }: StandardButtonInterface) => {
    return (
      <div>
        <button
            type="button"
            className={`${extendClassName ? extendClassName : ''} inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none  ${disabled ? `bg-gray-400 cursor-not-allowed` : customColor ? customColor : 'bg-blue-700 hover:bg-blue-800'} } ${isSpinning ? `cursor-not-allowed` : ``}`}
            disabled={disabled}
            onClick={!isSpinning ? onClick : () => {}}>
            {text}
            <RenderIf condition={isSpinning}>
                <svg className="animate-spin  ml-3 h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </RenderIf>
            {children}
        </button>
      </div>
    )
  }
  
  export default StandardButton