import axios from 'axios'
import { UserStore } from 'Store/UserStore'
import { ContactUsForm } from 'Types/GeneralTypes'
import { User } from 'Types/UserTypes'
import { API_BASE_URL } from './ApiConfig'


export async function createCustomer(userId : string, firstName : string, lastName : string, email : string) {
    const createUserPayload = {
        "customerId" : userId,
        "email" : email,
        "firstName" : firstName,
        "lastName" : lastName,
    }
    const response = await axios.post(API_BASE_URL + "/customers", createUserPayload)
    if (response.status === 200) {
        return true
    }
    return false
}


export async function verifyCustomerEmail(email : string) {
    const response = await axios.put(API_BASE_URL + "/customers/" + email + "/verified")
    if (response.status === 200) {
        return true
    }
    return false
}


export async function emailContactUsForm(firstName : string, lastName: string, email: string, message : string, userStore : UserStore) {
    let contactUsForm : ContactUsForm = {
        firstName :firstName,
        lastName: lastName,
        email: email,
        message: message,
    }
  
    const user : User = userStore.user
    if (user) {
        contactUsForm.customerId = user.username
    }

    try {
        const response = await axios.post(API_BASE_URL + "/customers/contact", contactUsForm)
        if (response.status === 200) {
            return true
        }
    } catch (error) {
        return false
    }
}

