import InputWithLabel from "Components/Structure/InputWithLabel"
import {Link} from 'react-router-dom'
import { useState } from "react"
import { observer } from "mobx-react"
import { useStores } from "Store/StoreConfig"
import { INVALID_EMAIL_MSG, MISSING_FULLNAME_MSG, REQUIRE_AGREEMENT_TO_TERMS } from "Utilities/GeneralConstants"
import RenderIf from "Components/Structure/RenderIf"
import { isEmailValid, isFirstNameValid } from "Utilities/HelperFunctions"


const ContactDetails = observer(({highlightErrors}) => {
  const { CheckoutStore, StreamBuilderStore, UserStore } = useStores()
  const [checkoutDetails] = useState(CheckoutStore.checkoutDetails)
  const [showFirstNameError, setShowFirstNameError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showCheckedError, setShowCheckedError] = useState(false)
  const renderCheckBoxError = (showCheckedError || highlightErrors) && !checkoutDetails.agreeTerms

  const updateCheckoutContactDetails = (newFullName, newEmail, newAgreeTerms ) => {
      let newCheckoutDetails = CheckoutStore.checkoutDetails
      newCheckoutDetails.fullName = newFullName
      newCheckoutDetails.email = newEmail
      newCheckoutDetails.agreeTerms = newAgreeTerms
      CheckoutStore.setCheckoutDetails(newCheckoutDetails)
      let newStreamConfig = StreamBuilderStore.streamConfig
      newStreamConfig.paymentEmail = newEmail
      if(UserStore.user){
        const customerId = UserStore.user.username
        newStreamConfig.customerId = customerId
      }
      StreamBuilderStore.setStreamConfig(newStreamConfig, true)
  } 

  return (
    <div className="max-w-md">
        <h2 className="text-2xl leading-relaxed font-bold mb-2 text-gray-800 whitespace-nowrap	">Contact details</h2>
        <InputWithLabel
            id="fullName"
            label="Full name"
            onChange={e => updateCheckoutContactDetails(e.target.value,checkoutDetails.email, checkoutDetails.agreeTerms)}
            onBlur={() => setShowFirstNameError(true)}
            value={checkoutDetails.fullName}
            type="text"
            showError={!isFirstNameValid(checkoutDetails.fullName) && ( showFirstNameError || highlightErrors)}
            errorMessage={MISSING_FULLNAME_MSG}
        />
        <InputWithLabel
            id="email"
            label="Email"
            onChange={e => updateCheckoutContactDetails(checkoutDetails.fullName, e.target.value,checkoutDetails.agreeTerms)}
            onBlur={() => setShowEmailError(true)}
            value={checkoutDetails.email}
            type="text"
            showError={!isEmailValid(checkoutDetails.email) && ( showEmailError || highlightErrors )}
            errorMessage={INVALID_EMAIL_MSG}
            maxLength={80}
        />
        <div className="relative flex items-start mt-6" >
        <div className="flex items-center h-6">
          <input
            type="checkbox"
            onBlur={() => setShowCheckedError(true)}
            checked={checkoutDetails.agreeTerms}
            onChange={() => updateCheckoutContactDetails(checkoutDetails.fullName, checkoutDetails.email, !checkoutDetails.agreeTerms)}
            className={`h-4 w-4 rounded`}
          />
        </div>
        <div className="ml-3 text-md">
          <span id="comments-description" className="text-gray-500">
            I agree to StreamCreate <Link className="border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700"  to="/TermsOfService" target="_blank" rel="noopener noreferrer">  terms of service </Link>
          </span>
        </div>
      </div>
      <RenderIf condition={renderCheckBoxError}>
              <div className="mt-1 text-sm text-red-600 "> {REQUIRE_AGREEMENT_TO_TERMS} </div>
      </RenderIf>
    </div>


  )
})

export default ContactDetails

