import BackNext from 'Components/Structure/BackNext'
import RenderIf from 'Components/Structure/RenderIf'
import React, { useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { useStores } from 'Store/StoreConfig'
import { DEFAULT_AUDIO_MAP } from 'Utilities/GeneralConstants'
import PreviewWindow from './PreviewWindow'

const BuildStepThree = () => {
  const { StreamBuilderStore } = useStores()
  const incomplete = false
  const [musicOn, setMusicOn] = useState(true)

  const VolumeUpIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
      </svg>
    )
  }

  const VolumeOffIcon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5355 9.46448C17.4881 11.4171 17.4881 14.5829 15.5355 16.5355M17.364 6.63599C20.8787 10.1507 20.8787 15.8492 17.364 19.3639M6.58579 16.0001H5C4.44772 16.0001 4 15.5523 4 15.0001V11.0001C4 10.4478 4.44772 10.0001 5 10.0001H6.58579L11.2929 5.29294C11.9229 4.66298 13 5.10915 13 6.00005V20.0001C13 20.891 11.9229 21.3371 11.2929 20.7072L6.58579 16.0001Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <line x1="2.6" y1="5.2" x2="22.6" y2="20.2" stroke="white" strokeWidth="2"/>
        <line x1="2.4" y1="5.8" x2="20.8" y2="19.6" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }

  return (
    <React.Fragment>
      
        <div className="bg-gray-50 lg:mx-24 lg:px-16 px-4 py-5 mt-1 rounded-md shadow-md text-black">
          <img  src={process.env.PUBLIC_URL + '/images/YouTubePreviewTop.svg'} alt="" />
          <div className="mt-4" /> 
          <PreviewWindow  productType={StreamBuilderStore.streamConfig.productType} productConfig={StreamBuilderStore.streamConfig.productConfig} backgroundImage={null} defaultWidth={846} defaultHeight={477} borderStyle={'1px solid rgba(229, 231, 235, 1)'}/>
          <div className="mt-2" /> 
          <img  src={process.env.PUBLIC_URL + '/images/YouTubePreviewBottom.svg'} alt="" />
        </div>

        <div className="flex-1 inline-block w-6/12 ">
          <button
            type="button"
            className="inline-flex items-center px-4 lg:ml-24 py-2  border border-gray-100 shadow-sm text-base font-medium rounded-md text-blue-700 bg-white focus:outline-none focus:ring-offset-2 hover:bg-gray-50"
            disabled={false}
            onClick={() =>setMusicOn(!musicOn)}>
            <span className="mr-2">{musicOn ? "Mute" : "Play sound" }</span> {musicOn ? <VolumeOffIcon  aria-hidden="true" /> : <VolumeUpIcon  aria-hidden="true" />}
          </button>
        </div>

        <div className="flex-1 inline-block w-6/12 -mt-2">
        <BackNext
          onBack={() => StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep - 1)}
          onNext={() => StreamBuilderStore.setStreamBuilderStep(StreamBuilderStore.streamBuilderStep + 1)}
          disableNext={incomplete} />
        </div>

        <RenderIf condition={musicOn && StreamBuilderStore.streamConfig.productConfig.audioEnabled}>
          <ReactAudioPlayer
              src={DEFAULT_AUDIO_MAP[StreamBuilderStore.streamConfig.productConfig.defaultAudio]}
              autoPlay
              loop={true}
          />
        </RenderIf>

    </React.Fragment>
  )
}


export default BuildStepThree