import Layout from "../Components/Structure/Foundation/Layout"
import Body from "Components/Structure/Foundation/Body"
import PageHeader from "Components/Typography/PageHeader"
import StandardButton from "Components/Structure/StandardButton"
import { useHistory } from "react-router-dom"
import FaqSection from "Components/Support/FaqSection"
import { ChevronRight } from "@material-ui/icons"
import { useRef } from "react"
import ParagraphLink from "Components/Typography/ParagraphLink"
import { PUBLISH_STREAM_VIDEO_URL_WATCH, WORLD_TIME_SERVER_URL } from "Utilities/GeneralConstants"
import { Helmet } from "react-helmet"
import { SUPPORT_DESCRIPTION, SUPPORT_TITLE } from "Utilities/SEOConstants"

const StreamConfigFAQ = [
    {
        question: "What file types and sizes are allowed for background images?",
        answer: "We accept PNG and JPG image types, up to 5MB in size. For the best results we recommend an aspect ratio of 16:9. We also recommend a minimum pixel size of (1280 x 720px)."
    },
    {
        question: "What is the maximum stream duration?",
        answer: <div className="break-words"> 
                    We currently allow a maximum of 60 days of streaming (counted from the moment you purchase the stream). We plan to allow you to renew and extend your stream in the future. For now you can contact our{'\u00A0'} 
                    <ParagraphLink link={"/Contact"}>
                        customer support{'\u00A0'}
                    </ParagraphLink>
                    for assistance in renewal. 
                </div>
    }
]

const VotingPollStreamFAQ = [
    {
        question: "How do viewers of my stream vote?",
        answer: "Your audience votes via the YouTube live chat of your stream, using the vote code that you set up as part of your stream configuration. "
    },
    {
        question: "How should I format the vote code?",
        answer: "We suggest you use simple, short words and ensure each vote code is unique. We also recommend prefixing the vote code with an exclamation mark (!VoteCode) to eliminate accidental voting. When we read the votes we ignore capitalization, so !VOTECODE and !VoTeCoDe will both count as votes."
    },
    {
        question: "How do you track and collect votes?",
        answer: <div>
                    When a YouTube user casts a vote on your live stream the following happens: 
                    <ol className="list-decimal ml-10">
                        <li>Our system tracks your live chat in real time and identifies votes.</li>
                        <li>We will increment the vote count for the associate vote option on your stream.</li>
                        <li>The name of the user who voted will be displayed at the bottom of your stream. Only the last five voters will be displayed.</li>
                    </ol>
                </div>
    },
    {
        question: "Why is there a small delay between casting a vote and seeing it appear on screen?",
        answer: <div>
                    There is no stream on the planet that is in real time. This is because it takes time for live video data to be transmitted to YouTube. YouTube calls this delay 'latency'. In order to reduce latency down to a few seconds, you will need to configure your YouTube stream settings correctly. However, the lower the stream latency, the higher the chance your stream will buffer. YouTube has 3 options for live stream latency: 
                    <ul className="list-disc ml-10">
                        <li>Normal latency </li>
                        <li>Low latency</li>
                        <li>Ultra-low latency</li>
                    </ul>
                    We recommend using the Low latency setting as a balanced option - low latency with minimal stream buffering. For more information, checkout our video on how to{'\u00A0'}
                    <a className="items-center border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={PUBLISH_STREAM_VIDEO_URL_WATCH} target="_blank" rel="noreferrer">
                        configure your stream.
                    </a>
                </div>
    }
  
]

const TimeAndCountdownsFAQ = [
    {
        question: "What is UTC and why are the times in UTC?",
        answer: 
                <div> 
                    UTC stands for Coordinated Universal time. This is the primary time used by most computers and servers around the world. Because we cater to a global audience we have adopted this common standard for displaying time on our site. There are many sites and utilities that can help you convert UTC to your local time such as the{'\u00A0'} 
                    <a className="items-center border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={WORLD_TIME_SERVER_URL} target="_blank" rel="noreferrer">
                        world time server website.
                    </a>
                </div>
    },
    {
        question: "Why can't I set my countdown to go for more than 60 days?",
        answer: "The countdown timer is limited to 60 days since the maximum stream duration is 60 days."
    }
]

const StreamPublishingFAQ = [
    {
        question: "Where can I find the Stream key and Video Id in YouTube?",
        answer: <div> 
                    After logging into your YouTube account, click on the video icon on the top right and then click “Go live”. Now on the left you want to click the calendar icon to manage your streams. Then on the top right you want to click “Schedule Stream”, fill in all your details and make sure to select “No, it’s not made for kids” to ensure you have a live chat. Once you click done, you will be on the stream preview page, make sure to set the stream latency to “Low-latency”. On this page you will be able to copy your stream key and you will also be able to obtain your video ID from the browser URL. For example if your browser url looks like this:
                    /video/<span className="text-pink-500">2NI1p1ODQLa</span>/livestreaming, the video ID would be: <span className="text-pink-500">2NI1p1ODQLa. </span>
                    Please checkout our video on how to{'\u00A0'} 
                    <a className="items-center border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={PUBLISH_STREAM_VIDEO_URL_WATCH} target="_blank" rel="noreferrer">
                        configure your stream{'\u00A0'}
                    </a>
                    for a video guide. 

                </div>
        
    },
    {
        question: "Why do I need to create a Scheduled Live Stream instead of a regular Stream?",
        answer: <div> 
                    Regular youtube live streams are designed for creators who want to go live with a single live stream and who do not plan to run a 24/7 stream. If there is any data loss during streaming, regular streams will immediately cut off. With Scheduled streams, you can create and manage multiple 24/7 live streams and they will only be cut off after hours of data loss. Please checkout our video on how to{'\u00A0'}
                    <a className="items-center border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={PUBLISH_STREAM_VIDEO_URL_WATCH} target="_blank" rel="noreferrer">
                        configure your stream{'\u00A0'}
                    </a>
                    for detailed information.
                </div>
    },
    {
        question: "Why do I need to provide a Stream key?",
        answer: "Your YouTube stream key enables us to stream video data to your live stream. We will never abuse this privilege and will only stream the templates you have built. The stream key is added as the final step before you publish your stream."
    },
    {
        question: "Why do I need to provide a Video Id?",
        answer: "A Youtube video ID is a unique identifier for videos and streams on Youtube. The video Id will enable us to monitor the live chat of your stream and assist you if any technical problems arise."
    }
]

const LiveStreamManagementFAQ = [
    {
        question: "Do I need a StreamCreate account to manage streams?",
        answer: "Yes you do. With a StreamCreate account, you have access to a Dashboard from which you can edit, preview, republish and track all your streams."
    },
    {
        question: "Can I update my stream after publishing?",
        answer: "Yes, you can. Login to your StreamCreate account and on the top right click “Dashboard” and then “Manage streams”. Click the “Update” button and you will be presented with your stream configuration, which you can update and modify. When you are happy with your changes you can click the “Save updates” button to immediately display your changes on your live stream. Note that this does not include changes to your music loop selection. In order to update the music loop, you will have to click “Save updates”, close the dialog and then republish your stream via the “Publish” button."
    },
    {
        question: "Will stream republishing end my stream and how long does it take?",
        answer: "Your stream should experience no downtime during a republish. Republishing will usually take a few minutes. "
    },
    {
        question: "Can I change a Steam Key or Video Id after publishing?",
        answer: <div className="break-words"> 
                     Yes, you can change both. Login to your StreamCreate account and on the top right click “Dashboard” and then “Manage streams”. Click the “Publish” button on your stream and enter the new values. Make sure you are getting these values from YouTube, as shown in the how to{'\u00A0'}
                     <a className="items-center border-b border-opacity-0 hover:border-opacity-100 border-blue-300 text-blue-700" href={PUBLISH_STREAM_VIDEO_URL_WATCH} target="_blank" rel="noreferrer">
                        configure your stream{'\u00A0'}
                    </a>
                    video.
                </div>
    },
    {
        question: "What should I do if my stream is experiencing issues?",
        answer: <div className="break-words"> 
                    If your stream is experiencing performance issues, you can try republishing your stream. Login to your StreamCreate account and on the top right click “Dashboard” and then “Manage streams”, you can then republish via the “Publish” button for your stream. If problems persist please reach out on our{'\u00A0'}
                    <ParagraphLink link={"/Contact"}>
                        customer support page.
                    </ParagraphLink>
                </div>
    }
]

const PaymentsAndPricingFAQ = [
    {
        question: "What is the cost of streaming and how is it calculated?",
        answer: <div className="break-words"> 
                    Cost is calculated based on the template you have selected and the number of days you would like to stream. You can check prices using our{'\u00A0'}
                    <ParagraphLink link={"/Pricing"}>
                        streaming cost calculator.{'\u00A0'}
                    </ParagraphLink>
                </div>
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept online payments using Visa, MasterCard, American Express and PayPal."
    },
    {
        question: "Can I get a refund for a purchased stream?",
        answer: <div className="break-words">
                    We do not offer refunds for change of mind. We will only provide you with a refund of the Services Fee if we are unable to deliver our services. Please refer to{'\u00A0'}
                    <ParagraphLink link={"/TermsOfService"}>
                        terms of service{'\u00A0'}
                    </ParagraphLink>
                    for more information.
                </div>
    }
]

const FAQTitles = ["Stream Configuration", "Voting Poll Stream", "Time and Countdowns", "Stream Publishing", "Live Stream Management", "Payments and Pricing"]


export const Support = () => {
  const history = useHistory()
  const StreamConfigRef = useRef()
  const VotingPollStreamRef = useRef()
  const TimeAndCountdownsRef = useRef()
  const StreamPublishingRef = useRef()
  const LiveStreamManagementRef = useRef()
  const PaymentsAndPricingRef = useRef()
  const faqRefs = [StreamConfigRef,VotingPollStreamRef,TimeAndCountdownsRef,StreamPublishingRef,LiveStreamManagementRef,PaymentsAndPricingRef]

  const scrollTo = (ref) => {
    if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }


  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
        <Helmet>
            <title>{SUPPORT_TITLE}</title>
            <meta name="description" content={SUPPORT_DESCRIPTION} />
        </Helmet>
        <PageHeader text={"Support"}  />
        <Body> 
            <div className="flex flex-col lg:flex-row a xl:mx-24 my-1 md:my-3 lg:my-5">
                <div className="lg:w-6/12 lg:mx-3">
                    <div className="flex flex-col bg-white rounded-md shadow-md bg-white pt-4 pb-4 pl-6 sm:pl-4 lg:pl-4 xl:pl-10 xl:pr-4">
                        <div className="flex-1 relative pb-4">
                            <h3 className="text-xl font-medium text-gray-900">
                                Jump to question section
                            </h3>
                            <ul role="list" className="mt-2">
                                {FAQTitles.map((feature, index) => (
                                    <li key={feature} className="flex text-md md:text-lg">
                                        <button onClick={() => {scrollTo(faqRefs[index])}}>
                                            <ChevronRight className="text-blue-600 -mt-1" aria-hidden="true" />
                                            <span className="ml-1 text-blue-600 font-medium ">{feature}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="lg:w-6/12 lg:mx-3 lg:mt-0 mt-5">
                    <div  className="flex flex-col bg-white rounded-md shadow-md bg-white pt-4 pb-4 pl-6 sm:pl-4 lg:pl-4 xl:pl-10 xl:pr-4">
                        <div className="flex-1 relative pb-2 ">
                            <h3 className="text-xl font-medium text-gray-900">
                                Need additional assistance?
                            </h3>
                            <p className="mt-2 mb-3 text-base text-gray-600">
                                Reach out to our friendly customer support team.
                            </p>
                            <StandardButton
                                disabled={false}
                                extendClassName={"px-10"}                        
                                onClick={e => { history.push("/Contact")}}
                                text={"Contact Us"}
                                isSpinning={false}
                            />
                            <p className="mt-5 mb-3 text-base text-gray-600">
                                Join our discord server for voice and chat support.
                            </p>
                            <button
                                style={{backgroundColor : '#5865F2'}}
                                className={`inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none `}
                                onClick={e => { window.open("https://discord.gg/UGt94sRRh5")}}
                            >
                                Join Discord <img className="inline-flex h-5 ml-2" src={process.env.PUBLIC_URL + '/images/DiscordLogo.svg'} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
                
            <div className="max-w-7xl mx-auto py-2 lg:px-8 -mt-2">
                <div className="max-w-4xl mx-auto">
                    <div ref={StreamConfigRef}>
                        <FaqSection title={"Stream Configuration"} faqs={StreamConfigFAQ} />
                    </div>
                    <div ref={VotingPollStreamRef}>
                        <FaqSection title={"Voting Poll Stream"} faqs={VotingPollStreamFAQ} />
                    </div>
                    <div ref={TimeAndCountdownsRef}>
                        <FaqSection title={"Time and Countdowns"} faqs={TimeAndCountdownsFAQ} />
                    </div>
                    <div ref={StreamPublishingRef}>
                        <FaqSection title={"Stream Publishing"} faqs={StreamPublishingFAQ} />
                    </div>
                    <div ref={LiveStreamManagementRef}>
                        <FaqSection title={"Live Stream Management"} faqs={LiveStreamManagementFAQ} />
                    </div>
                    <div ref={PaymentsAndPricingRef}>
                        <FaqSection title={"Payments and Pricing"} faqs={PaymentsAndPricingFAQ} />
                    </div>
                </div>
            </div>
        </Body> 
    </Layout>
  )
}
