import { ProductType } from "Types/StreamTypes"
import StreamTitleConfig from "./StreamTitleConfig"
import BackgroundConfig from "./BackgroundConfig"
import CountdownTimerConfig from "./CountdownTimerConfig"
import AudioConfig from "./AudioConfig"
import VoteTableConfig from "./VoteTableConfig"

const IconPhotograph = () => {
  return (
    <svg style={{ verticalAlign: 'sub' }} className="w-5 h-5 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
    </svg>
  )
}

const IconTag = () => {
  return (
    <svg style={{ verticalAlign: 'sub' }} className="w-5 h-5 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"></path>
    </svg>
  )
}

const IconTime = () => {
  return (
    <svg style={{ verticalAlign: 'sub' }} className="w-5 h-5 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
  )
}

const IconMusicNote = () => {
  return (
    <svg style={{ verticalAlign: 'sub' }} className="h-5 w-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
    </svg>
  )
}



const IconChartBar = () => {
  return (
    <svg style={{ verticalAlign: 'sub' }} className="h-5 w-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
    </svg>
  )
}


const AccordionConfiguration = (product: ProductType, isBuilderConfig: boolean) => {
            
    if (product === ProductType.COUNTDOWN) {
        return [{
          title: 'Stream title',
          icon: <IconTag />,
          contents: (
            <StreamTitleConfig isBuilderConfig={isBuilderConfig}/>
          )
        }, {
          title: 'Background',
          icon: <IconPhotograph />,
          contents: (
            <BackgroundConfig isBuilderConfig={isBuilderConfig}/>
          )
        }, {
          title: 'Countdown timer',
          icon: <IconTime />,
          contents: (
            <CountdownTimerConfig isBuilderConfig={isBuilderConfig}/>
          )
        }, {
          title: 'Audio setup',
          icon: <IconMusicNote />,
          contents: (
            <AudioConfig isBuilderConfig={isBuilderConfig}/>
          )
        }]
    } else if (product === ProductType.MULTI_VOTE) {
        return [{
          title: 'Stream title',
          icon: <IconTag />,
          contents: (
            <StreamTitleConfig isBuilderConfig={isBuilderConfig}/>
          )
        }, {
          title: 'Background',
          icon: <IconPhotograph />,
          contents: (
            <BackgroundConfig isBuilderConfig={isBuilderConfig}/>
          )
        }, {
          title: 'Vote table',
          icon: <IconChartBar />,
          contents: (
            <VoteTableConfig isBuilderConfig={isBuilderConfig}/>
          )
        }, {
          title: 'Audio setup',
          icon: <IconMusicNote />,
          contents: (
            <AudioConfig isBuilderConfig={isBuilderConfig}/>
          )
        }]
    }
  }


  export default AccordionConfiguration