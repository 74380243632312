import { useState } from "react"
import { useStores } from "Store/StoreConfig"
import MyColorPicker from "./Utilities/MyColorPicker"
import SelectMenu from "./Utilities/SelectMenu"
import { DROPDOWN_FONTS, MAXIMUM_VOTE_OPTIONS } from 'Utilities/GeneralConstants'
import { observer } from "mobx-react"
import { getDefaultVoteOption } from "Store/StreamBuilderStore"
import VoteInput from "./VoteInput"
import RenderIf from "Components/Structure/RenderIf"
import MyCheckBox from "./Utilities/MyCheckBox"

const PlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

const VoteTableConfig = observer(({isBuilderConfig}) => {
    const { StreamBuilderStore } = useStores()
    const [productConfig] = useState(StreamBuilderStore.getProductConfig(isBuilderConfig))

    const updateVoteConfig = (newFont, newFontColor, isIncludeImages, newDropShadow) => {
        let newVoteConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig).productConfig.voteConfig
        newVoteConfig.font = newFont
        newVoteConfig.fontColor = newFontColor
        newVoteConfig.includeImages = isIncludeImages
        newVoteConfig.dropShadow = newDropShadow
        StreamBuilderStore.setVoteConfig(newVoteConfig,isBuilderConfig)
    }

    const updateVoteOption = (newName,newCode,newAvatarFile : File, index) => {
        let newVoteConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig).productConfig.voteConfig
        let newVoteOption = newVoteConfig.voteOptions[index];
        newVoteOption.name = newName
        newVoteOption.code = newCode
        if (newAvatarFile) {
            newVoteOption.avatarUrl = URL.createObjectURL(newAvatarFile)
            newVoteOption.imagesUpdated = true
        }
        newVoteOption.avatarFile = newAvatarFile
        newVoteConfig.voteOptions[index] = newVoteOption
        StreamBuilderStore.setVoteConfig(newVoteConfig,isBuilderConfig)
    }

    const addVoteOption = () => {
        productConfig.voteConfig.voteOptions.push(getDefaultVoteOption(productConfig.voteConfig.voteOptions.length + 1))
        StreamBuilderStore.setVoteConfig(productConfig.voteConfig,isBuilderConfig)
    }


    const removeVoteOption = (removalIndex) => {
        productConfig.voteConfig.voteOptions.splice(removalIndex,1)
        StreamBuilderStore.setVoteConfig(productConfig.voteConfig,isBuilderConfig)
    }


    return (
        <div className="p-4 pt-2">
            <div className="flex flex-col xl:flex-row">
                <div className="flex-1 inline-block lg:w-full sm:w-1/2 ">
                    <label className="text-sm text-base font-medium text-gray-700 ">Font</label>
                    <SelectMenu  items={DROPDOWN_FONTS} itemName={productConfig.voteConfig.font} updateCallback={font => updateVoteConfig(font, productConfig.voteConfig.fontColor, productConfig.voteConfig.includeImages, productConfig.voteConfig.dropShadow)} />
                </div>
                <div className=" inline-block  sm:w-1/2 lg:w-full xl:w-4/12 xl:pl-2 pt-2 xl:pt-0">
                    <label className=" text-sm text-base font-medium text-gray-700 ">Title color</label>
                    <div className="mt-0.5 -mr-0.5">
                        <MyColorPicker selectedColor={productConfig.voteConfig.fontColor} updateCallback={color => updateVoteConfig(productConfig.voteConfig.font, color, productConfig.voteConfig.includeImages, productConfig.voteConfig.dropShadow)} width="100%" height="45px"/>
                    </div>
                </div>
            </div>

            <div className="xs:flex mt-2">
                <MyCheckBox text={"Drop shadow"} checked={productConfig.voteConfig.dropShadow} onChange={() => updateVoteConfig(productConfig.voteConfig.font, productConfig.voteConfig.fontColor, productConfig.voteConfig.includeImages, !productConfig.voteConfig.dropShadow)} />
            </div>

            <div className="mt-2" />
            <label className="text-sm text-base font-medium text-gray-700" >Vote options</label>

            <div className="relative flex items-start mt-2 mb-3">
                <div className="flex items-center h-5">
                    <input
                        type="checkbox"
                        checked={productConfig.voteConfig.includeImages}
                        onChange={() => updateVoteConfig(productConfig.voteConfig.font, productConfig.voteConfig.fontColor, !productConfig.voteConfig.includeImages, productConfig.voteConfig.dropShadow) }
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                </div>
                <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">
                        Include vote images
                    </label>
                    <span id="comments-description" className="text-gray-500 pl-2">
                        (PNG or JPG, up to 2MB each)
                    </span>
                </div>
            </div>

            {
                productConfig.voteConfig.voteOptions.map((voteOption, index) => {
                    return <VoteInput includeImages={productConfig.voteConfig.includeImages} voteOption={voteOption} updateVoteOption={(name,code,avatarFile,index) => updateVoteOption(name,code,avatarFile,index)} index={index}  removeVoteOption={(index) => removeVoteOption(index)}/>
                })
            }

            <RenderIf condition={productConfig.voteConfig.voteOptions.length < MAXIMUM_VOTE_OPTIONS} >
                <div className="mt-2 sm:mt-1 flex justify-center">
                    <button onClick={e => {addVoteOption()}} type="button" className="group -ml-1 bg-white p-1 rounded-md flex text-blue-500 hover:text-blue-600 items-center focus:outline-none">
                        <span className="ml-4 text-sm font-medium  pr-2">
                            Add vote option
                        </span>
                        <PlusIcon  />
                    </button>
                </div>
            </RenderIf>
        </div>
    )
})

export default VoteTableConfig
