import Footer from "./Footer"
import FooterDetailed from "./FooterDetailed"
import Header from "./Header"

const Layout = ({
  header = {
    showMenu: true,
    showAccount: true,
  },
  children,
  footer = {
    basicFooter: false,
  }
}) => {
  return (
    <>
      <Header
        showMenu={header.showMenu}
        showAccount={header.showAccount}
      />
      <main className="bg-white pb-10" style={{ minHeight: 'calc(100vh - 114px)' }}>{children}</main>
      {footer.basicFooter ? 
        <Footer />
      :
        <FooterDetailed/>
      }
    </>
  )
}

export default Layout