import { Dialog } from '@headlessui/react'
import StandardButton from 'Components/Structure/StandardButton'
import { SuccessOrErrorMessage } from 'Components/Structure/SuccessOrErrorMessage'
import React, { useState } from 'react'
import { deleteCustomBackground, updateStreamConfig, uploadUserCustomBackground } from 'Services/StreamConfigService'
import { uploadVoteAvatars } from 'Services/VoteConfigService'
import { useStores } from 'Store/StoreConfig'
import { ProductType, StreamConfig, StreamConfigResponse } from 'Types/StreamTypes'
import CustomizeStream from '../CustomizeStream'


export const EditStreamModalBody = ({streamConfigUpdated}) => {
  const { StreamBuilderStore } = useStores()
  const streamConfig : StreamConfig = StreamBuilderStore.dashBoardStreamConfig
  const [loading, setLoading] = useState(false)
  const [changesSaved,setChangesSaved] = useState(false)
  const [changesSavedSuccess,setChangesSavedSuccess] = useState(true)

  const saveUpdates = async () => {
    setLoading(true)
    setChangesSaved(false)
    try {
      const streamConfigResponse : StreamConfigResponse = await updateStreamConfig(streamConfig.id,streamConfig)
      await handleCustomBackgroundImage(streamConfigResponse)
      await handleVoteOptionAvatarUploads(streamConfigResponse)
      streamConfigUpdated(streamConfigResponse)
      setChangesSavedSuccess(true)
    } catch (e) {
      setChangesSavedSuccess(false)
    }
    setLoading(false)
    setChangesSaved(true)
  }

  const handleCustomBackgroundImage = async (streamConfigResponse : StreamConfigResponse) => {
    if (StreamBuilderStore.dashboardNewImageUploaded) {
      if (StreamBuilderStore.getUploadedImageFiles(false).length === 0) {
        await deleteCustomBackground(streamConfigResponse.id)
        streamConfigResponse.productConfig.customBackgroundUrl = ""
      } else {
        const customBackgroundUrl : string =  await uploadUserCustomBackground(streamConfigResponse.id,false,StreamBuilderStore)
        streamConfigResponse.productConfig.customBackgroundUrl = customBackgroundUrl
      }
    }
  }

  const handleVoteOptionAvatarUploads = async(streamConfigResponse : StreamConfigResponse) => {
    if (streamConfig.productType === ProductType.MULTI_VOTE) {
      const productConfig = streamConfig.productConfig
      if (productConfig.voteConfig && productConfig.voteConfig.includeImages) {
          await uploadVoteAvatars(streamConfigResponse.id, productConfig.voteConfig.voteOptions, true)
      }
    }
  }


  return (
      <React.Fragment>
            <div>
              <Dialog.Title className="text-lg leading-6 font-medium text-gray-600">
                    Update Stream
              </Dialog.Title>

              <div className="mx-4 pt-2">

                <CustomizeStream isBuilderConfig={false}/>

                <div className="flex justify-end mt-6">
        
                  <StandardButton
                    disabled={false}
                    onClick={() =>{saveUpdates()}}
                    text={"Save updates"}
                    isSpinning={loading}
                  />
                  
                </div>

                <SuccessOrErrorMessage 
                  showComponent={changesSaved} 
                  showSuccess={changesSavedSuccess} 
                  successMessage={"Successfully updated!"} 
                  errorMessage={"Failed to update"}
                />
              
              </div>


            </div>
      </React.Fragment>
  )
}
