import React from 'react'
import { CheckoutStore } from './CheckoutStore'
import { StreamBuilderStore } from './StreamBuilderStore'
import { UserStore } from './UserStore'

const userStore : UserStore = new UserStore()
const streamBuilderStore : StreamBuilderStore = new StreamBuilderStore()
const checkoutStore : CheckoutStore = new CheckoutStore()

export const getUserStore = () : UserStore => {
    return userStore;
}

export const storesContext = React.createContext({
    StreamBuilderStore: streamBuilderStore,
    UserStore: userStore,
    CheckoutStore: checkoutStore
 })

 export const useStores = () => React.useContext(storesContext)