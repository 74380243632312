export default function Pagination({hasNext, page, pageSize, totalItems, updatePage}) {
  return (
    <nav
      className="bg-white px-4 pt-4 flex items-center justify-between sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium"> {totalItems > 0 ? page*pageSize + 1 : 0} </span> to <span className="font-medium"> {Math.min(pageSize + page*pageSize,totalItems)}  </span> of{' '}
          <span className="font-medium">{totalItems}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          disabled={page === 0}
          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white ${page === 0 ? `bg-gray-100 cursor-not-allowed` : `hover:bg-gray-50`}`}
          onClick={e => updatePage(page - 1)}
        >
          Previous
        </button>
        <button
          disabled={!hasNext}
          className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white ${!hasNext ? `bg-gray-100 cursor-not-allowed` : `hover:bg-gray-50`}`}
          onClick={e => updatePage(page + 1)}
        >
          Next
        </button>
      </div>
    </nav>
  )
}
