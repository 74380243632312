import { observer } from "mobx-react"
import { previewWindowStyle } from "Services/StreamConfigService"
import { COUNTDOWN_TEXT_MULITPLIER } from "Utilities/GeneralConstants"
import { subTitleHeading, titleHeading } from "Utilities/TextFunctions"
import Countdown from "./CountDownTimers/Countdown"

const CountdownAudioView = observer(({productConfig, backgroundImage, width, height}) => {
    return (
        <div className="h-full" style={previewWindowStyle(productConfig, backgroundImage, width, height)}>
            <div className="flex flex-col h-full">
            <div className="flex-initial text-center" style={{ marginTop: '1em', fontFamily : productConfig.titleFont}}>
                <h1 style={titleHeading(productConfig.titleColor,0.8,productConfig.titleDropShadow, productConfig.titleExtraLarge, COUNTDOWN_TEXT_MULITPLIER)}>{productConfig.title}</h1>
                <h2 style={subTitleHeading(productConfig.titleColor,0.65,productConfig.titleDropShadow, productConfig.titleExtraLarge, COUNTDOWN_TEXT_MULITPLIER)}>{productConfig.subtitle}</h2>
            </div>
            <div className="flex-1 flex items-center justify-center" style={{ marginBottom: '2.0em' }}>
                <div className="flex-initial inline-block">
                    <Countdown 
                        windowWidth={width} 
                        countdownDate={productConfig.countdownDate} 
                        countdownFontColor={productConfig.countdownFontColor} 
                        countdownBoxColor={productConfig.countdownBoxColor} 
                        countdownFont={productConfig.countdownFont} 
                        countdownType={productConfig.countdownType} 
                        countdownExtraLarge={productConfig.countdownExtraLarge}
                        countdownDropShadow={productConfig.countdownDropShadow}    
                    />
                </div>
            </div>
            </div>
        </div>
    )
})

export default CountdownAudioView;
  