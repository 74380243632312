import { getCountdownFontStyle, getCountdownNumStyle } from '../../../Utilities/TextFunctions'
import './styles.css'

const TextSideLabelCountdown = ({fontColor, fontName, countdownExtraLarge, countdownDropShadow, windowWidth, days, hours, minutes, seconds}) => {

    const getGap = () => {
        if (windowWidth < 300) {
            return "gap-1"
        } else if (windowWidth < 450) {
            return "gap-2"
        } else if(windowWidth < 640) {
            return "gap-2"
        } else if(windowWidth < 768) {
            return "gap-4"
        } else if(windowWidth < 1024) {
            return "gap-4"
        } else if(windowWidth < 1281) {
            return "gap-6"
        } else {
            return "gap-8"
        }
    }
    let countdownFontSize = (Math.round((windowWidth/300) * 4) / 4).toFixed(2)
    return (
        <div className={`grid grid-flow-col ${getGap()} place-items-end auto-cols-max`}>
            <TextSideLabel value={days} valueName={"days"} fontColor={fontColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
            <TextSideLabel value={hours} valueName={"hours"} fontColor={fontColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
            <TextSideLabel value={minutes} valueName={"min"} fontColor={fontColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
            <TextSideLabel value={seconds} valueName={"sec"} fontColor={fontColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow}/>
        </div>
    )
}


const TextSideLabel = ({value, valueName, fontColor, fontName, countdownFontSize, countdownExtraLarge, countdownDropShadow}) => {
    return (
        <div style={{ fontFamily : fontName }}>
        <span style={getCountdownNumStyle(countdownExtraLarge, countdownDropShadow, countdownFontSize)} className={`countdown`}>
        {/*@ts-ignore */}
        <span style={{"--value" : value,  color: fontColor  }}></span>
        </span>
        <span style={getCountdownFontStyle(countdownExtraLarge, countdownDropShadow, fontColor, 0.38)}> {valueName} </span>
      </div> 
    )
}



export default TextSideLabelCountdown