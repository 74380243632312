import RenderIf from "Components/Structure/RenderIf"
import { observer } from "mobx-react"
import moment from "moment"
import React from "react"
import { useEffect, useState } from "react"
import { CountdownType } from "Types/StreamTypes"
import { getFutureDateTime } from "Utilities/HelperFunctions"
import BoxLabelCountdown from "./BoxLabelCountdown"
import TextLabelCountdown from "./TextLabelCountdown"
import TextSideLabelCountdown from "./TextSideLabelCountdown"

const calculateTimeLeft = (countDownDate) => {
  const difference = moment(countDownDate).unix()*1000 - moment(getFutureDateTime(0)).unix()*1000 
  let timeLeft = {}
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    }
  } else {
    timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }

  return timeLeft
}

const Countdown = observer(({windowWidth, countdownDate, countdownFontColor, countdownBoxColor, countdownFont, countdownType, countdownExtraLarge, countdownDropShadow }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(countdownDate))
    
    useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft(countdownDate))
        }, 1000)
      })


    
    const timerComponents = []

    Object.keys(timeLeft).forEach(interval => {
        if (!timeLeft[interval]) {
          return
        }

        timerComponents.push(
        <span>
            {timeLeft[interval]} {interval}{" "}
        </span>
        )
    })


    return(
      <React.Fragment>
        <RenderIf condition={countdownType === CountdownType.BASIC}>
          <TextLabelCountdown fontColor={countdownFontColor} fontName={countdownFont} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow} windowWidth={windowWidth} days={timeLeft["days"]} hours={timeLeft["hours"]} minutes={timeLeft["minutes"]} seconds={timeLeft["seconds"]} />
        </RenderIf>
        <RenderIf condition={countdownType === CountdownType.BASICSIDE}>
          <TextSideLabelCountdown fontColor={countdownFontColor} fontName={countdownFont} countdownExtraLarge={countdownExtraLarge} countdownDropShadow={countdownDropShadow} windowWidth={windowWidth} days={timeLeft["days"]} hours={timeLeft["hours"]} minutes={timeLeft["minutes"]} seconds={timeLeft["seconds"]} />
        </RenderIf>
        <RenderIf condition={countdownType === CountdownType.BOXED}>
          <BoxLabelCountdown fontColor={countdownFontColor} boxColor={countdownBoxColor} fontName={countdownFont} countdownExtraLarge={countdownExtraLarge} windowWidth={windowWidth} days={timeLeft["days"]} hours={timeLeft["hours"]} minutes={timeLeft["minutes"]} seconds={timeLeft["seconds"]} />
        </RenderIf>
      </React.Fragment>
    )

})

export default Countdown