import { COUNTDOWN_TEXT_MULITPLIER, STANDARD_DROP_SHADOW } from "Utilities/GeneralConstants"

export const getCountdownNumStyle = (countdownExtraLarge, countdownDropShadow, countdownFontSize) => {
    if (countdownDropShadow) {
        return {
            fontSize: countdownExtraLarge ? `${countdownFontSize*COUNTDOWN_TEXT_MULITPLIER}rem` :`${countdownFontSize}rem`,  
            textShadow: STANDARD_DROP_SHADOW 
        }
    } else {
        return {
            fontSize: countdownExtraLarge ? `${countdownFontSize*COUNTDOWN_TEXT_MULITPLIER}rem` :`${countdownFontSize}rem`,  
        }
    }
}

export const getCountdownFontStyle = (countdownExtraLarge, countdownDropShadow, fontColor, countdownFontSize) => {
    if (countdownDropShadow) {
        return {
            fontSize: countdownExtraLarge ? `${countdownFontSize*COUNTDOWN_TEXT_MULITPLIER}em` :`${countdownFontSize}em`,  
            color: fontColor, 
            textShadow: STANDARD_DROP_SHADOW 
        }
    } else {
        return {
            fontSize: countdownExtraLarge ? `${countdownFontSize*COUNTDOWN_TEXT_MULITPLIER}em` :`${countdownFontSize}em`,  
            color: fontColor
        }
    }
}


export const titleHeading = (titleColor, fontSize, textShadow, extraLarge,sizeMultiplier) => {
    if (textShadow) {
        return { 
            color: titleColor, 
            fontSize: extraLarge ? `${fontSize*sizeMultiplier}em` : `${fontSize}em`,  
            textShadow: STANDARD_DROP_SHADOW 
        }
    } else {
        return { 
            color: titleColor, 
            fontSize: extraLarge ? `${fontSize*sizeMultiplier}em` : `${fontSize}em`,  
        }
    }
}

export const subTitleHeading = (titleColor, fontSize, textShadow, extraLarge, sizeMultiplier) => {
    if (textShadow) {
        return { 
            color: titleColor, 
            fontSize: extraLarge ? `${fontSize*sizeMultiplier}em` : `${fontSize}em`,  
            textShadow: STANDARD_DROP_SHADOW 
        }
    } else {
        return { 
            color: titleColor, 
            fontSize: extraLarge ? `${fontSize*sizeMultiplier}em` : `${fontSize}em`,  
        }
    }
}