import Footer from "Components/Structure/Foundation/Footer"
import StandardButton from "Components/Structure/StandardButton"
import InputWithLabel from "../Structure/InputWithLabel"
import { useCallback, useEffect, useState } from "react"
import RenderIf from "Components/Structure/RenderIf"
import { GENERIC_ERROR_MSG, INVALID_EXPIRE_URL_MSG, PASSWORD_MISMATCH_MSG } from "Utilities/GeneralConstants"
import { CognitoUser } from "amazon-cognito-identity-js"
import { createCognitoUser } from "Services/AuthService"
import { useHistory } from "react-router-dom"
import { deHashText, isPasswordValid } from "Utilities/HelperFunctions"
import {Link} from 'react-router-dom'

const queryString = require('query-string')
const isConfirmPasswordValid = (p1, p2)=> p1 === p2

const NewPassword = (props) => {
    const [password, setPassword] = useState("")
    const [showPasswordError, setShowPasswordError] = useState(false)

    const [confirmPassword, setConfirmPassword] = useState("")
    const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false)

    const [updatePassworError, setUpdatePasswordError] = useState("")

    const [complete, setComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const validPasswords = isPasswordValid(password) && isConfirmPasswordValid(password,confirmPassword)
    const history = useHistory()

    const parseCodeEmailFromURL = useCallback(() => {
        try{
            const qsParsed = queryString.parse(props.location.search)
            return {
                "email" : deHashText(qsParsed['id']),
                "code" : qsParsed['code']
            }    
        } catch(error){
            return {
                "error" : INVALID_EXPIRE_URL_MSG
            }
        }
    }, [props.location.search])

    const setNewPassword = useCallback((event) => {
        event.preventDefault()
        if(validPasswords){
            const parsedURL = parseCodeEmailFromURL()
            if(parsedURL["error"]){
                setUpdatePasswordError(parsedURL["error"])
            }else{
                setLoading(true)
                const cognitoUser : CognitoUser = createCognitoUser(parsedURL["email"])
                cognitoUser.confirmPassword(parsedURL['code'], password, {
                    onSuccess() {
                        setComplete(true)
                        setLoading(false)
                    },
                    onFailure(err) {
                        if(err["code"] === "CodeMismatchException" || err["code"] === "ExpiredCodeException"){
                            setUpdatePasswordError(INVALID_EXPIRE_URL_MSG)
                        }else{
                            setUpdatePasswordError(GENERIC_ERROR_MSG)
                        }
                        setLoading(false)
                    },
                })
            }
        }        
    }, [password, validPasswords, parseCodeEmailFromURL ])

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" && validPasswords) {
                setNewPassword(event)
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [setNewPassword, validPasswords])



    return (
        <div className="bg-blue-50 min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <Link to="/">
                  <img className="h-9 w-auto" src={process.env.PUBLIC_URL + '/images/StreamCreateLogo.svg'} alt="" />
                </Link>                <div className="bg-white px-6 py-8 pt-5 mt-6 rounded shadow-md text-black w-full">
                    <h1 className="mb-6 text-2xl text-center font-medium text-gray-600">{complete ? "New password confirmed!" : "Set new password" } </h1>

                    <RenderIf condition={!complete}>

                        <InputWithLabel
                            id="password"
                            label=""
                            placeholder="New Password" 
                            onChange={e => setPassword(e.target.value)}
                            onBlur={() => setShowPasswordError(true)}
                            value={password}
                            type="password"
                        />
                        <RenderIf condition={!isPasswordValid(password) && showPasswordError}>
                        <div className="mb-2 -mt-2 text-sm text-red-600 ml-5 ">
                            <ul className="list-disc">
                            <li>8 characters minimum.</li>
                            <li>must contain a number.</li>
                            <li>at least one uppercase.</li>
                            </ul>
                        </div>
                        </RenderIf>

                        <InputWithLabel
                            id="confirmPassword"
                            label=""
                            placeholder="Confirm New Password" 
                            onChange={e => setConfirmPassword(e.target.value)}
                            onBlur={() => setShowConfirmPasswordError(true)}
                            value={confirmPassword}
                            type="password"
                            showError={!isConfirmPasswordValid(password, confirmPassword) && showConfirmPasswordError}
                            errorMessage={PASSWORD_MISMATCH_MSG}
                        />
      
                        <RenderIf condition={updatePassworError.length > 0}>
                            <div className="text-sm text-red-600 text-center">{updatePassworError}</div>
                        </RenderIf>

                        <div className="text-center text-sm text-grey-dark mt-4">
                            <StandardButton
                                disabled={!validPasswords}
                                onClick={e => setNewPassword(e)}
                                text={"Confirm"}
                                isSpinning={loading}
                            />
                        </div>

                    </RenderIf>


                    <RenderIf condition={complete}>
                         <div className="text-center mt-4">
                            <StandardButton
                                disabled={false}
                                onClick={e => history.push("/Login")}
                                text={"Login Now "}
                                isSpinning={false}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 pl-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                            </StandardButton>
                        </div>
                    </RenderIf>


                </div>

            </div>
            <Footer />
        </div>
    )
  }
  
  export default NewPassword