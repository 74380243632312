import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function itemsToDropDownObjects(items) {
    let currentId = 0
    let dropDownObjects = items.map( item => {
        currentId++
        return { id : currentId, name : item }  
    })
    return dropDownObjects
}

export default function SelectMenu({items, itemName, updateCallback}) {
  const itemObjects = itemsToDropDownObjects(items)
  
  const getSelected = () => {
    return itemObjects.find(item => item.name === itemName)
  }

  const updateSelected = (item) => {
    updateCallback(item.name)
  }

  return (
    <Listbox value={getSelected()} onChange={item => updateSelected(item)}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className={`relative w-full bg-white rounded pl-3 pr-10 py-2 text-left text-gray-700 cursor-default border focus:border-blue-500 focus:ring-blue-500 ring-1 ${open ? 'ring-blue-500 border-blue-500' : '' }`}>
              <span >{getSelected().name.replace(/_/g, ' ')}</span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {itemObjects.map((font) => (
                  <Listbox.Option
                    key={font.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-blue-700' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={font}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal')}
                          >
                            {font.name.replace(/_/g, ' ')}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-blue-700',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}