import App from './App';
import { StylesProvider } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLISH_KEY } from 'Services/ApiConfig';
import { hydrate, render } from "react-dom";

const stripe = loadStripe(STRIPE_PUBLISH_KEY)

const APP = <StylesProvider><Elements stripe={stripe} ><App /></Elements></StylesProvider>
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
