import './colorpick.css'
import { useEffect, useRef, useState } from "react"
import ColorPicker from 'react-color/lib/components/twitter/Twitter'
import { PICK_COLORS } from "Utilities/GeneralConstants"

const useOutsideClick = (ref, onOutsideClick) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick()
        }
      }
  
      // Bind the event listener
      document.addEventListener('mousedown', handleOutsideClick)
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleOutsideClick)
      }
    }, [ref])
}

const MyColorPicker = ({selectedColor, updateCallback, width, height}) => {

    const wrapperRef = useRef(null)
    const [showPicker, setShowpicker] = useState(false)
    useOutsideClick(wrapperRef, () => setShowpicker(false))

    const updateColor = (newColor) => {
        updateCallback(newColor)
    }

    return (
          <div ref={wrapperRef} onClick={() => setShowpicker(true)} className="flex-initial mt-auto rounded-md" style={{ position: 'relative', width: width, height: height, backgroundColor: selectedColor, border: '1px solid rgb(209, 213, 219)' }}>
            <div style={{ display: showPicker ? 'block' : 'none',  position: 'absolute', top: '48px', right: '-0', zIndex: 1}}>
              <ColorPicker
                colors={PICK_COLORS}
                triangle={'top-right'}
                color={selectedColor}
                onChange={color => updateColor(color.hex)} />
            </div>
          </div>
    )
}

export default MyColorPicker
