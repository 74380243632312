import {Link} from 'react-router-dom'

const navigation = {
    templates: [
      { name: 'Countdown Stream', href: '/Templates/Countdown' },
      { name: 'Voting Poll Stream', href: '/Templates/VotingPoll' }
    ],
    support: [
      { name: 'Support', href: '/Support' },
      { name: 'Contact Us', href: '/Contact' }
    ],
    legal: [
      { name: 'Terms of Service', href: '/TermsOfService' },
      { name: 'Privacy Policy', href: '/PrivacyPolicy' }    
    ],
    streambuilder: [
      { name: 'Stream Builder', href: '/StreamBuilder' }
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/streamcreate',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/stream_create',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: 'YouTube',
        href: 'https://www.youtube.com/channel/UCbByLZpEYfO1M7TNpUOKjBw',
        icon: (props) => (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.522 6.55874C22.3206 5.45921 21.1609 4.49839 19.8775 4.37096C14.6399 3.87622 9.35578 3.87635 4.11824 4.37135C2.83944 4.49835 1.67974 5.45908 1.47728 6.56273C0.840655 10.1679 0.840908 13.8361 1.47803 17.4413C1.67969 18.5409 2.83944 19.5016 4.12255 19.6289C6.73867 19.8763 9.36837 20.0002 12 20C14.6316 20.0002 17.2613 19.8763 19.8775 19.6289L19.8817 19.6285C21.1607 19.5017 22.3204 18.5409 22.5227 17.4372C23.1593 13.832 23.1591 10.1638 22.522 6.55874ZM15.7341 12.5912L10.8151 15.4338C10.6916 15.5051 10.5481 15.5461 10.3999 15.5523C10.2517 15.5585 10.1043 15.5297 9.97348 15.469C9.84265 15.4083 9.73327 15.318 9.65702 15.2077C9.58076 15.0973 9.54048 14.9711 9.54048 14.8425V9.1574C9.54048 9.02878 9.58076 8.90257 9.65702 8.79223C9.73327 8.68189 9.84265 8.59156 9.97348 8.53086C10.1043 8.47017 10.2517 8.4414 10.3999 8.44761C10.5481 8.45382 10.6916 8.49478 10.8151 8.56613L15.7341 11.4087C15.8464 11.4736 15.9385 11.5615 16.0022 11.6646C16.0659 11.7678 16.0992 11.883 16.0992 11.9999C16.0992 12.1169 16.0659 12.2321 16.0022 12.3353C15.9385 12.4384 15.8464 12.5263 15.7341 12.5912Z" fill="#9CA3AF"/>
          </svg>
        ),
      },
    ]
  }
  
const FooterDetailed = () => {
    return (
      <footer className="bg-gray-50 pt-10" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-6 px-8 sm:px-6">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="xl:col-span-1">
            <img className="h-6 w-auto " src={process.env.PUBLIC_URL + '/images/StreamCreateLogoGray.svg'} alt="" />
              <p className="text-gray-500 text-base mt-1">
                Simplifying 24/7 live streaming on YouTube.
              </p>
              <div className="flex space-x-6 mt-4">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500" target="_blank" rel="noreferrer">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12  grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Templates</h3>
                  <ul  className="mt-4 space-y-4">
                    {navigation.templates.map((item) => (
                      <li key={item.name}>
                        <Link className="text-base text-gray-500 hover:text-gray-900" to={item.href}>
                            {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Stream Builder</h3>
                  <ul  className="mt-4 space-y-4">
                    {navigation.streambuilder.map((item) => (
                      <li key={item.name}>
                        <Link className="text-base text-gray-500 hover:text-gray-900" to={item.href}>
                            {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                  <ul  className="mt-4 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <Link className="text-base text-gray-500 hover:text-gray-900" to={item.href}>
                            {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                  <ul  className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <Link className="text-base text-gray-500 hover:text-gray-900" to={item.href}>
                            {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-2 -mb-4">
            <p className="text-base text-gray-500 text-center"> &copy; {new Date().getFullYear()} 	StreamCreate Pty Ltd. <p className="text-base text-gray-500 text-center md:inline"> All rights reserved. </p> </p>
          </div>
        </div>
      </footer>
    )
  }


export default FooterDetailed