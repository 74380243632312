import React from 'react'
import { getCountdownNumStyle } from '../../../Utilities/TextFunctions'
import './styles.css'

const BoxLabelCountdown = ({fontColor, boxColor, fontName, countdownExtraLarge, windowWidth, days, hours, minutes, seconds}) => {

    const getGap = () => {
        if (windowWidth < 300) {
            return "gap-1"
        } else if (windowWidth < 450) {
            return "gap-2"
        } else if(windowWidth < 640) {
            return "gap-2"
        } else if(windowWidth < 768) {
            return "gap-4"
        } else if(windowWidth < 1024) {
            return "gap-4"
        } else if(windowWidth < 1280) {
            return "gap-7"
        } else {
            return "gap-8"
        }
    }

    let countdownFontSize = (Math.round((windowWidth/310) * 4) / 4).toFixed(2)

    return (
        <React.Fragment>
        <div className={`grid grid-flow-col ${getGap()} text-center auto-cols-max`}>
            <TimeBox windowWidth={windowWidth} value={days} valueName={"days"} fontColor={fontColor} boxColor={boxColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge}/>
            <TimeBox windowWidth={windowWidth} value={hours} valueName={"hours"} fontColor={fontColor} boxColor={boxColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge}/>
            <TimeBox windowWidth={windowWidth} value={minutes} valueName={"min"} fontColor={fontColor} boxColor={boxColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge}/>
            <TimeBox windowWidth={windowWidth}value={seconds} valueName={"sec"} fontColor={fontColor} boxColor={boxColor} fontName={fontName} countdownFontSize={countdownFontSize} countdownExtraLarge={countdownExtraLarge}/>
        </div>
        </React.Fragment>
    )
}

const TimeBox = ({windowWidth,value, valueName, fontColor, boxColor, fontName, countdownFontSize, countdownExtraLarge}) => {

    const getBoxShape = () => {
        if (windowWidth < 450) { 
            return "pt-1 pr-1 pb-1 pl-1 rounded-md"
        } else if(windowWidth < 640) {
            return "pt-2 pr-2 pb-2 pl-2 rounded-xl"
        } else if(windowWidth < 768) {
            return "pt-2 pr-2 pb-2 pl-2 rounded-xl"
        } else if(windowWidth < 1024) {
            return "pt-2 pr-3 pb-2 pl-3 rounded-xl"
        } else if(windowWidth < 1280) {
            return "pt-2 pr-3 pb-2 pl-3 rounded-2xl"
        } else {
            return "pt-2 pr-3 pb-2 pl-3 rounded-2xl"
        }
    }

    return (
        <div style={{ backgroundColor : boxColor + 'B3', fontFamily : fontName, color: fontColor }} className={`flex flex-col ${getBoxShape()} bg-neutral text-neutral-content`}>
        <span style={getCountdownNumStyle(countdownExtraLarge, false, countdownFontSize)} className={`countdown`}>
            {/*@ts-ignore */}
            <span style={{"--value" : value }}></span>
            </span>
            <div style={{fontSize: '0.4em'}} > {valueName} </div>
        </div>
    )
}

export default BoxLabelCountdown