import { CheckIcon } from '@heroicons/react/outline'
import { ProductType } from 'Types/StreamTypes'
import { calculatePricingFromDays } from 'Utilities/HelperFunctions'

const pricing = {
  templates: [
    {
      title: 'Countdown Stream',
      price: calculatePricingFromDays(ProductType.COUNTDOWN,1),
      description: 'Stream a live countdown 24/7',
      exampleLink: '',
      features: ['Customizable title and subtitle', 
                 'Customizable countdown', 
                 'Use predesigned backgrounds', 
                 'Upload your own background', 
                 'Optional music'],
      cta: 'Build stream',
      mostPopular: false,
      comingSoon: false,
      option: "Countdown"
    },
    {
      title: 'Voting Poll Stream ',
      price: calculatePricingFromDays(ProductType.MULTI_VOTE,1),
      description: 'Stream a live poll 24/7',
      exampleLink: '',
      features: ['Customizable title and subtitle', 
                 'Customizable vote options', 
                 'Use predesigned backgrounds', 
                 'Upload your own background',  
                 'Optional music',
                 'Live chat monitoring for votes'],
      cta: 'Build stream',
      mostPopular: false,
      comingSoon: false,
      option: "VotingPoll"
    }
  ]
}


const pricingBackgroundStyle = (windowWidth, windowHeight) => {
  if (windowWidth >= 1280) {
    return { 
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/DarkBlueWaveStars.svg)`, 
      backgroundRepeat: "no-repeat", 
      backgroundPosition: 'center' }
  } else {
    return {}
  }
}

export default function Pricing({windowWidth, windowHeight, history}) {
  return (
    <div className={` py-16 md:pt-16 lg:pb-16 xl:pb-40 xl:pt-32 px-6 sm:px-6 lg:px-8 ${windowWidth < 1280 ? 'bg-blue-800 ' : '' }`} style={pricingBackgroundStyle(windowWidth,windowHeight)}>

      <p className="text-3xl text-white font-bold text-whitetracking-tight sm:text-4xl text-center ">
            Template Pricing
      </p>
      <p className="mt-5 text-white max-w-screen-md mx-auto text-xl text-center">
            Our customizable templates enable you to create professional YouTube live streams.
      </p>

      <p className="text-white max-w-screen-md mx-auto text-xl text-center">
            Engage with your YouTube audience 24/7 through our interactive templates. 
      </p>
  
      <div className="text-center mx-auto md:max-w-4xl max-w-2xl mt-8 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-20"  >
        {pricing.templates.map((template) => (
          <div
            key={template.title}
            className="relative py-6 px-6 xs:mx-8 sm:mx-20 md:mx-36 lg:mx-2 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1 m-auto">
              <h3 className="text-xl font-bold text-gray-900">{template.title}</h3>
              <p className="mt-4 text-lg font-semibold  text-gray-900">from</p>
              <p className="text-4xl font-bold  text-gray-900">${template.price.toFixed(2)}</p>
              <p className="mt-4 text-lg  font-semibold text-gray-900">{template.description}</p>
              <button  onClick={() => {history.push("/Templates/" + template.option)}} className="mt-2 font-semibold text-blue-700"> {"See examples >"} </button>

              <ul role="list" className="mt-6 space-y-4">
                {template.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon className="flex-shrink-0 w-6 h-6 text-yellow-500" aria-hidden="true" />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>


            <div className="text-center">
              <button
                type="button"
                className={`mt-8 px-16 md:px-24 py-3 inline-flex items-center border border-transparent text-base font-medium  rounded-md shadow-sm text-black focus:outline-none focus:ring-offset-2 focus:ring-yellow-400 bg-yellow-400 hover:bg-yellow-500`}
                disabled={false}
                onClick={() => {history.push("/StreamBuilder")}}
              >
                {template.cta}
              </button>

            </div>

          </div>
        ))}
      </div>
    </div>
  )
}