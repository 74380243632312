import { ProductType } from "Types/StreamTypes"

export const GENERIC_ERROR_MSG = "Error, something went wrong."

export const INVALID_EXPIRE_URL_MSG = "Error, invalid or expired url."

export const INCORRECT_LOGIN_MSG = "Incorrect username or password."

export const INCORRECT_PASSWORD_MSG = "Incorrect password."

export const EMAIL_NOT_VERIFIED = "Your email is not verified. Please check your mailbox for a verification code."

export const PASSWORD_MISMATCH_MSG = "Passwords must match."

export const INVALID_EMAIL_MSG = "Please enter a valid email."

export const MISSING_FIRSTNAME_MSG = "Please enter your first name."

export const MISSING_FULLNAME_MSG = "Please enter your full name."

export const REQUIRE_AGREEMENT_TO_TERMS = "Please confirm you agree to the terms of service."

export const INVALID_NAME_ON_CARD = "Please enter a valid name."

export const INVALID_CARD_NUMBER = "Please enter a valid card number."

export const INVALID_CARD_EXPIRATION = "Invalid card expiration"

export const INVALID_CVC_NUMBER = "Invalid verficiation code"

export const MISSING_LASTNAME_MSG = "Please enter your last name."

export const UPLOAD_BACKGROUND_IMAGE = "Error, please upload a background image."

export const PUBLISH_STREAM_SUCCESS_NEXT_STEP_MESSAGE = "Keep an eye on your YouTube scheduled stream. Your stream will be running on our servers in a couple of minutes and will be connected to the stream key you provided. Don’t forget to publish it to your audience on YouTube."

export const PUBLSIH_STREAM_SUCCESS_CONGRATULATIONS = "Your stream has been successfully launched. Streaming will commence in a few minutes!"

export const INVALID_DATE_ENTERED = "You have entered an invalid date."

export const MAX_STREAM_DAYS = 60

export const INVALID_STREAM_DURATION = `Stream must end between 1-${MAX_STREAM_DAYS} days`

export const STREAM_PURCHASE_FAILURE = "Failed to complete stream purchase."

export const INVALID_REFERRAL_CODE = "Invalid promo code."

export const WHITE = "#FFFFFF"

export const BLACK = "#000000"

export const PICK_COLORS = [BLACK, '#333333', '#ABB8C3', '#D3D2D2',  '#E11D48', '#EF4444', '#F97316', 
                            '#FDBA74', '#FCD34D', '#FDE68A', '#00D084', '#6ADAC4', '#26CAD3', '#8ED1FC',
                            '#0693E3', '#1E40AF' , '#151877', '#D8B4FE', '#A78BFA', '#FF9CC0',  WHITE]

export const DROPDOWN_FONTS = ['Aldrich Regular','Belgrano Regular', 'LilitaOne Regular', 'Lobster Regular', 'Merriweather Regular', 'MiriamLibre Bold', 'Philosopher Bold', 'Roboto Condens Regular','RussoOne Bold','Sansita Bold', 'SecularOne Bold', 'Volkhov Bold Italic','Work Sans Bold']

export const DEFAULT_BACKGROUNDS = ['BeachVibes','Beauty', 'BlueRing','Celebrate','Cloudy','Election','FireWorks','Futuristic','Gaming','Gradient','MultiColor','Party','PurpleDots','RetroCity','Spring','Stadium','TakeOff']

export const PUBLISH_STREAM_VIDEO_URL_WATCH = "https://www.youtube.com/watch?v=MCXbgobsgPk"

export const PROMOTION_VIDEO_URL_EMBED_COUNTDOWN = "https://www.youtube.com/embed/El7y7K_OzuY" 

export const PROMOTION_VIDEO_URL_EMBED_MULTIVOTE = "https://www.youtube.com/embed/LNG48Z64ocg" 

export const WORLD_TIME_SERVER_URL = "https://www.worldtimeserver.com/current_time_in_UTC.aspx"

export const DEFAULT_AUDIO = ['Adventure', 'Lofi', 'Piano', 'Cyberpunk', 'Guitar', 'Uplifting', 'Spring', 'Jazz', 'Dramatic']

export const DEFAULT_AUDIO_MAP = { 
                                  'Adventure' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/adventure.mp3',
                                  'Lofi' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/lofi.mp3',
                                  'Piano' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/piano.mp3',
                                  'Cyberpunk' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/cyberpunk.mp3',
                                  'Guitar' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/guitar.mp3',
                                  'Uplifting' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/uplifting.mp3',
                                  'Spring' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/spring.mp3',
                                  'Jazz' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/jazz.mp3',
                                  'Dramatic' : 'https://stream-create-music-public.s3.ap-southeast-2.amazonaws.com/default/dramatic.mp3'
                                 }

export const DEFAULT_TITLE = 'Enter Your Title'

export const DEFAULT_TITLE_COLOR = BLACK

export const DEFAULT_TITLE_FONT= 'Aldrich Regular'

export const DEFAULT_SUBTITLE= 'Enter Your SubTitle'

export const DEFAULT_BACKGROUND_COLOR= WHITE

export const DEFAULT_COUNTDOWN_FONT_COLOR= BLACK

export const DEFAULT_COUNTDOWN_BOX_COLOR= BLACK

export const DEFAULT_COUNTDOWN_FONT= 'Aldrich Regular'

export const DEFAULT_VOTE_TABLE_FONT= 'Aldrich Regular'

export const S3_BUCKET_BASE_URL = 'https://stream-create-backgrounds-public.s3.ap-southeast-2.amazonaws.com'

export const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png']

export const RECAPTCHA_SITE_KEY = "6LcHKvIcAAAAACR2EsqN5zoJkybBn9FPQ7zQUKGC"

export const MAX_DAILY_REDEPLOYMENTS = 5

export const STATUS_SUCCESS = 200

export const STATUS_UNAUTHORIZED = 401

export const COUNTDOWN_PRODUCT_NAME = "Live Countdown Stream"

export const MULTIVOTE_PRODUCT_NAME = "Live Voting Poll Stream"

export const MAXIMUM_VOTE_OPTIONS = 10

export const PRODUCTS = [
    {
      title: COUNTDOWN_PRODUCT_NAME,
      productType: ProductType.COUNTDOWN,
    },
    {
      title: MULTIVOTE_PRODUCT_NAME,
      productType: ProductType.MULTI_VOTE,
    },
  ]

export const getProductTitle = (productType : ProductType) => PRODUCTS.find(product => product.productType === productType).title


export const VOTE_TABLE_SIZES = {
  1: {"rowHeight": "0.85", "rowFontSize": 0.43, "rowMarginTop": 3.5, "numberMarginLeftImage": 0, "numberMarginLeftNoImage": 2.5, "numberMarginRight": 0, "lastFiveVoterPadding" : 32.2},
  2: {"rowHeight": "0.85", "rowFontSize": 0.43, "rowMarginTop": 3.5, "numberMarginLeftImage": 0, "numberMarginLeftNoImage": 2.5,"numberMarginRight": 0, "lastFiveVoterPadding" : 26.30},
  3: {"rowHeight": "0.85", "rowFontSize": 0.43, "rowMarginTop": 3.5, "numberMarginLeftImage": 0, "numberMarginLeftNoImage": 2.5,"numberMarginRight": 0, "lastFiveVoterPadding" : 20.58},
  4: {"rowHeight": "0.85", "rowFontSize": 0.43, "rowMarginTop": 3.5, "numberMarginLeftImage": 0, "numberMarginLeftNoImage": 2.5,"numberMarginRight": 0, "lastFiveVoterPadding" : 14.93},
  5: {"rowHeight": "0.85", "rowFontSize": 0.43, "rowMarginTop": 3.5, "numberMarginLeftImage": 0, "numberMarginLeftNoImage": 2.5,"numberMarginRight": 0, "lastFiveVoterPadding" : 9.36},
  6: {"rowHeight": "0.8", "rowFontSize": 0.43, "rowMarginTop": 2.75, "numberMarginLeftImage": 0, "numberMarginLeftNoImage": 2.5,"numberMarginRight": -5, "lastFiveVoterPadding" : 5.7},
  7: {"rowHeight":"0.75", "rowFontSize": 0.40, "rowMarginTop": 2.5, "numberMarginLeftImage": -0.5, "numberMarginLeftNoImage": 2.5,"numberMarginRight": -10, "lastFiveVoterPadding" : 2.71},
  8: {"rowHeight": "0.65", "rowFontSize": 0.37, "rowMarginTop": 1, "numberMarginLeftImage": -0.8, "numberMarginLeftNoImage": 3,"numberMarginRight": -15, "lastFiveVoterPadding" : 0.41},
  9: {"rowHeight": "0.58", "rowFontSize": 0.32, "rowMarginTop": 1.25, "numberMarginLeftImage": -1.2, "numberMarginLeftNoImage": 3,"numberMarginRight": -16, "lastFiveVoterPadding" : 1.48},
  10: {"rowHeight": "0.52", "rowFontSize": 0.30, "rowMarginTop": 0.5, "numberMarginLeftImage": -1.5, "numberMarginLeftNoImage": 3,"numberMarginRight": -20, "lastFiveVoterPadding" : 0.84},
}

export const STANDARD_DROP_SHADOW = "1px 3px 3px rgba(0,0,0,0.7)"

export const COUNTDOWN_TEXT_MULITPLIER = 1.25

export const MULTIVOTE_TEXT_MULITPLIER = 1.125
