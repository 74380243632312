import { useEffect, useRef, useState } from "react"
import { HeadingTwo } from "Components/Typography/HeadingTwo"
import { observer } from "mobx-react"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import StandardButton from "Components/Structure/StandardButton";
import InputWithLabel from "Components/Structure/InputWithLabel";
import { INVALID_NAME_ON_CARD } from "Utilities/GeneralConstants";
import RenderIf from "Components/Structure/RenderIf";
import Paypal from "./Paypal";
import { PaymentProvider } from "Types/CheckoutTypes";

const isCardNameValid = input => input.length >= 2 && input.length <= 40  && /^[a-zA-Z '.-]+$/.test(input)

const Payment = observer(({paymentAmount, submitStripePayment, completePaypalPayment, isCheckoutFormComplete}) => {
    const [showCardPayment, setShowCardPayment] = useState(false)
    const [cardName, setCardName] = useState("")
    const [showCardNameError, setShowCardNameError] = useState(false)
    const [cardDetailsError, setCardDetailsError] = useState("")
    const [streamPurchaseError, setStreamPurchaseError] = useState("")
    const [paymentProcessing, setPaymentProcessing] = useState(false)

    const messagesEndRef = useRef(null)

    const stripe = useStripe();
    const elements = useElements();
    
    const handleChange = (e) => {
        if (e['error']) {
            setCardDetailsError(e['error']['message'])
        } else {
            setCardDetailsError("")
        }
    } 
  
    const handleSubmit = async (provider, paypalOrder, paypalError) => {
        let errorMessage = ""
        let streamPurchaseError = ""
        setPaymentProcessing(true)
        setStreamPurchaseError("")
        if (provider === PaymentProvider.STRIPE) {
            streamPurchaseError = await submitStripePayment(paymentAmount,{card: elements.getElement(CardElement),billing_details: {name: cardName}},stripe) 
        } else if (provider === PaymentProvider.PAYPAL) {
          if (paypalError) {
              errorMessage = paypalError
          }else {
            streamPurchaseError = await completePaypalPayment(paypalOrder)
          }
        }
        setCardDetailsError(errorMessage)
        setStreamPurchaseError(streamPurchaseError)
        setPaymentProcessing(false)
    };

    function scrollToBottom() {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        if (messagesEndRef && messagesEndRef.current) {
            scrollToBottom()
        }
    }, [showCardPayment]);


    
    return (
        <div className="max-w-md">
            <HeadingTwo> Payment </HeadingTwo>
            <div className="mt-4" ></div >
            <Paypal paymentAmount={paymentAmount} handleSubmit={(paypalOrder, paypalError) => handleSubmit(PaymentProvider.PAYPAL,paypalOrder,paypalError)} isCheckoutFormComplete={()=> isCheckoutFormComplete() }/>
            <div className="mt-4" ></div >
            <button
                type="button"
                className={` items-center w-full px-3 py-1 pt-2 border-2 border-blue-500 text-base font-medium rounded-md shadow-sm text-blue-700 focus:outline-none focus:ring-offset-2 focus:ring-blue-300 bg-blue-200 hover:bg-blue-300`}
                onClick={() => {setShowCardPayment(!showCardPayment)}}>
                Credit Card <img className="inline-flex h-6 w-auto ml-2 mb-1 " src={process.env.PUBLIC_URL + '/images/CardLogo.svg'} alt="" />
            </button>
            <div className="mt-3" ></div >
            {showCardPayment ? 
                <div ref={messagesEndRef} className=" mt-3 mb-5" >
                
                <InputWithLabel
                    id="name"
                    label="Name on card"
                    onChange={e => {setCardName(e.target.value)}}
                    onBlur={() => {setShowCardNameError(true)}}
                    value={cardName}
                    type="text"
                    showError={!isCardNameValid(cardName) && showCardNameError}
                    errorMessage={INVALID_NAME_ON_CARD }
                />

                    <label htmlFor="email" className={"block text-md font-medium text-gray-700 mb-1" }>Card details</label>

                    <div 
                        className={`block border text-gray-700 w-full p-3 rounded  focus:outline-none ${cardDetailsError.length > 0 ? "border-red-600 ring-red-600 " : "focus:border-blue-500 focus:ring-blue-500"} ring-1` }
                    >
                        <CardElement  
                            options={{
                                hidePostalCode: true,
                                style: {
                                    base: {
                                        fontSize: '16px',
                                    },
                                    invalid: {
                                        color: '#dc2626',
                                      },
                                },
                            }} 
                        
                            onChange={ e => handleChange(e)}
                        />
                    </div>
                    
                    <RenderIf condition={cardDetailsError.length > 0}>
                        <div className="text-sm text-red-600 mt-2 mb-2"> {cardDetailsError} </div>
                    </RenderIf>
                    <RenderIf condition={!(cardDetailsError.length > 0)}>
                        <div className="mb-4" /> 
                    </RenderIf>

                    <StandardButton
                        disabled={!(isCardNameValid(cardName))}                            
                        onClick={() => {handleSubmit(PaymentProvider.STRIPE,null,null)}}
                        text={`Pay $${paymentAmount}`}
                        isSpinning={paymentProcessing}
                        extendClassName="w-full py-3 justify-center mt-2 "
                    />
                
                </div>
            : <div/>}
            <RenderIf condition={streamPurchaseError.length > 0}>
                <div className={`text-sm text-red-600 mb-2 ${showCardPayment ? '-mt-2' : 'mt-2' }`}> {streamPurchaseError} </div>
            </RenderIf>
        </div>
    )
})

export default Payment

