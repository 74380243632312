import { observer } from "mobx-react"
import {useState } from "react"
import { useStores } from "Store/StoreConfig"
import { BackgroundType } from "Types/StreamTypes"
import { DEFAULT_BACKGROUNDS } from "Utilities/GeneralConstants"
import MyColorPicker from "./Utilities/MyColorPicker"
import OptionSelector from "./Utilities/OptionSelector"
import SelectMenu from "./Utilities/SelectMenu"
import UploadImageBox from "./Utilities/UploadImageBox"


const BackgroundConfig = observer(({isBuilderConfig}) => {
    const { StreamBuilderStore } = useStores();
    const [pc,setpc] = useState(StreamBuilderStore.getProductConfig(isBuilderConfig))

    const updateBackground = (newBackgroundColor, newdefaultBackgroundUrl, newcustomBackgroundUrl, newBackgroundType) => {
      let newStreamConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig)
      newStreamConfig.productConfig.backgroundColor = newBackgroundColor
      newStreamConfig.productConfig.defaultBackgroundUrl = newdefaultBackgroundUrl
      newStreamConfig.productConfig.customBackgroundUrl = newcustomBackgroundUrl
      newStreamConfig.productConfig.backgroundType = newBackgroundType
      StreamBuilderStore.setStreamConfig(newStreamConfig,isBuilderConfig)
      setpc(newStreamConfig.productConfig)
    }

    const updateBackgroundType = (backgroundType : BackgroundType) => {
      let newStreamConfig = StreamBuilderStore.getStreamConfig(isBuilderConfig)
      newStreamConfig.productConfig.backgroundType = backgroundType
      StreamBuilderStore.setStreamConfig(newStreamConfig,isBuilderConfig)
    }

    return (
        <div className="p-4 pt-0 text-gray-800">

          <div className="flex flex-col lg:flex-row mt-2">
              <div className="flex-1 inline-block lg:mt-2 lg:w-8/12 lg:pt-0">
                <OptionSelector text={"Colored background"} active={pc.backgroundType === BackgroundType.BACKGROUND_COLOR} onClick={() => updateBackgroundType(BackgroundType.BACKGROUND_COLOR)} />
              </div>
              <div className="inline-block sm:w-1/2 lg:w-4/12 pt-2 lg:pt-0 lg:ml-0 ml-2">
                <MyColorPicker selectedColor={pc.backgroundColor} updateCallback={color => updateBackground(color, pc.defaultBackgroundUrl, pc.customBackgroundUrl,BackgroundType.BACKGROUND_COLOR)} width="100%" height="45px"/>
              </div>
          </div>

          <div className="flex-1 mt-2">
            <OptionSelector text={"Predesigned background"} active={pc.backgroundType === BackgroundType.DEFAULT_BACKGROUND}  onClick={() => updateBackgroundType(BackgroundType.DEFAULT_BACKGROUND)} />
            <div className="mt-2  sm:w-1/2 lg:w-7/12  ml-2 lg:ml-0"> 
              <SelectMenu  items={DEFAULT_BACKGROUNDS} itemName={pc.defaultBackgroundUrl} updateCallback={defaultBackgroundUrl => updateBackground(pc.backgroundColor, defaultBackgroundUrl, pc.customBackgroundUrl,BackgroundType.DEFAULT_BACKGROUND)} />
            </div>
          </div>

          <div className="flex mt-2">
            <OptionSelector text={"Upload background image"} active={pc.backgroundType === BackgroundType.CUSTOM_BACKGROUND}  onClick={() => updateBackgroundType(BackgroundType.CUSTOM_BACKGROUND)} />
          </div>
          <div className="ml-2 lg:ml-0 mt-2 sm:w-1/2 lg:w-full"> 
          < UploadImageBox  updateCallback={customBackgroundUrl => updateBackground(pc.backgroundColor, pc.defaultBackgroundUrl, customBackgroundUrl, BackgroundType.CUSTOM_BACKGROUND)} isBuilderConfig={isBuilderConfig} />
          </div>

      </div>
    )
})

export default BackgroundConfig
