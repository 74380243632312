


export default function ThreeStepsSection({windowWidth, windowHeight, history}) {
  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8">
      <p className="text-3xl text-black font-bold text-whitetracking-tight sm:text-4xl text-center">
          Customize your streams in 3 easy steps
      </p>
      <p className="mt-5 text-black max-w-screen-md mx-auto text-xl text-center">
          Build the stream for free, then purchase to go live. No software or design skills needed.
      </p>
      <div className="text-center mt-2">
      {windowWidth < 768 ? 
         <img className="mx-auto mt-6 md:mt-12" src={process.env.PUBLIC_URL + '/images/ThreeStepsMobile.svg'} alt="" />
        : 
        <img className="mx-auto mt-6 md:mt-12" src={process.env.PUBLIC_URL + '/images/ThreeSteps.svg'} alt="" />
        }
        <button
          type="button"
          onClick={() => {history.push("/Templates/Countdown")}}
          className={` md:mt-16 mt-8 px-10 py-3 inline-flex items-center border border-transparent text-base font-medium  rounded-md shadow-sm text-black focus:outline-none focus:ring-offset-2 focus:ring-yellow-400 bg-yellow-400 hover:bg-yellow-500`}
          disabled={false}
        >
          View templates
        </button>
      </div>
    </div>
  )
}