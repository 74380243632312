import axios from 'axios'
import { StreamPurchaseDto } from 'Types/CheckoutTypes'
import { STREAM_PURCHASE_FAILURE } from 'Utilities/GeneralConstants'
import { API_BASE_URL } from './ApiConfig'


export async function completeStreamPurchase(streamPurchaseDto : StreamPurchaseDto) {
    try {
        const response = await axios.post(API_BASE_URL + "/payments/complete/streamPurchase", streamPurchaseDto)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return null
    }
}

export async function createPaymentIntent(amount: string) {
    try {
        const response = await axios.post(API_BASE_URL + "/payments/intent", {"amount": amount})
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return null
    }
}


export async function completeStripePayment(paymentAmount: string, paymentElement, stripe) {
    const paymentIntentResponse = await createPaymentIntent(paymentAmount);
    if (paymentIntentResponse) {
        const clientSecret = paymentIntentResponse['clientSecret']
        return await stripe.confirmCardPayment(clientSecret,{payment_method: paymentElement})
    } else {
       return {error: {"message" : STREAM_PURCHASE_FAILURE}}
    }
}