import Layout from "../Components/Structure/Foundation/Layout"
import Body from "Components/Structure/Foundation/Body"
import PageHeader from "Components/Typography/PageHeader"
import ParagraphBold from "Components/Typography/ParagraphBold"
import ParagraphLink from "Components/Typography/ParagraphLink"
import InputWithLabel from "Components/Structure/InputWithLabel"
import StandardButton from "Components/Structure/StandardButton"
import React, { useState } from "react"
import { GENERIC_ERROR_MSG, INVALID_EMAIL_MSG, MISSING_FIRSTNAME_MSG, RECAPTCHA_SITE_KEY } from "Utilities/GeneralConstants"
import { isEmailValid, isFirstNameValid } from "Utilities/HelperFunctions"
import RenderIf from "Components/Structure/RenderIf"
import ReCAPTCHA from "react-google-recaptcha";
import { CheckCircleIcon } from "@heroicons/react/solid"
import { getUserStore } from "Store/StoreConfig"
import { UserStore } from "Store/UserStore"
import { emailContactUsForm } from 'Services/CustomerService'
import { Helmet } from "react-helmet"
import { CONTACT_DESCRIPTION, CONTACT_TITLE } from "Utilities/SEOConstants"


export const Contact = () => {
  const [firstName, setFirstname] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [captchaComplete, setCaptchaComplete] = useState(false)
  const [message, setMessage] = useState("")
  const [showFirstNameError, setShowFirstNameError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showMessageError, setShowMessageError] = useState(false)
  const [contactUsSubmitted, setContactUsSubmitted] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const firstNameInvalid = !isFirstNameValid(firstName)
  const emailInvalid = !isEmailValid(email)
  const messageInvalid = message.length < 2
  const disableSubmit = firstNameInvalid || emailInvalid || messageInvalid || !captchaComplete
  const userStore : UserStore = getUserStore()

  const onChange = (value) => {
    setCaptchaComplete(true)
  }

  const submitContactUsForm = async () => {
    setIsLoading(true)
    setEmailError("")
    const emailResult = await emailContactUsForm(firstName,lastName,email,message,userStore)
    setIsLoading(false)
    if (emailResult) {
      setContactUsSubmitted(true)
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      setEmailError(GENERIC_ERROR_MSG)
    }
  }

  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
      <Helmet>
        <title>{CONTACT_TITLE}</title>
        <meta name="description" content={CONTACT_DESCRIPTION} />
      </Helmet>
      <PageHeader text={"Contact us"}  />
      <Body > 

        <div className="bg-white pb-4 px-4 overflow-hidden sm:px-6 lg:px-8 lg:pb-8 pt-4">
          <div className="relative max-w-xl mx-auto">
            { contactUsSubmitted  ?
             <div className="text-center xs:mx-12 md:mx-24 my-8 sm:my-16">
                  <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-2">
                     <CheckCircleIcon className="h-16 w-16 text-green-400 " aria-hidden="true" />
                </div>
                <ParagraphBold>
                  Success! 
                </ParagraphBold>
                <div className="mb-2"/>
                <ParagraphBold>
                  Your message has been sent and a member of our team will be in touch with you shortly.
                </ParagraphBold>
                <div className="mb-30"/>
              </div>
            :
              <React.Fragment>
                <ParagraphBold>
                    We are here to help. Get in touch with our friendly team or look for answers to some of the most common questions in our <div className="inline-block"> <ParagraphLink link={"/Support"}>FAQ page.</ParagraphLink> </div>
                </ParagraphBold>
                <div className="mt-10 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-8">
                    <InputWithLabel
                        id="firstName"
                        label="First name"
                        inputClassName={`block border border-gray-300 text-gray-700 w-full p-3 rounded mb-2 mt-2 focus:outline-none ${firstNameInvalid && showFirstNameError ? "border-red-600 ring-red-600 ring-1" : "focus:border-blue-500 focus:ring-blue-500 focus:ring-1"}` }
                        onChange={e => {setFirstname(e.target.value)}}
                        onBlur={() => setShowFirstNameError(true)}
                        value={firstName}
                        type="text"
                        showError={firstNameInvalid && showFirstNameError}
                        errorMessage={MISSING_FIRSTNAME_MSG}
                    />
                    <InputWithLabel
                        id="lastName"
                        label="Last name"
                        inputClassName={`block border border-gray-300 text-gray-700 w-full p-3 rounded mb-2 mt-2 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1` }
                        onChange={e => setLastName(e.target.value)}
                        onBlur={() => {}}
                        value={lastName}
                        type="text"
                    />
        
                    <div className="sm:col-span-2">
                      <InputWithLabel
                        id="email"
                        label="Email"
                        inputClassName={`block border border-gray-300 text-gray-700 w-full p-3 rounded mb-2 mt-2 focus:outline-none ${emailInvalid && showEmailError ? "border-red-600 ring-red-600 ring-1" : "focus:border-blue-500 focus:ring-blue-500 focus:ring-1"}` }
                        onChange={e => setEmail(e.target.value)}
                        onBlur={() => setShowEmailError(true)}
                        value={email}
                        type="text"
                        showError={emailInvalid && showEmailError}
                        errorMessage={INVALID_EMAIL_MSG}
                        maxLength={80}
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                        Message
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="message"
                          name="message"
                          onChange={e => setMessage(e.target.value)}
                          onBlur={() => setShowMessageError(true)}
                          rows={4}
                          className={`py-3 px-4 w-full block mb-2 border border-gray-300 text-gray-700 w-full p-3 rounded mt-2 focus:outline-none ${messageInvalid && showMessageError ? "border-red-600 ring-red-600 ring-1" : "focus:border-blue-500 focus:ring-blue-500 focus:ring-1"}` }
                          value={message}
                        />
                      </div>
                      <RenderIf condition={messageInvalid && showMessageError}>
                        <div className="mb-2 text-sm text-red-600"> {"Please enter a valid message."} </div>
                      </RenderIf>
                      <RenderIf condition={!(messageInvalid && showMessageError)}>
                        <div className="mt-4" /> 
                      </RenderIf>
                    </div>

                      <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={onChange}
                      />

                    <div className="sm:col-span-2 mt-4">
                      <StandardButton
                          disabled={disableSubmit}                            
                          onClick={e => {submitContactUsForm()}}
                          text={"Submit"}
                          extendClassName="w-full justify-center py-3"
                          isSpinning={isLoading}
                      />
                    </div>
                    <RenderIf condition={emailError.length > 0}>
                        <div className={`text-sm text-red-600 mb-2`}> {emailError} </div>
                    </RenderIf>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
          
       </Body> 
    </Layout>
  )
}
