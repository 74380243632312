import FeaturesSection from 'Components/Marketing/FeaturesSection'
import HeroSection from 'Components/Marketing/HeroSection'
import PricingSection from 'Components/Marketing/PricingSection'
import ThreeStepsSection from 'Components/Marketing/ThreeStepsSection'
import Layout from 'Components/Structure/Foundation/Layout'
import { useWindowSize } from 'Components/Structure/Foundation/WindowsSize'
import { Helmet } from 'react-helmet'
import { useHistory } from "react-router-dom"
import { HOME_DESCRIPTION, HOME_TITLE } from 'Utilities/SEOConstants'

export const Home = () => {
  const [windowWidth, windowHeight] = useWindowSize()
  const history = useHistory()
  return (  
    <Layout header={{ showAccount: true, showMenu: true }}>
      <Helmet>
        <title>{HOME_TITLE}</title>
        <meta name="description" content={HOME_DESCRIPTION} />
      </Helmet>
      <HeroSection windowWidth={windowWidth}  windowHeight={windowHeight} history={history}/>
      <ThreeStepsSection windowWidth={windowWidth}  windowHeight={windowHeight} history={history}/>
      <PricingSection windowWidth={windowWidth}  windowHeight={windowHeight} history={history}/>
      <FeaturesSection/>  
    </Layout>
  )
}

