import { observer } from "mobx-react";
import { VoteOption } from "Types/StreamTypes";
import { SUPPORTED_IMAGE_TYPES } from "Utilities/GeneralConstants";

const TWO_MB_IN_BYTES = 2000000

interface VoteInputImageInterface {
    voteOption: VoteOption
    updateVoteOption: (name : string,code : string,avatarFile : File, index : number) => any
    index: number
    setImageError: (error: string) => any
}

const VoteInputImage = observer(({voteOption, updateVoteOption, index, setImageError} : VoteInputImageInterface) => {
 
    const validateImageUpload = (file : File) => {
        if (file == null) {
            return false
        }
        if (file.size > TWO_MB_IN_BYTES) {
            setImageError("Please ensure your file is smaller than 2MB.")
            return false
        }
        if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
            setImageError("Please ensure your file is a PNG or JPG file.")
            return false
        }
        return true
    }


    const imageUploaded = (newImageFile : File, files ) => {
        setImageError("")
        if (validateImageUpload(newImageFile)) {
            updateVoteOption(voteOption.name,voteOption.code,newImageFile,index)
        }
    }


    return (
        <div className="flex-shrink-0">
            <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none ">
                <img
                    className="h-10 w-10  mt-0.5"
                    src={voteOption.avatarUrl ? voteOption.avatarUrl : process.env.PUBLIC_URL + '/images/DefaultMultiVoteAvatar.svg'}
                    alt=""
                />
                <input onChange={e => imageUploaded(e.target.files[0], e.target.files)} id="file-upload" name="file-upload" type="file" className="sr-only" />
            </label>
        </div>
    )
})

export default VoteInputImage

