import {  XIcon} from '@heroicons/react/outline'
import RenderIf from 'Components/Structure/RenderIf'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { VoteOption } from 'Types/StreamTypes'
import VoteInputImage from './VoteInputImage'

interface VoteInputInterface {
    includeImages: boolean
    voteOption: VoteOption
    updateVoteOption: (name : string,code : string,avatarFile : File, index : number) => any
    index: number
    removeVoteOption: (index : number) => any
}


const VoteInput = observer(({includeImages, voteOption, updateVoteOption, index, removeVoteOption} : VoteInputInterface) => {

  const [imageError, setImageError] = useState("")

  return (
    <React.Fragment>
        <div className="bg-white py-2 pr-2 pl-1 mt-2 border border-gray-200 shadow-md rounded-md" >
            <div className="flex space-x-2">
                <RenderIf condition={includeImages} >
                    <VoteInputImage voteOption={voteOption} updateVoteOption={(name,code,avatarFile,index) => updateVoteOption(name,code,avatarFile, index)} index={index} setImageError={(error) => setImageError(error)}/>
                </RenderIf>
                <div className="relative border mt-1 mb-0.5 lg:w-7/12 w-1/2 border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500">
                    <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                    >
                        Name
                    </label>
                    <input
                        maxLength={22}
                        type="text"
                        name="name"
                        id="name"
                        className="focus:outline-none block w-full border-0 text-gray-900 placeholder-gray-500  sm:text-sm"
                        value={voteOption.name}
                        onChange={e => {updateVoteOption(e.target.value,voteOption.code,voteOption.avatarFile,index)}}
                    />
                </div>

                <div className="relative border mt-0.5 mb-0.5 lg:w-5/12 w-1/2 border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500">
                    <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                    >
                        Vote code
                    </label>
                    <input
                        maxLength={20}
                        type="text"
                        name="voteCode"
                        id="voteCode"
                        className="focus:outline-none block w-full border-0 text-gray-900 placeholder-gray-500 sm:text-sm"
                        onChange={e => {updateVoteOption(voteOption.name,e.target.value,voteOption.avatarFile,index)}}
                        value={voteOption.code}
                    />
                </div>


                <div className="flex-shrink-0 self-center flex">
                    <button className="relative z-35 inline-block ">
                            <XIcon onClick={( () => removeVoteOption(index) )} className="h-6 w-6 p-0.5 -mr-0.5 -mb-0.5 bg-gray-200 rounded-md text-gray-900" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <RenderIf condition={imageError.length > 0}>
            <div className="text-sm text-red-600 mt-1">{imageError}</div>
        </RenderIf>
    </React.Fragment>
  )
})

export default VoteInput
