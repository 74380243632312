import { InformationAlertAction } from 'Components/Structure/Alerts/InformationAlertAction'
import { TitleTextBox } from 'Components/Content/TitleTextBox'
import React from 'react'
import { useStores } from 'Store/StoreConfig'
import { TechnicalSupport } from './TechnicalSupport'
import { PUBLISH_STREAM_SUCCESS_NEXT_STEP_MESSAGE, PUBLSIH_STREAM_SUCCESS_CONGRATULATIONS } from 'Utilities/GeneralConstants'



const StreamPublishSuccess = ({streamKey}) => {
  const { StreamBuilderStore } = useStores()

  return (
    <React.Fragment>

        <div className="flex flex-col lg:flex-row mt-2 lg:pb-8 "   style={{ backgroundRepeat: 'no-repeat', backgroundImage: `url(${process.env.PUBLIC_URL}/images/PublishSuccess.svg)` }} >
            <div className="lg:w-3/12 "></div>

            <div className="lg:w-5/12 px-6 m-auto lg:pt-20 pt-80">
              <p className="text-black lg:text-white font-medium	text-xl ">Congratulations! <br/>
                <span> {PUBLSIH_STREAM_SUCCESS_CONGRATULATIONS} </span>
              </p>
            </div>
          
            <div className="px-2 lg:px-8 pt-2 lg:pt-10 lg:w-4/12 ">
              < TechnicalSupport />
            </div>
        </div>

          <div className="mx-2 mt-5 mb-4"  >
 
            <TitleTextBox 
               title={"What's next?"} 
               text={PUBLISH_STREAM_SUCCESS_NEXT_STEP_MESSAGE}  
            />

            <div className="mt-5" />

            <InformationAlertAction 
               message={ <p>If you need to change your stream key: 
                         <span className="font-semibold">{" " + StreamBuilderStore.streamConfig.streamKey}, </span>
                         or update your stream configuration (e.g text, color, background), you can do so by going to your personal Dashboard/Manage streams page. Login is required.</p> } 
            />

          </div>


    </React.Fragment>
  )
}

export default StreamPublishSuccess
