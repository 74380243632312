import axios from 'axios'
import { StreamConfigResponse } from 'Types/StreamTypes'
import { STATUS_SUCCESS, STATUS_UNAUTHORIZED } from 'Utilities/GeneralConstants'
import { API_BASE_URL } from './ApiConfig'
import { getAuthHeader, getSession, handleUnauthorizedRequest } from './AuthService'


export async function deployStreamConfig(streamConfigId: string) : Promise<StreamConfigResponse> {
    getSession()
    try {
        const response = await axios.put(API_BASE_URL + "/streams/manage/deploy/" + streamConfigId, {}, { headers: getAuthHeader()})
        if (response.status === STATUS_SUCCESS) {
            return response.data
        } 
    } catch (error) {
        if (error.response.status === STATUS_UNAUTHORIZED) {
            await handleUnauthorizedRequest()
        }
        throw error;
    }
}