import { InformationCircleIcon  } from '@heroicons/react/solid'

export const InformationAlert = ({title, message}) => {
    return (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon  className="h-5 w-5 text-blue-400 mt-0.5" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="font-medium text-blue-800">{title}</h3>
              <div className="mt-1 mr-4 lg:mr-0 xl:mr-0 text-blue-700">
                <p>
                  {message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )
}