import HeadingOne from "./HeadingOne"

export const PageHeader = ({ text }) => {
    return (
        <div className={`mb-8` }> 
        <div className={`bg-blue-800 pt-4 pb-24 -mb-36 text-center color-white` }> 
            <HeadingOne textColor="text-white"> {text} </HeadingOne>
        </div>
        </div>
    )
}
  
export default PageHeader


