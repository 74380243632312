import { ProductType, StreamConfig } from "./StreamTypes";

export enum PaymentProvider {
    STRIPE = 'STRIPE',
    PAYPAL = 'PAYPAL',
}

export interface CheckoutDetails {
    fullName: string,
    email: string,
    agreeTerms: boolean,
    totalCost: number,
    cardDetails : CardDetails,
    streamEndDateError: boolean,
    validReferralCode: string
}


export interface CardDetails {
    cardName: string,
    cardNumber: number,
    expirationDate : string,
    cvc : number
}


export interface StreamPurchaseDto {
    paymentProviderId: PaymentProvider,
    paymentProvider: string,
    paymentFullName: string,
    customerId: string,
    paymentEmail: string,
    productType: ProductType,
    cost: string,
    referralCode?: string,
    streamConfig: StreamConfig
}


export interface Payment {
    id: string,
    paymentProviderId: number,
    paymentFullName: string,
    paymentEmail: string,
    productType: ProductType,
    discount: number,
    cost: number,
    streamEnd: String,
    createdAt: String,
}