import Layout from "../Components/Structure/Foundation/Layout"
import Body from "Components/Structure/Foundation/Body"
import { PRODUCTS } from "Utilities/GeneralConstants"
import RenderIf from "Components/Structure/RenderIf"
import { useState } from "react"
import { ProductType } from "Types/StreamTypes"
import HeadingThree from "Components/Typography/HeadingThreeBold"
import LabelSlider from "Components/Structure/Foundation/LabelSlider"
import StandardButton from "Components/Structure/StandardButton"
import { useHistory } from "react-router-dom"
import { calculatePricingFromDays, getOptionProductType } from "Utilities/HelperFunctions"
import { Helmet } from "react-helmet"
import { PRICING_DESCRIPTION, PRICING_TITLE } from "Utilities/SEOConstants"

export const Pricing = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const preSelectedOption = urlParams.get('option')
  const [productType, setProductType] = useState(getOptionProductType(preSelectedOption))
  const [sliderDays, setSliderDays] = useState(15)
  const history = useHistory()

  return (
    <Layout header={{ showAccount: true, showMenu: true }}>
        <Helmet>
            <title>{PRICING_TITLE}</title>
            <meta name="description" content={PRICING_DESCRIPTION} />
        </Helmet>
        <div className={`bg-blue-800 pt-10 lg:pt-16 pb-48 -mb-40 lg:-mb-40 text-center px-8 `}> 
            <h1 className="tracking-tight font-bold text-white text-4xl md:text-5xl lg:text-5xl xl:text-6xl text-center ">
                Affordable 24/7 Stream Hosting
            </h1>
            <h1 className="tracking-tight font-bold text-blue-300 text-4xl md:text-5xl lg:text-5xl xl:text-6xl text-center ">
                pay only for what you need
            </h1>
            <p className="tracking-tight text-lg sm:text-xl text-white font-normal mt-6 -mb-4 md:mt-10">
                Our prices are calculated from the template you select and the number of days we host your live stream
            </p>
        </div>
        
        <Body> 
            <div className="max-w-7xl mx-auto py-2 sm:px-12 lg:px-32 pb-8">
                 <div className="text-center mb-5 lg:mb-7 lg:mt-2">
                 <h2 className="text-2xl sm:text-3xl leading-relaxed font-bold text-gray-800">Streaming Cost Calculator</h2>
                </div>
                <HeadingThree>Select Template</HeadingThree>
                <div className="flex flex-col lg:flex-row my-2" >
                    <div className="md:w-6/12 lg:w-5/12 xl:mb-20">
                        <div className="flex flex-col ">
                            {
                            PRODUCTS 
                            .map(item => {
                                const active = item.productType === productType
                                const circleStyle = active ? 'bg-blue-600' : 'border border-blue-600'
                                return (
                                <button 
                                    disabled={item.productType !== ProductType.COUNTDOWN && item.productType !== ProductType.MULTI_VOTE}
                                    onClick={() => setProductType(item.productType)}
                                    className={`block hover:bg-blue-100 focus:bg-blue-100 shadow-sm text-gray-700 flex flex-1 text-left w-auto focus:outline-none border rounded-md px-4 py-4 my-3  ${active ? 'bg-blue-100 border-blue-100' : 'border-gray-200 hover:border-blue-100' }`} key={item.productType}>
                                    <span className={`box-border self-center inline-block rounded-full h-4 w-4 mr-4 ${circleStyle}`} />
                                    <span className={`text-bold`}>{item.title}</span>
                                </button>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="lg:w-1/12 xl:w-2/12"></div>
                    <div className="md:w-6/12 lg:w-6/12 xl:w-6/12 mt-6 lg:mt-1 " >
                        <RenderIf condition={productType === ProductType.MULTI_VOTE}>
                            <img style={{"width": '100%'  }}   src={process.env.PUBLIC_URL + '/images/MultiVoteTemplateSmall.svg'} alt="" />
                        </RenderIf>
                        <RenderIf condition={productType === ProductType.COUNTDOWN}>
                            <img style={{"width": '100%' }}  src={process.env.PUBLIC_URL + '/images/CountDownTemplateSmall.svg'} alt="" />
                        </RenderIf>
                    </div>
                </div>

                <div className="relative pt-6 sm:pt-8 pb-8">
                    <div className="w-full border-t border-gray-300" />
                </div>

                <HeadingThree>How many days do you wish to stream?</HeadingThree>

                <div className="mt-12" />

                <LabelSlider setSliderDays={setSliderDays} />


                <div className="relative pt-4 sm:pt-6 pb-6">
                    <div className="w-full border-t border-gray-300" />
                </div>
                
                <div className="text-right">
                    <h2 className="text-xl sm:text-2xl leading-relaxed font-bold text-gray-800 mt-2 mb-1 sm:mb-3">Your price for {sliderDays} days</h2>
                    <h2 className="text-2xl sm:text-3xl leading-relaxed font-extrabold text-gray-800 mb-1 sm:mb-2">${calculatePricingFromDays(productType, sliderDays).toFixed(2)}</h2>
                    <StandardButton
                            disabled={false}
                            extendClassName={"mt-2 py-3"}                        
                            onClick={e => { history.push("/StreamBuilder")}}
                            text={"Build Stream"}
                            isSpinning={false}
                        />
                </div>
                
            </div>
        </Body> 
    </Layout>
  )
}
